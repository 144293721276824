import { useQuery } from 'react-query';
import { fetchLessonLengths } from '../services/TutorAvailablity';

const useLessonLengths = ({ tutorId, date, startTime }, onSuccess, onError) => {
    return useQuery(['lessonLengths'], () => fetchLessonLengths(tutorId, date, startTime), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
        enabled: !!startTime,
        cacheTime: 0
    });
};

export default useLessonLengths;
