import { useQuery } from 'react-query';
import { verifyUser } from '../../services/Auth';

const useVerifyUser = (userId, token, onSuccess, onError) => {
    return useQuery(['verification'], () => verifyUser({ userId, token }), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
        enabled: Boolean(userId && token)
    });
};

export default useVerifyUser;
