import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const ActivateAccount = () => {
    return (
        <Stack mt={5} textAlign="center" justifyContent="center" spacing={2}>
            <Box>
                <MailOutlineIcon fontSize="large" color="primary" />
            </Box>
            <Typography variant="h6" mt={2} gutterBottom>
                A verification link has been sent to your email.
            </Typography>
            <Typography variant="body1" color="textSecondary">
                Please check your inbox and follow the instructions to activate your account.
            </Typography>
            <Typography variant="body2" color="textSecondary">
                If you don’t see the email, check your spam folder.
            </Typography>
        </Stack>
    );
};

export default ActivateAccount;
