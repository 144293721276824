import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

const LoadingScreen = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        // backgroundColor: '#f5f5f5',
        position: 'relative'
      }}
    >
      <Stack sx={{
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        alignItems: 'center'
      }}>
        <div className="loading-screen"></div>
        <Box mt={2}>
          <Typography variant="h6" fontWeight={"600"} fontStyle="italic">Loading...</Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default LoadingScreen;
