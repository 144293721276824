import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const fetchListings = async (isAuthenticated, params) => {
    const query_params = [];

    const { subject, rateRange, page } = params
        
    if (subject) query_params.push("subjectId=" + subject.id);
    if (rateRange) query_params.push("rateRange=" + rateRange.join(","));
    if (page) query_params.push("page=" + page);

    const params_text = "?" + query_params.join("&");

    const response = await requestEngine.get(`api/listings` + (isAuthenticated ? "/protected" : "") + params_text);
    return response.data.data;
}

export const fetchListing = async (listingId, isAuthenticated) => {
    const response = await requestEngine.get(`api/listing/${listingId}` + (isAuthenticated ? "/protected" : ""));
    return response.data.data;
}

export const fetchFeaturedListings = async (isAuthenticated) => {
    const response = await requestEngine.get(`api/listings/featured` + (isAuthenticated ? "/protected" : ""));
    return response.data.data;
}