import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import Listing from '../../../../components/Listing'
import useListings from '../../../../hooks/useListings'
import { useUserContext } from '../../../../context/UserContext'
import ListingSkeleton from '../../../../components/ListingSkeleton'
import { useLocation } from 'react-router'
import { getParsedParams } from '../Filters/utils'

const List = () => {
  const { isAuthenticated } = useUserContext();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialFilters = getParsedParams(searchParams);

  const { data: listings, isLoading, fetchNextPage, hasNextPage } = useListings(isAuthenticated, initialFilters);

  if (isLoading) {
    return (
      <Stack gap={2}>
        {new Array(4).fill(0).map((_, index) => {
          return (
            <ListingSkeleton key={index} />
          )
        })}
      </Stack>
    )
  }

  const data = listings.pages.flatMap(page => page.ads)

  if (!data.length) {
    return (
      <Box mt={4} textAlign="center">
        <Typography variant="h6" fontWeight="500">No Listing Found.</Typography>
      </Box>
    )
  }

  return (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchNextPage}
      hasMore={hasNextPage}
      loader={<Box textAlign="center" mt={2}><CircularProgress size={24} color="inherit" /></Box>}
    >
      <Stack gap={2}>
        {data.map(listing => {
          return (
            <Listing key={listing.id} listing={listing} />
          )
        })}
      </Stack>
    </InfiniteScroll>
  )
}

export default List