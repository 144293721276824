import SchoolIcon from '@mui/icons-material/School';
import { Box, Button, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import learningSessionImage from '../../../../assets/images/time-to-learn.png';

const StartYourLearningJourney = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            style={{ position: 'relative', width: '100%', overflow: 'hidden', height: '550px', border: "1px solid #ebe2e2", borderRadius: "20px" }}
        >
            <CardMedia
                component="img"
                image={learningSessionImage}
                alt="Students engaged in a learning session."
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.25, duration: 0.5, ease: 'easeInOut' }}
                style={{ position: 'absolute', bottom: "5%", left: "5%", width: "90%", maxWidth: "440px" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background: theme.palette.primary.main,
                                color: "white",
                                p: { xs: "15px", md: "25px" },
                                borderRadius: "20px",
                                cursor: "pointer",
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                            onClick={() => navigate("/sign-up-as-a-student")}
                        >
                            <Typography variant={isSmallScreen ? "h5" : "h4"} fontWeight="bold" gutterBottom>
                                Are you ready to learn?
                            </Typography>
                            <Typography variant={isSmallScreen ? "body2" : "body1"} fontWeight="500" gutterBottom>
                                Explore subjects, enhance skills, and join our community of learners.
                            </Typography>
                            <Button
                                fullWidth
                                sx={{ mt: 1 }}
                                size="large"
                                variant="outlined"
                                color="primary"
                                endIcon={<SchoolIcon />}
                            >
                                Sign up now
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </motion.div>
        </motion.div>
    );
};

export default StartYourLearningJourney;
