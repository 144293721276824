import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, useTheme } from '@mui/material';
import React, { useMemo, useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    bgcolor: 'background.paper',
    borderRadius: "10px",
    maxHeight: "80%",
    overflow: "hidden",
    overflowY: "auto",
    outline: "unset",
    border: "unset",
};

const useModal = () => {
    const theme = useTheme()
    const [modals, setModals] = useState([]);

    const openModal = (id, data) => {
        setModals([...modals, { id, data }]);
    };

    const closeModal = (id) => {
        setModals(prevModals => prevModals.filter(modal => modal.id !== id));
    };

    const isModalOpen = (id) => {
        return Boolean(modals.find(modal => modal.id === id))
    };

    const getModalData = (id) => {
        return modals.find(modal => modal.id === id)?.data
    }

    const displayChildren = (children, data) => {
        if (typeof children === "function") {
            return children(data)
        } else {
            return children
        }
    }

    const ModalComponent = useMemo(() => {
        return React.memo(({ id, maxWidth = 400, hideHeader, children }) => {
            const data = getModalData(id);
            return (
                <Modal sx={{ "& .MuiBackdrop-root": { background: "rgba(0,0,0,0.2)" } }} open={isModalOpen(id)} onClose={() => closeModal(id)}>
                    <Box sx={{ ...style, maxWidth }}>
                        {!hideHeader && (
                            <Box backgroundColor={theme.palette.secondary.main} textAlign="end" pb={0.5} pt={0.5}>
                                <IconButton onClick={() => closeModal(id)} sx={{ mr: 1 }}>
                                    <CloseIcon sx={{ fontSize: 18, color: "white" }} />
                                </IconButton>
                            </Box>
                        )}
                        <Box p={4} pt={2} pb={2}>
                            {displayChildren(children, data)}
                        </Box>
                    </Box>
                </Modal>
            );
        });
    }, [modals]);

    return { openModal, closeModal, isModalOpen, getModalData, ModalComponent };
};

export default useModal;
