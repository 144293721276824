import { useMutation } from 'react-query';
import { createStudent } from '../../services/Auth';

const useCreateStudent = (onSuccess, onError) => {
    return useMutation(createStudent, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data)
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useCreateStudent;
