import { useInfiniteQuery } from 'react-query';
import { fetchUpcomingBookings } from '../../../services/Booking';

const useUpcomingBookings = (onSuccess, onError) => {
    return useInfiniteQuery(
        'upcomingBookings',
        async ({ pageParam = 1 }) => {
            const data = await fetchUpcomingBookings(pageParam);
            return data;
        },
        {
            getNextPageParam: (lastPage, pages) => {
                return lastPage.pagination.currentPage < lastPage.pagination.totalPages ? lastPage.pagination.currentPage + 1 : undefined;
            },
            onSuccess: (data) => {
                if (onSuccess) onSuccess(data);
            },
            onError: (error) => {
                if (onError) onError(error.response?.data || error.message);
            },
            keepPreviousData: true,
            cacheTime: 0
        }
    );
};

export default useUpcomingBookings;
