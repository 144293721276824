import { Box, LinearProgress, Rating, Stack, Typography } from '@mui/material'
import React from 'react'
import Review from './Review'

const Reviews = ({ reviews }) => {

    const displayAverageRating = () => {
        return reviews.length ? Number((reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length).toFixed(1)) : "N/A"
    }

    const calculateRatingDistribution = () => {
        const ratingCounts = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

        reviews.forEach(review => {
            if (review.rating >= 1 && review.rating <= 5) {
                ratingCounts[review.rating]++;
            }
        });

        const totalReviews = reviews.length;

        const ratingPercentages = {};
        for (const [rating, count] of Object.entries(ratingCounts)) {
            ratingPercentages[rating] = totalReviews > 0 ? (count / totalReviews) * 100 : 0;
        }

        return { ratingPercentages, ratingCounts, totalReviews };
    };

    const { ratingPercentages, ratingCounts, totalReviews } = calculateRatingDistribution()

    return (
        <Box>
            <Typography variant="h6" fontWeight="bold">Reviews</Typography>
            <Box mt={1} display="flex" gap={3} alignItems="center">
                <Box>
                    <Typography variant="h4" fontWeight="bolder" mb={1}>{displayAverageRating()}</Typography>
                    <Rating name="read-only" value={displayAverageRating()} readOnly color='primary' />
                    <Typography variant="body2" fontWeight="500" gutterBottom>{totalReviews || "N/A"} review(s)</Typography>
                </Box>
                <Box width="100%">
                    {[5, 4, 3, 2, 1].map((key) => (
                        <Stack direction="row" alignItems="center" key={key} spacing={1} sx={{ mt: 1 }}>
                            <Typography variant="body2" sx={{ width: 20 }}>{key}</Typography>
                            <LinearProgress
                                variant="determinate"
                                value={ratingPercentages[key] || 0}
                                sx={{ flexGrow: 1, mx: 1, height: 7, borderRadius: 5 }}
                            />
                            <Typography variant="body2" sx={{ width: 30 }}>{ratingCounts[key]}</Typography>
                        </Stack>
                    ))}
                </Box>
            </Box>
            {!!totalReviews && (
                <Stack mt={3} gap={2}>
                    {reviews.map((review) => {
                        return <Review key={review.id} review={review} />
                    })}
                </Stack>
            )}
        </Box>
    )
}

export default Reviews