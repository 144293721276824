import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const fetchRoomMessages = async (roomId, lastMessageTime, page) => {
    const params = []

    if (page) {
        params.push("page=" + page)
    }

    if (lastMessageTime) {
        params.push("lastMessageTime=" + lastMessageTime)
    }

    var paramString = "?" + params.join("&");
    
    const response = await requestEngine.get("/api/room/" + roomId + "/messages" + paramString);
    return response.data.data;
}

export const fetchMessagesUnreadCount = async () => {
    const response = await requestEngine.get("/api/messages/unread/count");
    return response.data.data;
}