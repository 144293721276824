import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const fetchRooms = async (query) => {
    let params_string = ""
    if (query) {
        params_string = "?q=" + query
    }
    const response = await requestEngine.get("/api/rooms" + params_string);
    return response.data.data;
}

export const createRoomAndSendMessage = async (data) => {
    const response = await requestEngine.post("/api/room", data);
    return response.data.data;
}