import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Box, Button, styled, Typography } from '@mui/material';
import React, { useRef } from 'react';
import FileCard from '../../../../../components/FileCard';
import ImageCard from '../../../../../components/ImageCard';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: "100%",
    height: "100%"
});

const UploadFile = ({ onUpload, onChange, onRemove, file, type, label }) => {
    const inputRef = useRef(null)

    const displayCorrectType = () => {
        if (type === 'file') {
            return (
                <Box>
                    <FileCard fileUrl={file.name} fileName={file.name} showRemove onRemove={onRemove} />
                    <Box mt={2} textAlign="right">
                        <Button variant="contained" color="secondary" onClick={onUpload}>Upload</Button>
                    </Box>
                </Box>
            )
        } else {
            return (
                <Box>
                    <ImageCard image={file} onRemove={onRemove} />
                    <Box mt={2} textAlign="right">
                        <Button variant="contained" color="secondary" onClick={onUpload}>Upload</Button>
                    </Box>
                </Box>
            )
        }
    }

    return (
        <>
            {file ? (
                displayCorrectType()
            ) : (
                <Box>
                    <Typography variant="h6" fontWeight="600" mb={2}>{label}</Typography >
                    <Box
                        component={Button}
                        fullWidth
                        variant="outlined"
                        onClick={() => inputRef.current.click()}
                        sx={{
                            cursor: "pointer",
                            height: 300,
                            borderRadius: 2,
                            position: "relative"
                        }}
                    >
                        <Box
                            sx={{
                                textAlign: "center",
                                position: "absolute",
                                top: "40%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                            }}
                        >
                            <Box>
                                <CloudUploadOutlinedIcon sx={{ fontSize: "4rem" }} />
                            </Box>
                            <Box>
                                <Typography variant='body1'>Choose a file or drag it here.</Typography>
                            </Box>
                        </Box>
                        <VisuallyHiddenInput accept={type === 'file' ? ".pdf,.doc,.docx" : "image/*"} type="file" onChange={onChange} ref={inputRef} />
                    </Box>
                </Box>
            )}
        </>
    )
}

export default UploadFile;
