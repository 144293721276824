import { matchIsValidTel } from "mui-tel-input";
import * as yup from "yup"

export const validationSchemas = [
    yup.object().shape({
        firstName: yup.string().required('First Name is required'),
        lastName: yup.string().required('Last Name is required'),
        email: yup.string().email('Email is invalid').required('Email is required'),
    }),

    yup.object().shape({
        phoneNumber: yup.string().required('Phone Number is required').test('is-valid-phone', 'Phone Number is invalid', (value) => {
            return matchIsValidTel(value);
        }),
        gender: yup.string().required('Gender is required'),
        dob: yup.string().required('Date of Birth is required'),
    }),
    yup.object().shape({
        country: yup.string().required('Country is required'),
        city: yup.string().required('City is required'),
        streetAddress: yup.string().required('Street Address is required'),
    }),
    yup.object().shape({
        // resume: yup.mixed().test("fileType", "Unsupported File Format", (value) => {
        //     return value && ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type);
        // }),
    }),
    yup.object().shape({
        // profile: yup.mixed().test("fileType", "Unsupported File Format", (value) => {
        //     return value && ["image/jpeg", "image/png", "image/gif"].includes(value.type);
        // }),
    }),
    yup.object().shape({
        password: yup
            .string()
            .min(8, 'Password must be at least 8 characters')
            .matches(/[A-Z]/, 'Must include an uppercase letter')
            .matches(/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/, 'Must include a special character')
            .required('Password is required'),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
    }),

]

export const initialSchema = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dob: "",
    country: "",
    city: "",
    streetAddress: "",
    resume: null,
    profile: null,
    password: "",
    confirmPassword: ""
}