import { useMutation } from 'react-query';
import { markNotificationAsRead } from '../../../services/Notification';

const useMarkNotificationAsRead = (onSuccess, onError) => {
    return useMutation(markNotificationAsRead, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data)
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useMarkNotificationAsRead;
