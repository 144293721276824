import { MuiPalette } from "./MuiPalette";

export const MuiSelect = {
    styleOverrides: {
        root: {
            borderRadius: 5,
            backgroundColor: MuiPalette.blue.main,
            height: "48px",
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                // transition: 'border-color 0.2s ease-in-out',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
                // borderColor: 'transparent',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: MuiPalette.secondary.main,
                borderWidth: '2px',
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                borderColor: MuiPalette.error.main,
            },
            '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: MuiPalette.error.main,
            },
            '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: MuiPalette.error.main,
                borderWidth: '2px',
            },
        },
    },
};
