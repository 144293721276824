import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Slide,
    Toolbar,
    Typography,
    useMediaQuery,
    useScrollTrigger,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Logo from './Logo';
import Messages from './Messages';
import Notifications from './Notifications';
import useLogout from "../../hooks/shared/student_tutor/useLogout";
import Account from "./Account";
import LogoutIcon from "@mui/icons-material/Logout"
import { serveMenuItems } from "./utils"
import { useUserContext } from "../../context/UserContext";

function HideOnScroll({ children }) {
    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function Header() {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const { user, isAuthenticated } = useUserContext()

    const mainMenuItems = serveMenuItems(isAuthenticated, isAuthenticated ? user.role : "public")

    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.href = "/login";
    };

    const { mutate: logout, isLoading, isSuccess } = useLogout(handleLogout);

    const handleDrawerToggle = () => {
        setDrawerOpen((prev) => !prev);
    };

    const navigateTo = (menuItem) => {
        setDrawerOpen(false);
        navigate(menuItem.route);
    };

    useEffect(() => {
        if (!isSmallScreen) {
            setDrawerOpen(false);
        }
    }, [isSmallScreen]);

    const renderRightContent = () => {
        if (isSmallScreen) {
            if (isAuthenticated) {
                return (
                    <>
                        <Messages />
                        <Notifications />
                        <IconButton onClick={handleDrawerToggle}>
                            <MenuIcon />
                        </IconButton>
                    </>
                )
            } else {
                return (
                    <IconButton onClick={handleDrawerToggle}>
                        <MenuIcon />
                    </IconButton>
                )
            }
        } else {
            if (isAuthenticated) {
                return (
                    <>
                        <Messages />
                        <Notifications />
                        <Account mainMenuItems={mainMenuItems} onLogout={handleLogout} />
                    </>
                )
            } else {
                return (
                    <>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigateTo({ route: "/become-a-tutor" })}
                        >
                            Become a tutor
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => navigateTo({ route: "/sign-up-as-a-student" })}
                        >
                            Sign up
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => navigateTo({ route: "/login" })}
                        >
                            Login
                        </Button>
                    </>
                )
            }
        }
    }

    return (
        <>
            <HideOnScroll>
                <AppBar position="sticky" elevation={0} sx={{ background: "white" }}>
                    <Toolbar sx={{ ml: 2, mr: 2, justifyContent: "space-between", minHeight: '64px' }}>
                        <Logo />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {renderRightContent()}
                        </Box>
                    </Toolbar>
                    <Box borderBottom={1} borderColor="divider" sx={{ opacity: 0.6 }} />
                </AppBar>
            </HideOnScroll>

            <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                <Box sx={{ width: 250 }} role="presentation">
                    <List>
                        {mainMenuItems.map((item) => (
                            <React.Fragment key={item.key}>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigateTo(item)}>
                                        <ListItemText primary={<Typography variant="body2">{item.text}</Typography>} />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ))}
                        {isAuthenticated && (
                            <ListItem disablePadding>
                                <ListItemButton onClick={logout} disabled={isLoading || isSuccess}>
                                    <LogoutIcon sx={{ fontSize: 18, mr: 1 }} />
                                    <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                                    {(isLoading || isSuccess) && (
                                        <CircularProgress size={24} className='absolute-circular-progress' />
                                    )}
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Box>
            </Drawer>
        </>
    );
}

export default Header;
