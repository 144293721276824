import { Avatar, Card, CardContent, CardHeader, Typography, Rating } from '@mui/material'
import React from 'react'
import dayjs from '../../../../../../utils/dayjsConfig'

const Review = ({ review }) => {
    const formattedDate = dayjs(review.updatedAt).format('MMM D, YYYY')

    return (
        <Card sx={{ boxShadow: 'none', border: "1px dashed" }}>
            <CardHeader
                avatar={
                    <Avatar>
                        {review.student.credentials.firstName[0]}
                    </Avatar>
                }
                title={review.student.credentials.fullName}
                subheader={formattedDate}
            />
            <CardContent>
                <Rating value={review.rating} readOnly precision={0.5} />
                <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {review.content}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default Review
