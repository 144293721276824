import { createTheme, responsiveFontSizes } from "@mui/material";
import { MuiButton } from "./MuiButton";
import { MuiPalette } from "./MuiPalette";
import { MuiTypography } from "./MuiTypography";
import { MuiTextField } from "./MuiTextField";
import { MuiSelect } from "./MuiSelect";
import { MuiFormHelperText } from "./MuiFormHelperText";
import { MuiAutocomplete } from "./MuiAutocomplete";
import { MuiToggleButton } from "./MuiToggleButton"
import { MuiChip } from "./MuiChip";

export default responsiveFontSizes(createTheme({
    palette: MuiPalette,
    typography: MuiTypography,
    MuiCssBaseline: {
        styleOverrides: {
            ':root': {
                '--Header-height': '64px',
            },
        },
    },
    components: {
        MuiButton: MuiButton,
        MuiTextField: MuiTextField,
        MuiSelect: MuiSelect,
        MuiFormHelperText: MuiFormHelperText,
        MuiAutocomplete: MuiAutocomplete,
        MuiToggleButton: MuiToggleButton,
        MuiChip: MuiChip,
    },
    mixins: {
        toolbar: {
            minHeight: 64,
            '@media (max-width:600px)': {
                minHeight: 56,
            },
        },
    },
})); 