import { useQuery } from 'react-query';
import { fetchCalendarEventDetails } from '../../../services/Calendar';

const useCalendarEventDetails = (bookingId, roleId, onSuccess, onError) => {
    return useQuery(
        ['eventDetails'],
        () => fetchCalendarEventDetails(bookingId, roleId),
        {
            onSuccess: (data) => {
                if (onSuccess) onSuccess(data);
            },
            onError: (error) => {
                if (onError) onError(error.response.data);
            },
        }
    );
};

export default useCalendarEventDetails;
