import { useQuery } from 'react-query';
import { fetchMessagesUnreadCount } from '../../../services/Message';

const useMessagesUnreadCount = (onSuccess, onError) => {
    return useQuery('unreadMessagesCount', fetchMessagesUnreadCount, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
    });
};

export default useMessagesUnreadCount;
