import { useTheme } from '@emotion/react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Backdrop, Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, InputAdornment, Stack, Switch, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from "notistack";
import React, { useRef } from 'react';
import useUpdateAd from "../../../../hooks/Tutor/useUpdateAd";
import useUpdateAdVisibility from "../../../../hooks/Tutor/useUpdateAdVisibility";
import UpgradeToFeaturedBanner from './UpgradeToFeaturedBanner';
import { initialSchema, validationSchema } from './yup';
import YoutubeVideo from '../../../../components/YoutubeVideo';

const AdEdit = ({ ad, onAdUpdate }) => {
  const theme = useTheme();
  const ylRef = useRef()

  const { mutate: updateAd, isLoading } = useUpdateAd((ad) => {
    onAdUpdate(ad.id, ad)
    enqueueSnackbar(ad.subject.name + " ad updated successfully.", { variant: "success" })
  })

  const { mutate: updateAdVisibility, isLoading: isLoadingVisibility } = useUpdateAdVisibility((newAd) => {
    onAdUpdate(newAd.id, newAd)
    enqueueSnackbar(newAd.online ? "Ad is back online!" : "Ad is now offline!", { variant: newAd.online ? "success" : undefined })
  })

  const adSchema = Object.keys(initialSchema).reduce((acc, key) => {
    acc[key] = ad[key]
    return acc
  }, {})

  const formik = useFormik({
    initialValues: adSchema,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit: (data) => {
      updateAd(data)
    }
  });

  const handleSwitchChange = (e) => {
    if (e.target.checked) updateAdVisibility({ id: ad.id, online: true })
    else {
      const confirmOffline = window.confirm('Your ad will be hidden from the listings. Are you sure you want to proceed?');
      if (confirmOffline) {
        updateAdVisibility({ id: ad.id, online: false })
      }
    }
  }

  const navigateToListingDetails = () => {
    const setupTab = window.open("/listings/" + ad.id, "_blank");
    if (setupTab) {
      setupTab.focus();
    }
  }

  const onAddYoutubeLinkClick = () => {
    if (ylRef && ylRef.current) {
      console.dir(ylRef.current);

      ylRef.current.firstChild.firstChild.focus()
    }
  }

  return (
    <Box width="100%">
      <Grid item lg={12}>
        <UpgradeToFeaturedBanner ad={ad} />
      </Grid>

      <Grid item lg={12} mt={3}>
        <Stack spacing={3}>
          <Box>
            <Typography fontSize={18} fontWeight="bold">
              You Teach
              <Typography ml={1} variant="h6" component="span" fontWeight="bold" color="secondary">
                {ad.subject.name}
              </Typography>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" bgcolor={theme.palette.grey[100]} borderRadius={2} p={2}>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" fontWeight="bold">Ad is online</Typography>
              <Switch onChange={handleSwitchChange} checked={ad.online} />
            </Box>
            <Button sx={{ width: 200 }} variant="outlined" size="large" color="secondary" endIcon={<RemoveRedEyeIcon />} onClick={navigateToListingDetails}>
              Student's View
            </Button>
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>About Me</Typography>
            <TextField
              fullWidth
              placeholder="Write something about yourself..."
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.aboutMe}
              name="aboutMe"
              error={formik.touched.aboutMe && Boolean(formik.errors.aboutMe)}
              helperText={formik.touched.aboutMe && formik.errors.aboutMe}
              onBlur={formik.handleBlur}
              minRows={2}
              multiline
            />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Ad Title</Typography>
            <TextField
              fullWidth
              placeholder="Enter the title of your ad"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.title}
              name="title"
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
              onBlur={formik.handleBlur}
              minRows={2}
              multiline
            />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Ad Description</Typography>
            <TextField
              placeholder="Enter the description of your ad"
              fullWidth
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.description}
              name="description"
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              onBlur={formik.handleBlur}
              minRows={4}
              multiline
            />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Price per hour</Typography>
            <TextField
              autoComplete="off"
              fullWidth
              placeholder="Enter your rate per hour"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.rate}
              name="rate"
              error={formik.touched.rate && Boolean(formik.errors.rate)}
              helperText={formik.touched.rate && formik.errors.rate}
              onBlur={formik.handleBlur}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography variant="body2" fontWeight="bold">$/h</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Service Location</Typography>
            <Box mt={2}>
              <FormGroup>
                <Box border={"2px solid " + theme.palette.lightBlue.main} pl={3} pr={3} pt={1} pb={1} borderRadius={"5px"}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        name="isLessonOnline"
                        onChange={(_, val) => formik.setFieldValue("isLessonOnline", val)}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isLessonOnline}
                      />
                    }
                    label={<Typography ml={1} fontWeight="500" variant="body2">Online</Typography>} />
                </Box>

                <Box border={"2px solid " + theme.palette.lightBlue.main} pl={3} pr={3} pt={1} pb={1} borderRadius={"5px"} mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        name="isLessonInPerson"
                        onChange={(_, val) => formik.setFieldValue("isLessonInPerson", val)}
                        onBlur={formik.handleBlur}
                        checked={formik.values.isLessonInPerson}
                      />
                    }
                    label={<Typography ml={1} fontWeight="500" variant="body2">In-Person</Typography>} />
                </Box>
                {(formik.errors.isLessonOnlineOrInHouse && (formik.touched.isLessonOnline || formik.touched.isLessonInPerson)) && (
                  <Typography mt={1} ml={1} variant="caption" color="error">
                    {formik.errors.isLessonOnlineOrInHouse}
                  </Typography>
                )}
              </FormGroup>
            </Box>
          </Box>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>YouTube Link</Typography>
            <TextField
              fullWidth
              autoComplete="off"
              placeholder="Add your video introduction link"
              name="youtubeLink"
              variant="outlined"
              value={formik.values.youtubeLink}
              onChange={formik.handleChange}
              error={formik.touched.youtubeLink && Boolean(formik.errors.youtubeLink)}
              helperText={formik.touched.youtubeLink && formik.errors.youtubeLink}
              onBlur={formik.handleBlur}
              ref={ylRef}
            />
          </Box>
          <Box textAlign="right" mt={3}>
            <Button disabled={isLoading} size="large" variant="contained" onClick={formik.handleSubmit}>
              {isLoading &&
                <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
              }
              Update Ad Content
            </Button>
          </Box>
        </Stack>
      </Grid>

      {/* <Grid item lg>
        <Box height="195px">
          <YoutubeVideo videoUrl={ad.youtubeLink} onAddLinkClick={onAddYoutubeLinkClick} />
        </Box>
      </Grid> */}

      <Backdrop
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoadingVisibility || isLoading}
      >
        <Box textAlign="center" sx={{ color: "#fff" }}>
          <CircularProgress sx={{ color: "#fff" }} />
          <Typography variant="body1">Updating ad...</Typography>
        </Box>
      </Backdrop>
    </Box>

  )
}

export default AdEdit;