export const getParsedParams = (searchParams) => {
    const subjectParam = searchParams.get('s');
    const rateParam = searchParams.get('r');

    const subject = subjectParam
        ? { name: subjectParam.split(',')[0], id: Number(subjectParam.split(',')[1]) }
        : null;

    const rateRange = rateParam
        ? rateParam.split(',').map(Number)
        : [0, 400];

    return { subject, rateRange };
};