import { Navigate } from 'react-router';
import ActivateAccount from "../../pages/Public/ActivateAccount";
import BecomeATutor from "../../pages/Public/BecomeATutor";
import Home from "../../pages/Public/Home";
import Login from "../../pages/Public/Login";
import RequestResetPassword from "../../pages/Public/RequestResetPassword";
import ResetPassword from "../../pages/Public/ResetPassword";
import SignUp from "../../pages/Public/SignUp";
import VerifyAccount from '../../pages/Public/VerifyAccount';
import ListingDetails from '../../pages/shared/for_all/ListingDetails';
import Listings from '../../pages/Student/Listings';
import commonRoutes from './common';
import Layout from '../../layouts';

/**
 * Public Routes Configuration
 *
 * This file contains the routing configuration for public pages in the application.
 * Each route defines the path, associated component, and layout to be used.
 */

const publicRoutes = [
    {
        path: "/",
        element: <Layout maxWidth="lg" footer />,
        children: [
            { index: true, element: <Home /> },
        ]
    },
    {
        path: "/",
        element: <Layout maxWidth="lg" />,
        children: [
            {
                path: "listings/:listingId",
                element: <ListingDetails />
            },
            {
                path: "activate-account",
                element: <ActivateAccount />
            }
        ]
    },
    {
        path: "/",
        element: <Layout maxWidth="sm" />,
        children: [
            { path: "become-a-tutor", element: <BecomeATutor /> },
            { path: "sign-up-as-a-student", element: <SignUp /> },
            { path: "login", element: <Login header /> },
            { path: "request-reset-password", element: <RequestResetPassword /> },
            { path: "reset-password", element: <ResetPassword /> },
        ]
    },
    {
        path: "/",
        element: <Layout maxWidth="xl" />,
        children: [
            {
                path: "listings",
                element: <Listings />,
            }
        ]
    },
    {
        path: "/verify-account",
        element: <VerifyAccount />
    },
    ...commonRoutes,
    {
        path: "*",
        element: <Navigate to="/" />
    },
]

export default publicRoutes