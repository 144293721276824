import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import Constants from '../core/Constants';

const SocketContext = createContext(null);

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false);
    const socketRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) return;

        if (!socketRef.current) {
            const newSocket = io(Constants.serverlink, {
                query: { token },
                reconnection: true,
                transports: ['websocket']
            });

            socketRef.current = newSocket;

            newSocket.on(Constants.EVENTS.CONNECT, () => setIsConnected(true));
            newSocket.on(Constants.EVENTS.DISCONNECT, () => setIsConnected(false));
            newSocket.on(Constants.EVENTS.ERROR, (err) => {
                console.error('Socket connection error:', err);
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    return (
        <SocketContext.Provider value={socketRef.current}>
            {children}
        </SocketContext.Provider>
    );
};
