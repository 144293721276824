import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Button, CircularProgress, IconButton, Link, TextField, Tooltip, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import * as yup from "yup";
import useUpdateMeetingLink from '../../../../../../hooks/Tutor/useUpdateMeetingLink';

const AddMeetingLink = ({ onMeetingLinkUpdated, booking, user }) => {
    const [inEditMode, setInEditMode] = useState(false)

    const handleSuccess = ({ meetingLink }) => {
        enqueueSnackbar("Meeting link was updated to reflect upcoming lesson.", { variant: "success" });
        onMeetingLinkUpdated(meetingLink, booking.id)
    }

    const handleError = () => {
        enqueueSnackbar("Invalid URL.", { variant: "error" });
    }

    const { mutate: updateMeetingLink, isLoading } = useUpdateMeetingLink(handleSuccess, handleError)

    const formik = useFormik({
        initialValues: { meetingLink: booking.meetingLink || "" },
        validationSchema: yup.object({
            meetingLink: yup
                .string()
                .required('Meeting Link is required')
                .url('Please enter a valid URL'),
        }),
        validateOnBlur: true,
        onSubmit: (data) => {
            updateMeetingLink({ meetingLink: data.meetingLink || null, bookingId: booking.id })
        }
    });

    const handleRemoveLink = () => {
        const confirmRemoval = window.confirm('Are you sure you want to remove the meeting link?');
        if (confirmRemoval) {
            updateMeetingLink({ meetingLink: null, bookingId: booking.id })
        }
    }

    const displayTutorView = () => {
        if (booking.meetingLink && !inEditMode) {
            return (
                <Box display="flex" alignItems="center">
                    <Link href={booking.meetingLink} target="_blank" color="secondary" sx={{ wordBreak: "break-word", cursor: "pointer" }}>{booking.meetingLink}</Link>
                    <Tooltip title="Edit Link" placement="top">
                        <IconButton sx={{ ml: 1 }} color='secondary' onClick={() => setInEditMode(true)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove Link" placement="top" onClick={handleRemoveLink}>
                        <IconButton color='error'>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            )
        }

        return (
            <>
                <TextField
                    fullWidth
                    autoComplete="off"
                    placeholder="Add a meeting URL"
                    name="meetingLink"
                    sx={{ borderRadius: "5px" }}
                    value={formik.values.meetingLink}
                    onChange={formik.handleChange}
                    error={formik.touched.meetingLink && Boolean(formik.errors.meetingLink)}
                    helperText={formik.touched.meetingLink && formik.errors.meetingLink}
                    onBlur={formik.handleBlur}
                />
                <Box textAlign="end" mt={2}>
                    <Button disabled={isLoading} variant="contained" color="secondary" onClick={formik.handleSubmit}>
                        {isLoading && (
                            <CircularProgress className="absolute-circular-progress" size={24} color="inherit" />
                        )}
                        {inEditMode ? "Update" : "Save"}
                    </Button>
                </Box>
            </>
        )
    }

    const displayStudentView = () => {
        return (
            <Link href={booking.meetingLink} target="_blank" color="secondary" sx={{ wordBreak: "break-all", cursor: "pointer" }}>{booking.meetingLink}</Link>
        )
    }

    return (
        <Box>
            <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="h6">
                    Meeting Link
                </Typography>
                <Tooltip
                    sx={{ ml: 1 }}
                    title={
                        user.role === 'tutor' ?
                            "Adding a meeting link is important for the student to join the session when the lesson starts." :
                            "The meeting link where the lesson will take place."
                    }
                    placement="top">
                    <IconButton>
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            {user.role === 'tutor' && displayTutorView()}
            {user.role === 'student' && displayStudentView()}
        </Box >
    )
}

export default AddMeetingLink