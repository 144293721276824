import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const fetchNotifications = async (page) => {
    const response = await requestEngine.get("/api/notifications?page=" + page);
    return response.data.data;
}

export const markNotificationAsRead = async (notificationId) => {
    const response = await requestEngine.post("/api/notification/" + notificationId + "/read");
    return response.data.data;
}

export const hideNotification = async (notificationId) => {
    const response = await requestEngine.post("/api/notification/" + notificationId + "/hide");
    return response.data.data;
}

export const fetchNotificationsUnreadCount = async () => {
    const response = await requestEngine.get("/api/notifications/unread/count");
    return response.data.data;
}