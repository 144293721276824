import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const createAd = async (data) => {
    const response = await requestEngine.post("/api/ad/create", data);
    return response.data.data;
}

export const updateAd = async (data) => {
    const response = await requestEngine.post("/api/ad/update", data);
    return response.data.data;
}

export const updateAdVisibility = async (data) => {
    const response = await requestEngine.post("/api/ad/" + data.id + "/update/visibility", { online: data.online });
    return response.data.data;
}

