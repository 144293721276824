import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const fetchCalendarEvents = async (startDate, endDate) => {
    const params = []

    if (startDate) {
        params.push("startDate=" + startDate)
    }

    if (endDate) {
        params.push("endDate=" + endDate)
    }

    var paramString = "?" + params.join("&");

    const response = await requestEngine.get("/api/calendar/events" + paramString);
    return response.data.data;
}


export const fetchCalendarEventDetails = async (bookingId, roleId) => {
    const response = await requestEngine.get("/api/calendar/event/" + bookingId + "/role/" + roleId);
    return response.data.data;
}

