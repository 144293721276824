import { useQuery } from 'react-query';
import { fetchRooms } from '../../../services/Room';

const useRooms = (query, onSuccess, onError) => {
    return useQuery(['rooms', query], () => fetchRooms(query || ""), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
        keepPreviousData: true, // Keep old data while new data is loading
    });
};

export default useRooms;
