import { Avatar, Box, Typography } from '@mui/material'
import React from 'react'
import getFormattedTime from '../utils/getFormattedTime'

const Lesson = ({ user, booking, children: menu }) => {
  const oppositeRole = user.role === 'tutor' ? 'student' : 'tutor'
  return (
    <Box borderRadius={4} border={"1px solid #d6d6d6"} p="1rem">
      <Box display="flex" gap={3} alignItems="center">
        <Box>
          {oppositeRole === 'student' && (
            <Avatar sx={{ width: 56, height: 56 }}>{booking[oppositeRole].credentials.firstName[0]}</Avatar>
          )}
          {oppositeRole === 'tutor' && (
            <Avatar alt={booking[oppositeRole].credentials.fullName} sx={{ width: 56, height: 56 }} />
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
          <Box>
            <Typography variant="body2" fontSize={15} fontWeight="600" mb={0.5}>
              {getFormattedTime(booking)}
            </Typography>
            <Typography variant="body2" fontSize={14} color="secondary">
              {booking[oppositeRole].credentials.firstName}, {booking.ad.subject.name}
            </Typography>
          </Box>
          {menu}
        </Box>
      </Box>
    </Box>
  )
}

export default Lesson