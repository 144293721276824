import { useFormik } from 'formik';
import React from 'react'
import * as yup from "yup"
import useCreateRoomAndSendMessage from "../../../../../hooks/shared/student_tutor/useCreateRoomAndSendMessage"
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

const SendMessage = ({ ad, onMessageSent, tutorId, studentId, firstName }) => {

  const handleSuccess = (room) => {
    enqueueSnackbar("Message was sent.", { variant: "success" });
    onMessageSent(room)
  }

  const { mutate: createRoomAndSendMessage, isLoading } = useCreateRoomAndSendMessage(handleSuccess)

  const formik = useFormik({
    initialValues: { text: "" },
    validationSchema: yup.object(({ text: yup.string().required('Message is required') })),
    validateOnBlur: true,
    onSubmit: (data) => {
      createRoomAndSendMessage({ text: data.text, tutorId, studentId })
    }
  });

  return (
    <Box>
      <Box>
        <Typography variant="body1" fontWeight={"600"} mb={1}>
          Write your first message to {firstName}
        </Typography>
        <TextField
          fullWidth
          autoComplete="off"
          placeholder="Ex: Wael Hamze is the best in the world"
          name="text"
          value={formik.values.text}
          onChange={formik.handleChange}
          multiline
          rows={4}
          error={formik.touched.text && Boolean(formik.errors.text)}
          helperText={formik.touched.text && formik.errors.text}
          onBlur={formik.handleBlur}
        />
      </Box>
      <Box mt={3} textAlign="right">
        <Button variant="contained" sx={{ maxWidth: 100, width: "100%" }} onClick={formik.handleSubmit} disabled={isLoading}>
          {isLoading && (
            <CircularProgress size={24} color="inherit" className='absolute-circular-progress' />
          )}
          Submit
        </Button>
      </Box>
    </Box>
  )
}

export default SendMessage