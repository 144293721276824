import * as yup from "yup";

export const validationSchema = yup.object({
    email: yup.string().email('Email is invalid').required('Email is required'),
    password: yup.string().required('Password is required'),
})

export const initialSchema = {
    email: "",
    password: "",
}