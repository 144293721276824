import ArticleIcon from '@mui/icons-material/Article';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { Box, Card, CardContent, IconButton, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';

const FileCard = ({ fileUrl, fileName, showRemove, onRemove, showDownload, showEdit, onEdit, loading }) => {
    const theme = useTheme();

    const getFileExtension = () => {
        return fileUrl?.split('.').pop().toLowerCase() || "";
    };

    const getIconForExtension = (extension) => {
        switch (extension) {
            case 'pdf':
                return <PictureAsPdfIcon sx={{ color: "red", fontSize: { xs: 24, md: 32 } }} />;
            case 'docx':
            case 'doc':
                return <ArticleIcon sx={{ color: theme.palette.primary.main, fontSize: { xs: 24, md: 32 } }} />;
            default:
                return <CloseIcon sx={{ fontSize: { xs: 24, md: 32 } }} />;
        }
    };

    const download = () => {
        const a = document.createElement("a");
        a.href = fileUrl;
        a.download = true;
        a.target = '_blank';
        a.click();
    };

    if (loading) {
        return (
            <Skeleton variant="rectangular" height={80} width={"100%"} sx={{ borderRadius: "15px" }} />
        );
    }

    return (
        <Card variant="outlined" sx={{ borderRadius: "15px", width: '100%', padding: 1 }}>
            <CardContent>
                <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
                        {getIconForExtension(getFileExtension(fileUrl))}
                        <Typography 
                            fontSize={{ xs: 14, sm: 15 }} 
                            component="span" 
                            sx={{ ml: 1, wordBreak: 'break-word', flex: 1 }}
                        >
                            {fileName ? fileName : "NO RESUME UPLOADED"}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gap={1} mt={{ xs: 1, sm: 0 }}>
                        {showRemove && fileUrl && (
                            <Tooltip title="Remove">
                                <IconButton sx={{ color: "red" }} onClick={onRemove}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {showDownload && fileUrl && (
                            <Tooltip title="Download">
                                <IconButton color="secondary" onClick={download}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {showEdit && (
                            <Tooltip title="Edit">
                                <IconButton color="secondary" onClick={onEdit}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default FileCard;
