import { MuiPalette } from "./MuiPalette";

export const MuiFormHelperText = {
    styleOverrides: {
        root: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: '4px 8px',
            borderRadius: 0,
            position: 'absolute',
            bottom: '-28px',
            left: 0,
            width: '100%',
            boxSizing: 'border-box',
            '&.Mui-error': {
                color: MuiPalette.error.main,
            },
        },
    },
};