import * as yup from "yup";

export const validationSchemas = [
    yup.object().shape({
        subject: yup.object().required('Subject is required'),
    }),

    yup.object().shape({
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required'),
        aboutMe: yup.string().required('About Me is required'),
    }),

    yup.object().shape({
        rate: yup.number().required('Rate is required').min(0.01, 'The minimum rate allowed is 1$'),
    }),

    yup.object().shape({
        isLessonOnline: yup.boolean(),
        isLessonInPerson: yup.boolean(),
    }).test('at-least-one', 'Must specify at least one service location', function (values, context) {
        if (!values.isLessonOnline && !values.isLessonInPerson) {
            return context.createError({ path: 'isLessonOnlineOrInHouse' })
        }

        return true
    }),
]

export const initialSchema = {
    subject: null,
    title: "",
    description: "",
    rate: "",
    isLessonOnline: false,
    isLessonInPerson: false
}