import { Box, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import Login from '../../pages/Public/Login';
import SignUp from '../../pages/Public/SignUp';
import { Link as RouterLink } from "react-router-dom"

const StudentAuth = ({ redirectURL }) => {
  const [isRegistering, setIsRegistering] = useState(false)

  return (
    <Box>
      {isRegistering ? (
        <SignUp redirectURL={redirectURL} smallTitle />
      ) : (
        <Login redirectURL={redirectURL} />
      )}
      <Box mt={2} textAlign="center">
        {isRegistering ? (
          <Typography variant="body2">Already have an account? {" "}
            <Link underline='hover' color="secondary" onClick={() => setIsRegistering(!isRegistering)} component={RouterLink}>
              Login
            </Link>
          </Typography>
        ) : (
          <Typography variant="body2">Don't have an account? {" "}
            <Link underline='hover' color="secondary" onClick={() => setIsRegistering(!isRegistering)} component={RouterLink}>
              Register
            </Link>
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default StudentAuth