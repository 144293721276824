import { Box, Card, CardContent, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

const ImageCard = ({ image, onRemove }) => {
    return (
        <Card variant="outlined" sx={{ borderRadius: "15px" }}>
            <CardContent>
                <Box position="relative">
                    <img src={URL.createObjectURL(image)} alt={image.name} style={{ width: "100%", borderRadius: "15px" }} />
                    <Tooltip title="Remove" placement="top">
                        <IconButton
                            sx={{ color: "red", position: 'absolute', top: 2, right: 2 }}
                            onClick={onRemove}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ImageCard;
