import { Navigate, useLocation, useNavigate } from 'react-router';
import useVerifyUser from "../../../hooks/Public/useVerifyUser";
import { enqueueSnackbar } from 'notistack';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import { useState } from 'react';

const VerifyAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const userId = params.get('ui');
    const token = params.get('vt'); 
    const [isVerified, setIsVerified] = useState(false);

    const handleSuccess = () => {
        setIsVerified(true);
    };

    const handleError = () => {
        enqueueSnackbar("Unable to verify your account.", { variant: "error" });
        navigate("/");
    };

    const { isLoading } = useVerifyUser(userId, token, handleSuccess, handleError);

    if (!userId || !token) {
        return <Navigate to="/" />;
    }

    if (isLoading) {
        return (
            <Box mt={4} textAlign="center">
                <Typography variant="h6" fontWeight="500">Verifying account...</Typography>
                <Box mt={3}>
                    <CircularProgress size={36} color="inherit" />
                </Box>
            </Box>
        );
    }

    if (isVerified) {
        return (
            <Box mt={4} textAlign="center">
                <Typography variant="h6" fontWeight="500">Your account has been verified!</Typography>
                <Box mt={2}>
                    <Typography variant="body1">You can now log in to your account.</Typography>
                </Box>
                <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={() => navigate("/login")} 
                    sx={{ mt: 3 }}
                >
                    Back to Login
                </Button>
            </Box>
        );
    }

    return null;
};

export default VerifyAccount;
