import MessageIcon from '@mui/icons-material/Message';
import { Badge, IconButton, Skeleton, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { useUserContext } from "../../../context/UserContext";
import { useQueryClient } from 'react-query';
import useMessagesUnreadCount from "../../../hooks/shared/student_tutor/useMessagesUnreadCount";
import { useSocket } from '../../../context/SocketContext';
import Constants from '../../../core/Constants';
import { useSnackbar } from 'notistack';

const Messages = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { data: unreadMessagesCount, isFetched, isLoading } = useMessagesUnreadCount();
  const socket = useSocket();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const navigateToMessages = () => {
    navigate(`/${user.role === 'tutor' ? 't' : 's'}/messages`);
  };

  useEffect(() => {
    if (!socket) return;

    socket.on(Constants.EVENTS.MESSAGE_RECEIVED, (message) => {
      if (message.senderId === user.id || message.read) return

      const isOnMessagesPage = window.location.pathname.includes("messages");

      if (!isOnMessagesPage) {
        enqueueSnackbar(`You received a new message.`);
      }

      queryClient.setQueryData('unreadMessagesCount', (oldCount = 0) => oldCount + 1);
    });

    socket.on(Constants.EVENTS.MESSAGES_MARKED_AS_READ, ({ updatedCount }) => {
      queryClient.setQueryData('unreadMessagesCount', (oldCount = 0) => oldCount - updatedCount);
    });

    return () => {
      socket.off(Constants.EVENTS.MESSAGE_RECEIVED);
      socket.off(Constants.EVENTS.MESSAGES_MARKED_AS_READ);
    };
  }, [socket, queryClient]);

  if (isLoading) {
    return <Skeleton variant="circular" width={40} height={40} />;
  }

  if (isFetched && !unreadMessagesCount) return <></>;

  return (
    <Tooltip title="Messages">
      <IconButton
        onClick={navigateToMessages}
        sx={{ border: '1px solid', borderColor: 'divider', borderRadius: '50%' }}
      >
        <Badge badgeContent={unreadMessagesCount} color="error">
          <MessageIcon sx={{ color: "black" }} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};

export default Messages;
