import React from 'react'
import dayjs from '../../../../../utils/dayjsConfig';
import { Box, Typography } from '@mui/material';

const Event = ({ event }) => {
  const start = dayjs(event.start).format("hh:mm A");
  const end = dayjs(event.end).format("hh:mm A");
  return (
    <Box>
      <Typography variant="body2" fontSize="small">
        {event.title}
      </Typography>
      <Typography variant="body2" fontSize="small">
        {`${start} - ${end}`}
      </Typography>
    </Box>
  );
}

export default Event