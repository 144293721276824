import { useQuery } from 'react-query';
import { fetchAvailability } from '../../services/TutorAvailablity';

const useAvailability = (date, onSuccess, onError) => {
    return useQuery(['availability'], () => fetchAvailability(date), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
        retry: false
    });
};

export default useAvailability;
