import { Box, Button, CircularProgress, FormControl, FormControlLabel, LinearProgress, MenuItem, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material';
import { useFormik, validateYupSchema } from "formik";
import { MuiTelInput } from 'mui-tel-input';
import { enqueueSnackbar } from "notistack";
import React, { useState } from 'react';
import { MuiFormHelperText } from "../../../assets/styles/mui/theme/MuiFormHelperText";
import FileCard from '../../../components/FileCard';
import ImageCard from '../../../components/ImageCard';
import useCreateTutor from '../../../hooks/Public/useCreateTutor';
import { initialSchema, validationSchemas } from "./yup";
import { useNavigate } from 'react-router';

const initialProgress = 16.666666666666666666666666666667;
const MAX_STEP = 6

function BecomeATutor() {
    const navigate = useNavigate()

    const [progress, setProgress] = useState(initialProgress);

    const step = Math.ceil(progress / initialProgress);

    const handleSuccess = () => {
        enqueueSnackbar("Verification Email Sent.", { variant: "success" })
        navigate("/activate-account")
    }

    const handleError = (data) => {
        enqueueSnackbar(data.message || "An error occurred while creating your profile.", { variant: "error" })
    }

    const { mutate: createTutor, isLoading } = useCreateTutor(handleSuccess, handleError);

    const validationSchema = validationSchemas[step - 1]

    const formik = useFormik({
        initialValues: initialSchema,
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (data) => {
            const formData = new FormData()

            formData.append("address", JSON.stringify({
                country: data.country,
                streetAddress: data.streetAddress,
                city: data.city,
            }))

            formData.append("user", JSON.stringify({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
            }))

            formData.append("tutor", JSON.stringify({
                gender: data.gender,
                phoneNumber: data.phoneNumber,
                dob: data.dob,
            }))

            formData.append("resume", data.resume)
            formData.append("profile", data.profile)

            createTutor(formData)
        }
    });

    const handleNext = async () => {
        try {
            await validateYupSchema(formik.values, validationSchema, true);
        } catch (validationErrors) {
            const errors = {};
            const touched = {};

            validationErrors.inner.forEach((error) => {
                errors[error.path] = error.message;
                touched[error.path] = true;
            });

            formik.setErrors(errors);
            formik.setTouched(touched);
            enqueueSnackbar('Please fix all errors before proceeding', { variant: 'error' });
            return
        }

        setProgress((prevProgress) => Math.min(prevProgress + initialProgress, 100));

        if (Math.ceil(progress / initialProgress) === MAX_STEP) {
            formik.setFieldTouched("resume", true)
            formik.setFieldTouched("profile", true)
            formik.handleSubmit();
        }
    };

    const handleBack = () => {
        setProgress((prevProgress) => Math.max(prevProgress - initialProgress, initialProgress));
    };

    return (
        <Box>
            <Typography variant="h4" fontWeight="bold" mb={5}>Become a tutor on TutorMe</Typography>
            <Typography variant="h5" fontWeight="bold" mb={3}>Let's get started with your profile</Typography>
            <Typography variant="body1" mb={2}>Profile</Typography>
            <LinearProgress variant="determinate" value={progress} sx={{ borderRadius: "20px", height: "6px" }} />
            <Typography variant="body1" mt={2} color="#4A709C">Step {step} of {MAX_STEP}</Typography>
            <Stack mt={4} spacing={3} pb={10}>
                {step === 1 && (
                    <>
                        <Box display={{ xs: "block", md: "flex" }} alignItems="center" gap={2}>
                            <Box flexGrow={1}>
                                <Typography variant="body1" fontWeight="bold" mb={1}>First Name</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter your first name"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                    name="firstName"
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    onBlur={formik.handleBlur}
                                />
                            </Box>
                            <Box flexGrow={1} mt={{ xs: 3, md: 0 }}>
                                <Typography variant="body1" fontWeight="bold" mb={1} >Last Name</Typography>
                                <TextField
                                    fullWidth
                                    placeholder="Enter your last name"
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                    name="lastName"
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    onBlur={formik.handleBlur}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Email</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter your email"
                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                name="email"
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                onBlur={formik.handleBlur}
                            />
                        </Box>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Phone Number</Typography>
                            <MuiTelInput
                                fullWidth
                                placeholder="Enter your number"
                                variant="outlined"
                                onlyCountries={["LB"]}
                                onChange={(value) => formik.setFieldValue("phoneNumber", value)}
                                value={formik.values.phoneNumber}
                                name="phoneNumber"
                                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                onBlur={formik.handleBlur}
                            />
                        </Box>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Gender</Typography>
                            <FormControl>
                                <RadioGroup
                                    onChange={formik.handleChange}
                                    value={formik.values.gender}
                                    name="gender"
                                    onBlur={formik.handleBlur}
                                >
                                    <FormControlLabel value="female" control={<Radio />} label={<Typography fontSize={15} fontWeight="400">Female</Typography>} />
                                    <FormControlLabel value="male" control={<Radio />} label={<Typography fontSize={15} fontWeight="400">Male</Typography>} />
                                </RadioGroup>
                                {formik.touched.gender && formik.errors.gender && (
                                    <Typography variant="caption" color="error">
                                        {formik.errors.gender}
                                    </Typography>
                                )}
                            </FormControl>
                        </Box>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Date of Birth</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                type="date"
                                onChange={formik.handleChange}
                                value={formik.values.dob}
                                name="dob"
                                error={formik.touched.dob && Boolean(formik.errors.dob)}
                                helperText={formik.touched.dob && formik.errors.dob}
                                onBlur={formik.handleBlur}
                            />
                        </Box>
                    </>
                )}
                {step === 3 && (
                    <>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Country</Typography>
                            <FormControl fullWidth>
                                <Select
                                    variant='outlined'
                                    displayEmpty
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.country}
                                    name="country"
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                    onBlur={formik.handleBlur}
                                >
                                    <MenuItem value="" disabled>
                                        <Typography fontSize={15} fontWeight="400">Select your country</Typography>
                                    </MenuItem>
                                    <MenuItem value={"Lebanon"}>
                                        <Typography fontSize={15} variant="body2" fontWeight="400">Lebanon</Typography>
                                    </MenuItem>
                                </Select>
                                {formik.touched.country && formik.errors.country && (
                                    <Typography variant="caption" color="error" sx={{ ...MuiFormHelperText.styleOverrides.root }}>
                                        {formik.errors.country}
                                    </Typography>
                                )}
                            </FormControl>
                        </Box>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>City</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter your city"
                                onChange={formik.handleChange}
                                value={formik.values.city}
                                name="city"
                                error={formik.touched.city && Boolean(formik.errors.city)}
                                helperText={formik.touched.city && formik.errors.city}
                                onBlur={formik.handleBlur}
                            />
                        </Box>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Street Address</Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                placeholder="Enter your street address"
                                onChange={formik.handleChange}
                                value={formik.values.streetAddress}
                                name="streetAddress"
                                error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}
                                helperText={formik.touched.streetAddress && formik.errors.streetAddress}
                                onBlur={formik.handleBlur}
                            />
                        </Box>
                    </>
                )}
                {step === 4 && (
                    <>
                        <Box>
                            {!formik.values.resume ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="orange"
                                        component="label"
                                    >
                                        Upload your Resume
                                        <input
                                            name="resume"
                                            onChange={(e) => { formik.setFieldValue("resume", e.target.files[0]); }}
                                            type="file"
                                            accept=".pdf,.doc,.docx"
                                            hidden
                                        />
                                    </Button>
                                    {formik.touched.resume && formik.errors.resume && (
                                        <Typography variant="caption" color="error" ml={2}>
                                            {formik.errors.resume}
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1" fontWeight="bold" mb={1}>Resume</Typography>
                                    <FileCard fileUrl={formik.values.resume.name} fileName={formik.values.resume.name} showRemove onRemove={() => formik.setFieldValue("resume", null)} />
                                </>
                            )}
                        </Box>
                    </>
                )}
                {step === 5 && (
                    <>
                        <Box>
                            {!formik.values.profile ? (
                                <>
                                    <Button
                                        variant="contained"
                                        color="orange"
                                        component="label"
                                    >
                                        Upload your profile picture
                                        <input
                                            name="profile"
                                            onChange={(e) => formik.setFieldValue("profile", e.target.files[0])}
                                            type="file"
                                            accept="image/*"
                                            hidden
                                        />
                                    </Button>
                                    {formik.touched.profile && formik.errors.profile && (
                                        <Typography variant="caption" color="error" ml={2}>
                                            {formik.errors.profile}
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <>
                                    <Typography variant="body1" fontWeight="bold" mb={1}>Profile Picture</Typography>
                                    <ImageCard image={formik.values.profile} onRemove={() => formik.setFieldValue("profile", null)} />
                                </>
                            )}
                        </Box>
                    </>
                )}
                {step === 6 && (
                    <>
                        <Box display={{ xs: "block", md: "flex" }} alignItems="center" gap={2}>
                            <Box flexGrow={1}>
                                <Typography variant="body1" fontWeight="bold" mb={1}>Password</Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Enter your password"
                                    onChange={formik.handleChange}
                                    value={formik.values.password}
                                    name="password"
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    onBlur={formik.handleBlur}
                                    type="password"
                                />
                            </Box>
                            <Box flexGrow={1} mt={{ xs: 3, md: 0 }}>
                                <Typography variant="body1" fontWeight="bold" mb={1} >Confirm Password</Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Confirm your password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    value={formik.values.confirmPassword}
                                    name="confirmPassword"
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    onBlur={formik.handleBlur}
                                />
                            </Box>
                        </Box>
                    </>
                )}
            </Stack>
            <Box
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                display="flex"
                justifyContent="center"
                gap={2}
                p={2}
                bgcolor="white"
            >
                <Box display="flex" gap={2} width="fit-content">
                    <Button disabled={step === 1 || isLoading} variant="contained" onClick={handleBack} sx={{ fontSize: "16px", pl: "2rem", pr: "2rem" }}>
                        Back
                    </Button>
                    <Button disabled={isLoading} variant="contained" onClick={handleNext} sx={{ fontSize: "16px", pl: "2rem", pr: "2rem" }}>
                        {isLoading &&
                            <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
                        }
                        {step === MAX_STEP ? "Create Profile" : "Next"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default BecomeATutor;
