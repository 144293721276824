import React from 'react';
import { useUserContext } from '../context/UserContext';
import PublicLayout from './PublicLayout';
import StudentLayout from './StudentLayout';
import TutorLayout from './TutorLayout';

function Layout({ isMessages, ...props }) {
    const { isAuthenticated, user } = useUserContext()

    if (!isAuthenticated) {
        return (
            <PublicLayout {...props} />
        )
    }

    if (user.role === "tutor") {
        return <TutorLayout isMessages={isMessages} {...props} />
    }

    return <StudentLayout isMessages={isMessages} {...props} />
}

export default Layout;
