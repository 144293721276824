import Layout from "../../layouts"
import AboutUs from "../../pages/shared/for_all/AboutUs"

const commonRoutes = [
    {
        path: "/about-us",
        element: <Layout maxWidth="lg" footer />,
        children: [
            {  element: <AboutUs /> },
        ]
    },
]

export default commonRoutes