export const serveMenuItems = (isAuthenticated, role) => {

    // public 
    if (!isAuthenticated) {
        return [
            { text: 'Become a tutor', key: 'becomeTutor', route: '/become-a-tutor' },
            { text: 'Sign up', key: 'signUp', route: '/sign-up-as-a-student' },
            { text: 'Login', key: 'login', route: '/login' },
        ]
    }

    // tutor
    if (role === "tutor") {
        return [
            { text: 'Messages', key: 'students', route: "/t/messages" },
            { text: 'My Ads', key: 'ads', route: "/t/my-ads" },
            { text: 'Lessons', key: 'lessons', route: "/t/lessons/upcoming" },
            { text: 'Availability', key: 'my-hours', route: "/t/my-hours" },
            { text: 'Calendar', key: 'calendar', route: "/t/calendar" },
            { text: 'Settings', key: 'settings', route: "/t/settings/profile" },
        ]
    }

    // student
    return [
        { text: 'Listings', key: 'listings', route: "/listings" },
        { text: 'Messages', key: 'students', route: "/s/messages" },
        { text: 'Lessons', key: 'lessons', route: '/s/lessons/upcoming' },
        { text: 'Calendar', key: 'calendar', route: '/s/calendar' }
    ]
}