import { Autocomplete, Box, Slider, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import useSubjectSearch from '../../../../hooks/shared/for_all/useSubjectSearch';
import { getParsedParams } from "./utils";

const Filters = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const initialFilters = getParsedParams(searchParams);

    const [filters, setFilters] = useState(initialFilters);

    const { isLoading, handleInputChange, getOptions } = useSubjectSearch(filters.subject?.name);

    const updateQueryParams = (key, value) => {
        const params = new URLSearchParams(location.search);
        if (value) {
            params.set(key, value);
        } else {
            params.delete(key);
        }
        navigate(`${location.pathname}?${params.toString()}`);
    };

    const handleFilterChange = (key, value, no_update) => {
        setFilters((prev) => ({ ...prev, [key]: value }));

        if (no_update) return

        if (key === 'subject') {
            const subjectParam = value ? `${value.name},${value.id}` : null;
            updateQueryParams('s', subjectParam);
        } else if (key === 'rateRange') {
            updateQueryParams('r', value.join(','));
        }
    };

    return (
        <Box sx={{ p: 3, border: "1px solid #d6d6d6", borderRadius: "5px", height: { lg: "inherit", xs: "unset" } }}>
            <Typography variant="h5" color="secondary" fontWeight="600" mb={2}>
                Filters
            </Typography>
            <Stack gap={2} width={{ lg: "350px", xs: "100%" }}>
                <Box>
                    <Typography variant="body1" mb={1} fontWeight="500">
                        Choose a subject
                    </Typography>
                    <Autocomplete
                        onInputChange={handleInputChange}
                        options={getOptions()}
                        value={filters.subject}
                        filterSelectedOptions
                        includeInputInList
                        loading={isLoading}
                        filterOptions={(x) => x}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(_, value) => handleFilterChange('subject', value)}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search subjects"
                            />
                        )}
                    />
                </Box>
                <Box>
                    <Typography variant="body1" fontWeight="500">
                        Choose a rate range
                    </Typography>
                    <Slider
                        min={0}
                        max={400}
                        sx={{ mt: 1.3, width: "95%", ml: 1 }}
                        value={filters.rateRange}
                        onChange={(_, value) => handleFilterChange('rateRange', value, true)}
                        onChangeCommitted={(_, value) => handleFilterChange('rateRange', value)}
                        valueLabelDisplay="auto"
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export default Filters;
