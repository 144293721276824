import { useQuery } from 'react-query';
import { fetchNotificationsUnreadCount } from '../../../services/Notification';

const useNotificationsUnreadCount = (onSuccess, onError) => {
    return useQuery('unreadNotificationsCount', fetchNotificationsUnreadCount, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
    });
};

export default useNotificationsUnreadCount;
