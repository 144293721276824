import { Box, Button, CircularProgress, Grid, Link, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import useLogin from '../../../hooks/Public/useLogin';
import { initialSchema, validationSchema } from './yup';
import { Link as RouterLink } from "react-router-dom";

function Login({ redirectURL = "", header }) {
    const handleSuccess = (data) => {
        enqueueSnackbar("Welcome back, " + data.firstName + "!", { variant: "success" });
        localStorage.setItem("token", data.token);
        window.location.href = redirectURL;
    };

    const handleError = (data) => {
        enqueueSnackbar(data.message || "An error occured.", { variant: "error" });
    };

    const { mutate: login, isLoading, isSuccess } = useLogin(handleSuccess, handleError);

    const formik = useFormik({
        initialValues: initialSchema,
        validationSchema: validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: (data) => {
            login(data);
        }
    });

    return (
        <Box>
            {header && <Typography variant="h4" fontWeight="bold" mb={5}>Welcome Back!</Typography>}
            <Typography variant="h5" fontWeight="bold" mb={3}>Log in to your account</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body1" fontWeight="bold" mb={1}>Email</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter your email"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        name="email"
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" fontWeight="bold" mb={1}>Password</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter your password"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        name="password"
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onBlur={formik.handleBlur}
                        type="password"
                    />
                </Grid>
            </Grid>
            <Box mt={3} textAlign="center">
                <Typography variant="body2">Forgot your password? {" "}
                    <Link underline='hover' color="secondary" component={RouterLink} to="/request-reset-password">
                        Reset
                    </Link>
                </Typography>
            </Box>
            <Box mt={2}>
                <Button onClick={() => formik.handleSubmit()} fullWidth disabled={isLoading || isSuccess} variant="contained" sx={{ fontSize: "16px", pt: "0.6rem", pb: "0.6rem" }}>
                    {(isLoading || isSuccess) &&
                        <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
                    }
                    Login
                </Button>
            </Box>
        </Box>
    );
}

export default Login;
