import { Box, Container } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router';
import Footer from '../components/Footer';
import Header from '../components/Header';

function PublicLayout(props) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header />
            <Container sx={{ ...props, p: { xs: 2, md: 4 }, pt: { xs: 3, md: 5 }, mx: 'auto' }} maxWidth={props.maxWidth}>
                <Outlet />
            </Container>
            {props.footer && <Footer />}
        </Box >
    );
}

export default PublicLayout;
