import { Avatar, Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import FileCard from '../../../../components/FileCard';
import StudentAuth from '../../../../components/StudentAuth';
import YoutubeVideo from '../../../../components/YoutubeVideo';
import { useUserContext } from '../../../../context/UserContext';
import useModal from '../../../../hooks/core/useModal';
import useListing from '../../../../hooks/useListing';
import BookALesson from './BookALesson';
import Reviews from './Reviews';
import SendMessage from './SendMessage';

const BOOK_LESSON_ID = Math.floor(Date.now() * Math.random());
const SEND_MESSAGE_ID = Math.floor(Date.now() * Math.random());
const AUTH_ID = Math.floor(Date.now() * Math.random());

const ListingDetails = () => {
    const navigate = useNavigate()
    const { isAuthenticated, user } = useUserContext();
    const { listingId } = useParams();
    const { data: ad, isLoading: isLoadingListing, isError } = useListing(listingId, isAuthenticated);
    const { openModal, closeModal, ModalComponent } = useModal();

    const handleBookingAction = () => {
        if (!isAuthenticated) {
            openModal(AUTH_ID);
            return;
        }
        openModal(BOOK_LESSON_ID);
    };

    const handleMessageAction = () => {
        if (!isAuthenticated) {
            openModal(AUTH_ID);
            return;
        }
        openModal(SEND_MESSAGE_ID);
    };

    const onLessonBooked = () => closeModal(BOOK_LESSON_ID);
    const onMessageSent = () => closeModal(SEND_MESSAGE_ID);

    const navigateToMessages = () => {
        const setupTab = window.open(`/s/messages?r=${ad.roomId}`, '_blank');
        if (setupTab) setupTab.focus();
    };

    if (isLoadingListing) {
        return (
            <Box mt={6} textAlign="center">
                <CircularProgress disableShrink />
            </Box>
        );
    }

    if (isError) {
        navigate(-1); // Navigate to the previous route
        return null; // Prevent further rendering
    }

    return (
        <Box px={{ xs: 2, md: 5 }}>
            <Grid container spacing={3} alignItems="center" justifyContent="space-between">
                <Grid item xs={12} md={8} display="flex" alignItems="center" gap={2}>
                    <Avatar
                        src={ad.tutor.profileUrl}
                        sx={{ width: { xs: 80, md: 120 }, height: { xs: 80, md: 120 }, fontSize: { xs: "2rem", md: "3rem" } }}
                    >
                        {ad.tutor.credentials.firstName[0]}
                    </Avatar>
                    <Box>
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            color="primary.main"
                            noWrap
                            gutterBottom
                        >
                            {ad.tutor.credentials.fullName}
                        </Typography>
                        <Typography
                            variant="h6"
                            fontWeight="600"
                            whiteSpace="pre-wrap"
                            sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {ad.title}
                        </Typography>
                    </Box>
                </Grid>
                {(!isAuthenticated || user?.role === 'student') && (
                    <Grid item xs={12} md="auto">
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                            {ad.roomExists ? (
                                <Button
                                    sx={{ width: 'max-content' }}
                                    variant="outlined"
                                    onClick={navigateToMessages}
                                >
                                    View Messages
                                </Button>
                            ) : (
                                <Button variant="outlined" onClick={handleMessageAction}>
                                    Message
                                </Button>
                            )}
                            <ModalComponent id={SEND_MESSAGE_ID} maxWidth={500}>
                                <SendMessage
                                    ad={ad}
                                    onMessageSent={onMessageSent}
                                    tutorId={ad.tutor.id}
                                    firstName={ad.tutor.credentials.firstName}
                                />
                            </ModalComponent>
                            <Button variant="contained" onClick={handleBookingAction}>
                                Book a Lesson
                            </Button>
                            <ModalComponent id={BOOK_LESSON_ID} maxWidth={600}>
                                <BookALesson ad={ad} onLessonBooked={onLessonBooked} />
                            </ModalComponent>
                            <ModalComponent id={AUTH_ID} maxWidth={600} hideHeader>
                                <StudentAuth redirectURL={`/listings/${listingId}`} />
                            </ModalComponent>
                        </Stack>
                    </Grid>
                )}
            </Grid>
            <Stack spacing={4} mt={6}>
                <Section title="About Me" content={ad.aboutMe} />
                <Section title="Description" content={ad.description} />
                <Box>
                    <Typography variant="h6" fontWeight="bold">
                        Resume
                    </Typography>
                    <Box mt={1}>
                        {ad.tutor.resume ? (
                            <FileCard fileUrl={ad.tutor.resumeUrl} fileName={ad.tutor.resume} showDownload />
                        ) : (
                            <Typography variant="body1" color="text.secondary">
                                No resume was found.
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Typography variant="h6" fontWeight="bold">
                        Video Introduction
                    </Typography>
                    <Box mt={1}>
                        {ad.youtubeLink ? (
                            <Box>
                                <YoutubeVideo videoUrl={ad.youtubeLink} />
                            </Box>
                        ) : (
                            <Typography variant="body1" color="text.secondary">
                                No video introduction was found.
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Reviews reviews={ad.reviews} />
            </Stack>
        </Box>
    );
};

const Section = ({ title, content }) => (
    <Box>
        <Typography variant="h6" fontWeight="bold">
            {title}
        </Typography>
        <Box mt={1}>
            <Typography variant="body1" color="text.secondary">
                {content}
            </Typography>
        </Box>
    </Box>
);

export default ListingDetails;
