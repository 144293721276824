import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const createUpdateAvailability = async (data) => {
    const response = await requestEngine.post("/api/tutor/availability", data);
    return response.data.data;
}

export const fetchAvailability = async (date) => {
    const response = await requestEngine.get("/api/tutor/availability?date=" + date);
    return response.data.data;
}

export const fetchAvailableSlots = async (tutorId, date) => {
    const response = await requestEngine.get("/api/tutor/" + tutorId + "/availability/slots?date=" + date);
    return response.data.data;
}

export const fetchLessonLengths = async (tutorId, date, startTime) => {
    const response = await requestEngine.get("/api/tutor/" + tutorId + "/availability/lesson-lengths?date=" + date + "&startTime=" + startTime);
    return response.data.data;
}
