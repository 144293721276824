import { Container, Paper, Tab, Tabs, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Profile from './Profile';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: { xs: 2, md: 3 } }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Settings() {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

    const tabValues = ['profile'];
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        const currentTab = location.pathname.split('/').pop();
        const tabIndex = tabValues.indexOf(currentTab);
        setValue(tabIndex !== -1 ? tabIndex : 0);
    }, [location.pathname]);

    const handleChange = (_, newValue) => {
        setValue(newValue);
        navigate(`/t/settings/${tabValues[newValue]}`);
    };

    return (
        <Box width="100%">
            <Box
                display="flex"
                flexDirection={isMediumScreen ? 'column' : 'row'}
                gap={2}
                height="inherit"
            >
                <Tabs
                    orientation={isMediumScreen ? 'horizontal' : 'vertical'}
                    value={value}
                    onChange={handleChange}
                    sx={{
                        borderRight: isMediumScreen ? 0 : 1,
                        borderBottom: isMediumScreen ? 1 : 0,
                        borderColor: 'divider',
                        width: '100%',
                        maxWidth: isMediumScreen ? '100%' : '150px',
                        position: isMediumScreen ? 'relative' : 'sticky',
                        top: 0,
                    }}
                    indicatorColor="secondary"
                    textColor="secondary"
                >
                    <Tab label="Profile" {...a11yProps(0)} />
                </Tabs>

                <CustomTabPanel value={value} index={0}>
                    <Profile />
                </CustomTabPanel>
            </Box>
        </Box>
    );
}
