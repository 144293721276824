import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, Box, CircularProgress, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from "react-router-dom";
import useMenu from '../../../hooks/core/useMenu';
import { useUserContext } from '../../../context/UserContext';
import useLogout from "../../../hooks/shared/student_tutor/useLogout"

const ACCOUNT_SETTINGS = "account-menu"

const Account = ({ mainMenuItems, onLogout }) => {
  const { openMenu, closeMenu, MenuComponent } = useMenu();
  const navigate = useNavigate()
  const { user } = useUserContext();
  const { mutate: logout, isLoading, isSuccess } = useLogout(onLogout)

  const handleAccountClick = (event) => {
    openMenu(ACCOUNT_SETTINGS, event.currentTarget);
  };

  const navigateTo = (menuItem) => {
    closeMenu(ACCOUNT_SETTINGS);
    navigate(menuItem.route);
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleAccountClick}
          size="small"
        >
          <Avatar sx={{ width: 43.2, height: 43.2 }} src={user.profileUrl}>
            {user.firstName[0]}
          </Avatar>
        </IconButton>
      </Tooltip>
      <MenuComponent
        id="account-menu"
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'auto',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 21,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
              width: 300
            },
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {mainMenuItems.map(menuItem => {
          return (
            <MenuItem onClick={() => navigateTo(menuItem)} key={menuItem.key} sx={{ pl: 2, pt: 1.5, pb: 1.5 }}>
              <Typography variant="body2" fontWeight="600">{menuItem.text}</Typography>
            </MenuItem>
          )
        })}
        <MenuItem key="logout" onClick={logout} disabled={isLoading || isSuccess}>
          <Box display="flex" alignItems="center" gap={1}>
            <LogoutIcon sx={{ fontSize: 18 }} />
            <Typography variant="body2" fontWeight="600">Logout</Typography>
          </Box>
          {(isLoading || isSuccess) && (
            <CircularProgress size={24} className='absolute-circular-progress' />
          )}
        </MenuItem>
      </MenuComponent>
    </>

  )
}

export default Account