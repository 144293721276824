import { Box, Divider, Skeleton } from '@mui/material'
import React from 'react'

const SkeletonLoader = ({ role }) => {
  return (
    <Box>
      <Skeleton variant="text" width="60%" height={35} />
      <Skeleton variant="text" width="40%" height={25} />
      <Box mt={2}>
        <Divider />
        <Box display="flex" mt={2} mb={2}>
          <Box flex={1}>
            <Skeleton variant="text" width="80%" height={18} />
          </Box>
          <Box flex={1}>
            <Skeleton variant="text" width="80%" height={18} />
          </Box>
        </Box>
        <Divider />
        <Box display="flex" mt={2}>
          <Box flex={1}>
            <Skeleton variant="text" width="80%" height={18} />
          </Box>
          <Box flex={1}>
            <Skeleton variant="text" width="80%" height={18} />
          </Box>
        </Box>
      </Box>
      <Box mt={4} display="flex" alignItems="center" gap={1.5}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" width="50%" height={25} />
        <Skeleton variant="circular" width={32} height={32} />
      </Box>
      {role === 'student' && (
        <Box mt={2}>
          <Skeleton variant="text" width="40%" height={25} />
        </Box>
      )}
    </Box>
  )
}

export default SkeletonLoader