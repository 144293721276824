import { Navigate } from 'react-router';
import Layout from '../../layouts';
import ListingDetails from '../../pages/shared/for_all/ListingDetails';
import MyCalendar from '../../pages/shared/student_tutor/Calendar';
import Messages from '../../pages/shared/student_tutor/Messages';
import StudentLessons from '../../pages/Student/Lessons';
import Listings from '../../pages/Student/Listings';
import commonRoutes from './common';

/**
 * Student Routes Configuration
 *
 * This file contains the routing configuration for students pages in the application.
 * Each route defines the path, associated component, and layout to be used.
 */

const studentRoutes = [
    {
        path: "/s",
        element: <Layout maxWidth="lg" />,
        children: [
            { path: "lessons/upcoming", element: <StudentLessons /> },
            { path: "lessons/canceled", element: <StudentLessons /> },
            { path: "lessons/pending", element: <StudentLessons /> },
            { path: "lessons/ongoing", element: <StudentLessons /> },
            { path: "lessons/completed", element: <StudentLessons /> },
            { path: "lessons/rejected", element: <StudentLessons /> },
            { path: "calendar", element: <MyCalendar /> }
        ]
    },
    {
        path: "/s",
        element: <Layout maxWidth="100%" isMessages height="calc(100vh - 64.8px)" />,
        children: [
            { path: "messages", element: <Messages /> }
        ]
    },
    {
        path: "/",
        element: <Navigate to="/listings" replace />
    },
    {
        path: "/",
        element: <Layout maxWidth="lg" />,
        children: [
            { path: "listings/:listingId", element: <ListingDetails /> }
        ]
    },
    {
        path: "/",
        element: <Layout maxWidth="xl" />,
        children: [
            { path: "listings", element: <Listings /> }
        ]
    },
    ...commonRoutes,
    {
        path: "*",
        element: <Navigate to="/listings" replace />
    }
]

export default studentRoutes