import { useMutation } from 'react-query';
import { rejectBooking } from '../../../services/Booking';

const useRejectBooking = (onSuccess, onError) => {
    return useMutation(rejectBooking, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data)
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useRejectBooking;
