import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CancelIcon from '@mui/icons-material/Cancel';
import LinkIcon from '@mui/icons-material/Link';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
    Box, CircularProgress, Divider,
    IconButton,
    MenuItem, Skeleton, Stack, Tooltip, Typography
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQueryClient } from 'react-query';
import { useUserContext } from '../../../../../context/UserContext';
import useMenu from '../../../../../hooks/core/useMenu';
import useModal from '../../../../../hooks/core/useModal';
import useCancelBooking from "../../../../../hooks/shared/student_tutor/useCancelBooking";
import useUpcomingBookings from "../../../../../hooks/shared/student_tutor/useUpcomingBookings";
import Lesson from '../Lesson';
import LessonSkeleton from '../Lesson/Skeleton';
import groupBookingsByDate from '../utils/groupBookingsByDate';
import MeetingLink from './MeetingLink';

const ACTIONS_MENU = "actionsMenu"
const LINK_MODAL = "linkModal"

const Upcoming = () => {
    const queryClient = useQueryClient()
    const { data: upcomingBookings, isLoading, refetch, fetchNextPage, hasNextPage } = useUpcomingBookings();
    const { user } = useUserContext();

    const { openMenu, closeMenu, MenuComponent, getSelectedItem } = useMenu();
    const { openModal, closeModal, getModalData, ModalComponent } = useModal();

    const handleSuccess = () => {
        enqueueSnackbar("Booking was canceled.", { variant: "success" });
        closeMenu(ACTIONS_MENU);
        refetch();
    };

    const handleError = (data) => {
        enqueueSnackbar(data.message, { variant: "error" });
        closeMenu(ACTIONS_MENU);
    };

    const { mutate: cancelBooking, isLoading: isLoadingCancel } = useCancelBooking(handleSuccess, handleError);

    const handleCancelLesson = () => {
        const confirmCancellation = window.confirm('THIS ACTION IS IRREVERSIBLE! Are you sure you want to proceed?');
        if (confirmCancellation) {
            closeMenu(ACTIONS_MENU);
            cancelBooking(getSelectedItem(ACTIONS_MENU).id);
        }
    };

    const handleRescheduleLesson = () => {
        closeMenu(ACTIONS_MENU);
    };

    const handleNavigateToListing = () => {
        const setupTab = window.open("/listings/" + getSelectedItem(ACTIONS_MENU).ad.id, "_blank");
        if (setupTab) {
            setupTab.focus();
        }
    };

    const onMeetingLinkUpdated = (meetingLink, bookingId) => {
        queryClient.setQueryData('upcomingBookings', oldData => {
            if (!oldData) return [];

            return {
                ...oldData,
                pages: oldData.pages.map(page => {
                    return {
                        ...page,
                        bookings: page.bookings.map(booking => {
                            if (booking.id === bookingId) {
                                return { ...booking, meetingLink }
                            }
                            return { ...booking }
                        })
                    }
                })
            }
        });

        closeModal(LINK_MODAL);
    }

    if (isLoading) {
        return (
            <Box>
                {new Array(3).fill().map((_, index) => {
                    return (
                        <LessonSkeleton key={index} />
                    )
                })}
            </Box>
        )
    }

    const data = upcomingBookings?.pages.flatMap(page => page.bookings) || []

    if (!data.length) {
        return (
            <Typography variant="body2">No upcoming lessons were found.</Typography>
        )
    }

    const groupedBookings = groupBookingsByDate(data);

    const oppositeRole = user.role === 'tutor' ? 'student' : 'tutor';

    return (
        <Box>
            <InfiniteScroll
                dataLength={data.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<CircularProgress size={24} color="inherit" />}
            >
                {Object.keys(groupedBookings).map(date => (
                    <Box key={date} mb={3}>
                        <Typography fontSize="1.1rem" fontWeight="bold" mb={2}>{date}</Typography>
                        <Stack spacing={2.5}>
                            {groupedBookings[date].map(booking => (
                                <Lesson key={booking.id} user={user} booking={booking}>
                                    <Box>
                                        <Tooltip title="Meeting Link" placement='top'>
                                            <IconButton onClick={() => openModal(LINK_MODAL, booking)} color={booking.meetingLink ? "success" : "unset"}>
                                                <LinkIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Actions" placement='top'>
                                            <IconButton
                                                aria-haspopup="true"
                                                onClick={(event) => openMenu(ACTIONS_MENU, event.currentTarget, booking)}>
                                                <MoreHorizIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </Lesson>
                            ))}
                        </Stack>
                    </Box>
                ))}
            </InfiniteScroll>
            <MenuComponent
                id={ACTIONS_MENU}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {user.role === 'student' && (
                    <>
                        <MenuItem onClick={handleNavigateToListing}>
                            <AccountBoxIcon sx={{ mr: 1, fontSize: "1.2rem" }} />
                            {getSelectedItem(ACTIONS_MENU) ? (
                                <Typography variant="body2">View {getSelectedItem(ACTIONS_MENU)?.[oppositeRole].credentials.firstName}'s Listing</Typography>
                            ) : (
                                <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={"130px"} />
                            )}
                        </MenuItem>
                        <Divider />
                    </>
                )}
                {/* <MenuItem onClick={handleRescheduleLesson}>
                    <ScheduleIcon sx={{ mr: 1, fontSize: "1.2rem" }} />
                    <Typography variant="body2">Reschedule</Typography>
                </MenuItem> */}
                <MenuItem disabled={isLoadingCancel} onClick={handleCancelLesson}>
                    {isLoadingCancel && (
                        <CircularProgress size={24} color="inherit" className='absolute-circular-progress' />
                    )}
                    <CancelIcon sx={{ mr: 1, fontSize: "1.2rem" }} />
                    <Typography variant="body2">Cancel Lesson</Typography>
                </MenuItem>
            </MenuComponent>
            <ModalComponent
                hideHeader
                id={LINK_MODAL}
                maxWidth={600}
            >
                <MeetingLink onMeetingLinkUpdated={onMeetingLinkUpdated} booking={getModalData(LINK_MODAL)} user={user} />
            </ModalComponent>
        </Box>
    );
};

export default Upcoming;
