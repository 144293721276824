import MessageIcon from '@mui/icons-material/Message';
import { Avatar, Box, Divider, IconButton, Link, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from "react-router";
import { useUserContext } from '../../../../../context/UserContext';
import dayjs from '../../../../../utils/dayjsConfig';
import useModal from '../../../../../hooks/core/useModal';
import useCalendarEventDetails from "../../../../../hooks/shared/student_tutor/useCalendarEventDetails";
import SkeletonLoader from "./SkeletonLoader";
import SendMessage from '../../../for_all/ListingDetails/SendMessage';

const SEND_MESSAGE_ID = Math.floor(Date.now() * Math.random())

const EventDetails = ({ event }) => {
  const { data: eventDetails, isLoading, isRefetching } = useCalendarEventDetails(event.id, typeof event.student !== "undefined" ? event.student.id : event.tutor.id);
  const { user } = useUserContext();
  const navigate = useNavigate()
  const { openModal, ModalComponent, closeModal } = useModal()

  const getFormattedTime = () => {
    const start = dayjs(event.start);
    const end = dayjs(event.end);
    return `${start.format("dddd, MMMM D, h:mm A")} - ${end.format("h:mm A")}`;
  };

  const navigateToListing = () => {
    const setupTab = window.open("/listings/" + event.ad.id, "_blank");
    if (setupTab) {
      setupTab.focus();
    }
  };

  const onMessageSent = (room) => {
    closeModal(SEND_MESSAGE_ID)
    navigate(`/${user.role[0]}/messages?r=${room.id}`);
  }

  const navigateToMessages = () => {
    if (!eventDetails.roomId) {
      openModal(SEND_MESSAGE_ID)
      return
    }

    navigate(`/${user.role[0]}/messages?r=${eventDetails.roomId}`);
  }

  if (isLoading || isRefetching) return <SkeletonLoader role={user.role} />

  const oppositeRole = user.role === 'tutor' ? 'student' : 'tutor';

  return (
    <>
      <ModalComponent id={SEND_MESSAGE_ID} maxWidth={500}>
        <SendMessage
          ad={event.ad}
          onMessageSent={onMessageSent}
          tutorId={event.tutor?.id}
          studentId={event.student?.id}
          firstName={event[oppositeRole].credentials.firstName} />
      </ModalComponent>
      <Box>
        <Typography variant="h6" fontWeight="600">{event.title}</Typography>
        <Typography variant="body2" color="secondary">{getFormattedTime()}</Typography>
        <Box mt={2}>
          <Divider />
          <Box display="flex" mt={2} mb={2}>
            <Box flex={1}>
              <Typography variant="body2">Subject</Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="body2">{event.ad.subject.name}</Typography>
            </Box>
          </Box>
          <Divider />
          <Box display="flex" mt={2}>
            <Box flex={1}>
              <Typography variant="body2">Duration</Typography>
            </Box>
            <Box flex={1}>
              <Typography variant="body2">{event.lessonLength} minutes</Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={4} display="flex" alignItems="center" gap={1.5}>
          <Avatar srcSet={event[oppositeRole].profileUrl} />
          <Typography variant="body2">{event[oppositeRole].credentials.fullName}</Typography>
          <Box>
            <Tooltip title="Send Message" placement="top">
              <IconButton onClick={navigateToMessages}>
                <MessageIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {user.role === 'student' && (
          <Box mt={2}>
            <Link onClick={navigateToListing} sx={{ cursor: "pointer" }}>
              <Typography variant="body2">View Listing</Typography>
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};

export default EventDetails;
