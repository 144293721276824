import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Grid, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { useFormik } from 'formik';
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from 'react';
import dayjs from '../../../utils/dayjsConfig';
import useAvailability from "../../../hooks/Tutor/useAvailability";
import useCreateUpdateAvailability from "../../../hooks/Tutor/useCreateUpdateAvailability";
import { initialSchema, validationSchema } from "./yup";
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const applyFormat = (date) => date.format("MMMM D, YYYY")

const Availability = () => {
    const handleSuccess = () => {
        enqueueSnackbar("Your changes were saved.", { variant: "success" })
    }

    const handleError = (data) => {
        enqueueSnackbar(data.message || "An error occurred.", { variant: "error" })
    }

    const { mutate: createUpdateAvailability, isLoading: isLoadingCreateUpdate } = useCreateUpdateAvailability(handleSuccess, handleError)

    const formik = useFormik({
        initialValues: initialSchema,
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (data) => {
            createUpdateAvailability({ availability: parseDayJS(data) });
        }
    });

    const { data: availability, isLoading: isLoadingAvailability, refetch: refetchAvailability, isRefetching } = useAvailability(formik.values.date.format('YYYY-MM-DD'))

    const parseDayJS = (data) => {
        return {
            date: dayjs(data.date).format('YYYY-MM-DD'),
            startTime: dayjs(data.startTime).format('HH:mm:ss'),
            endTime: dayjs(data.endTime).format('HH:mm:ss'),
            exceptions: data.exceptions.map(exception => ({
                startTime: dayjs(exception.startTime).format('HH:mm:ss'),
                endTime: dayjs(exception.endTime).format('HH:mm:ss'),
            }))
        }
    }

    const convertToDayJS = (data) => {
        return {
            date: dayjs(data.date, 'YYYY-MM-DD'),
            startTime: dayjs(data.startTime, 'HH:mm:ss'),
            endTime: dayjs(data.endTime, 'HH:mm:ss'),
            exceptions: data.exceptions.map(exception => ({
                startTime: dayjs(exception.startTime, 'HH:mm:ss'),
                endTime: dayjs(exception.endTime, 'HH:mm:ss'),
            }))
        }
    }

    useEffect(() => {
        if (availability) {
            formik.setValues(convertToDayJS(availability));
        } else {
            clearFormik(formik.values.date)
        }
    }, [availability]);

    const addException = () => {
        const exceptions = formik.values.exceptions.concat({ startTime: null, endTime: null });
        formik.setFieldValue('exceptions', exceptions);
    };

    const removeException = (index) => {
        const exceptions = formik.values.exceptions.filter((_, i) => i !== index);
        formik.setFieldValue('exceptions', exceptions);
    };

    const onDateCalendarChange = async (date) => {
        if (applyFormat(date) === applyFormat(formik.values.date)) return
        await clearFormik(date)
        refetchAvailability()
    }

    const clearFormik = async (date) => {
        await formik.setValues({ ...initialSchema, date })
        formik.setErrors({})
        formik.setTouched({})
    }

    const createLoadingSkeleton = () => {
        return (
            <Box width="100%">
                <Skeleton variant="text" sx={{ fontSize: '1rem', mb: 1 }} />
                {new Array(3).fill(0).map((_, idx) => {
                    return (
                        <Box key={idx} mb={2}>
                            <Box mt={2} display="flex" gap={3}>
                                <Box flex={1}>
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', mb: 1 }} />
                                    <Skeleton variant="rounded" height={40} />
                                </Box>
                                <Box flex={1}>
                                    <Skeleton variant="text" sx={{ fontSize: '0.8rem', mb: 1 }} />
                                    <Skeleton variant="rounded" height={40} />
                                </Box>
                            </Box>
                        </Box>
                    )
                })}
                <Box mt={2}>
                    <Skeleton variant="rounded" width={66} height={36} />
                </Box>
            </Box>
            // <Box textAlign="center" pt={5}>
            //     <CircularProgress size={24} color="inherit" />
            // </Box>
        )
    }

    const today = dayjs();

    return (
        <Box>
            <Typography variant="h5" fontWeight="bold" mb={2}>Set your availability</Typography>
            <Typography variant="body2" gutterBottom color="secondary">Here you can set your daily availability, or switch to weekly view and apply it directly for the full week.</Typography>

            <Grid container spacing={5} mt={0}>
                <Grid item xs={12} lg={4}>
                    <Box mb={2} border={1} borderRadius={4}>
                        <DateCalendar minDate={today} value={formik.values.date} onChange={onDateCalendarChange} />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={7} width="fit-content">
                    {isLoadingAvailability || isRefetching ? (
                        createLoadingSkeleton()
                    ) : (
                        <Box>
                            <Box mb={1}>
                                <Typography variant="body1" fontWeight="600">Your availability for {applyFormat(formik.values.date)}</Typography>
                            </Box>
                            <Box mt={2} display="flex" gap={3}>
                                <Box width="100%">
                                    <Typography variant="body2" fontWeight="400" mb={1}>Start Time</Typography>
                                    <TimePicker
                                        sx={{ width: "100%" }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        minutesStep={30}
                                        value={formik.values.startTime}
                                        onChange={(time) => formik.setFieldValue("startTime", time)}
                                        onBlur={() => formik.setFieldValue("startTime", true)}
                                    />
                                    {formik.touched.startTime && Boolean(formik.errors.startTime) && (
                                        <Typography variant="caption" mt={1} ml={1} display="block" color="error" component="span">{formik.errors.startTime}</Typography>
                                    )}
                                </Box>
                                <Box width="100%">
                                    <Typography variant="body2" fontWeight="400" mb={1}>End Time</Typography>
                                    <TimePicker
                                        sx={{ width: "100%" }}
                                        viewRenderers={{
                                            hours: renderTimeViewClock,
                                            minutes: renderTimeViewClock,
                                            seconds: renderTimeViewClock,
                                        }}
                                        minutesStep={30}
                                        value={formik.values.endTime}
                                        onChange={(time) => formik.setFieldValue("endTime", time)}
                                        onBlur={() => formik.setFieldValue("endTime", true)} />
                                    {formik.touched.endTime && Boolean(formik.errors.endTime) && (
                                        <Typography variant="caption" mt={1} ml={1} display="block" color="error" component="span">{formik.errors.endTime}</Typography>
                                    )}
                                </Box>
                            </Box>

                            <Accordion sx={{ mt: 2, borderRadius: "5px", '::before': { display: "none" } }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel" id="panel">
                                    <Typography variant="body1" fontWeight="600">Exceptions</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box>
                                        {formik.values.exceptions.map((exception, index) => {
                                            return (
                                                <Box display="flex" gap={3} mb={2} key={index}>
                                                    <Box width="100%">
                                                        <Typography variant="body2" fontWeight="400" mb={1}>Start Time</Typography>
                                                        <TimePicker
                                                            sx={{ width: "100%" }}
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            minutesStep={30}
                                                            value={formik.values.exceptions[index].startTime}
                                                            onChange={(time) => formik.setFieldValue(`exceptions[${index}].startTime`, time)}
                                                            onBlur={() => formik.setFieldTouched(`exceptions[${index}].startTime`, true)}
                                                        />
                                                        {formik.touched.exceptions && formik.errors.exceptions && formik.touched.exceptions[index]?.startTime && Boolean(formik.errors.exceptions[index]?.startTime) && (
                                                            <Typography variant="caption" mt={1} ml={1} display="block" color="error" component="span">{formik.errors.exceptions[index].startTime}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box width="100%">
                                                        <Typography variant="body2" fontWeight="400" mb={1}>End Time</Typography>
                                                        <TimePicker
                                                            sx={{ width: "100%" }}
                                                            viewRenderers={{
                                                                hours: renderTimeViewClock,
                                                                minutes: renderTimeViewClock,
                                                                seconds: renderTimeViewClock,
                                                            }}
                                                            minutesStep={30}
                                                            value={formik.values.exceptions[index].endTime}
                                                            onChange={(time) => formik.setFieldValue(`exceptions[${index}].endTime`, time)}
                                                            onBlur={() => formik.setFieldTouched(`exceptions[${index}].endTime`, true)}
                                                        />
                                                        {formik.touched.exceptions && formik.errors.exceptions && formik.touched.exceptions[index]?.endTime && Boolean(formik.errors.exceptions[index]?.endTime) && (
                                                            <Typography variant="caption" mt={1} ml={1} display="block" color="error" component="span">{formik.errors.exceptions[index].endTime}</Typography>
                                                        )}
                                                    </Box>
                                                    <Box component="span" pt={3.5}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => removeException(index)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                </Box>
                                            )
                                        })}
                                        <Box mt={1}>
                                            <Button variant="outlined" startIcon={<AddIcon />} onClick={addException}>
                                                Add Exception
                                            </Button>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>

                            <Box mt={2}>
                                <Button
                                    disabled={isLoadingCreateUpdate}
                                    variant="contained"
                                    onClick={() => formik.handleSubmit()}
                                >
                                    {isLoadingCreateUpdate &&
                                        <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
                                    }
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    )
                    }
                </Grid >
            </Grid >
        </Box >
    )
}

export default Availability