import React from 'react';
import { Skeleton, Box, Grid, Chip, Typography, styled } from '@mui/material';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    border: '1px solid #d6d6d6',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    transition: 'border-color 0.3s',
}));

const ListingSkeleton = () => {
    return (
        <StyledGridContainer container columnGap={2}>
            <Grid item xs="auto">
                <Skeleton variant="rectangular" width={160} height={160} />
            </Grid>
            <Grid item xs>
                <Typography variant="h6">
                    <Skeleton width="60%" />
                </Typography>
                <Box display="flex" alignItems="center" gap={1} mt={1}>
                    <Skeleton width="20%" height={30} variant="rounded" />
                    <Skeleton width="30%" height={30} variant="rounded" />
                </Box>
                <Typography variant="body2" mt={2}>
                    <Skeleton width="80%" />
                </Typography>
            </Grid>
            <Grid item container xs="auto" flexDirection="column" gap={"2rem"}>
                <Grid item xs>
                    <Box display="flex" gap={3}>
                        <Box>
                            <Box display="flex" alignItems="center" gap={0.5}>
                                <Skeleton variant="text" width={30} />
                                <Skeleton variant="circular" width={20} height={20} />
                            </Box>
                            <Skeleton width="60%" />
                        </Box>
                        <Box>
                            <Typography variant="h6" fontWeight="bold">
                                <Skeleton width={50} />
                            </Typography>
                            <Typography variant="h6" fontWeight="bold">
                                <Skeleton width={100} />
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Box display="flex" gap={2} flexDirection="column">
                        <Skeleton variant="rectangular" width={150} height={40} />
                        <Skeleton variant="rectangular" width={150} height={40} />
                    </Box>
                </Grid>
            </Grid>
        </StyledGridContainer>
    );
};

export default ListingSkeleton;
