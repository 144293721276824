import { Box } from '@mui/material';
import React from 'react';
import CreateYourFirstAd from './CreateYourFirstAd';
import FeaturedAds from './FeaturedAds';
import HeroSection from './HeroSection';
import PopularSubjects from './PopularSubjects';
import StartYourLearningJourney from './StartYourLearningJourney';

const Home = () => {
  return (
    <Box mb={{ xs: 4, md: 6 }}>
      <Box>
        <HeroSection />
      </Box>

      <Box mt={{ xs: 6, md: 10 }}>
        <PopularSubjects />
      </Box>

      <Box mt={{ xs: 6, md: 10 }}>
        <FeaturedAds />
      </Box>

      <Box mt={{ xs: 5, md: 8 }}>
        <StartYourLearningJourney />
      </Box>

      <Box mt={{ xs: 6, md: 10 }}>
        <CreateYourFirstAd />
      </Box>
    </Box>
  );
};

export default Home;
