import RequestEgine from "../core/RequestEngine"

const formDataRequestEngine = new RequestEgine("multipart/form-data")
const requestEngine = new RequestEgine()

export const fetchAds = async (data) => {
    const response = await requestEngine.get("/api/tutor/ads", data);
    return response.data.data;
}

export const fetchProfile = async () => {
    const response = await requestEngine.get("/api/tutor/me");
    return response.data.data;
}

export const updateProfile = async (data) => {
    const response = await formDataRequestEngine.post("/api/tutor/me", data);
    return response.data.data;
}

export const deleteFile = async (data) => {
    const response = await requestEngine.post("/api/tutor/delete/file", data);
    return response.data.data;
}