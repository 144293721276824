import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import tutorRoutes from './routes/tutor';
import publicRoutes from './routes/public';
import studentRoutes from './routes/student';
import LoadingScreen from '../components/LoadingScreen';

function Routes() {
    const { isAuthenticated, user, isLoading } = useUserContext();

    if (isLoading) return <LoadingScreen />

    const computeRoutes = () => {
        if (isAuthenticated) {
            return user.role === 'tutor' ? tutorRoutes : studentRoutes
        }
        return publicRoutes
    }

    return <RouterProvider router={createBrowserRouter(computeRoutes())} />;
}

export default Routes;
