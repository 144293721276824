import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TodayIcon from '@mui/icons-material/Today';
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

const Toolbar = ({ label, onView, onNavigate }) => {
  const theme = useTheme()
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      mb={3}
      display="flex"
      flexDirection={isMediumScreen ? 'column' : 'row'}
      alignItems="center"
      justifyContent="space-between"
      gap={isMediumScreen ? 2 : 0}
      p={2}
      boxShadow="0 3px 6px rgba(0,0,0,0.1)"
      sx={{ backgroundColor: "white", position: "sticky", top: 0, zIndex: 2 }}
    >
      <Box display="flex" gap={1}>
        <Button
          onClick={() => onNavigate('TODAY')}
          startIcon={<TodayIcon />}
          variant="contained"
          size="small"
          sx={{ borderRadius: '8px' }}
        >
          Today
        </Button>
        <Button
          onClick={() => onNavigate('PREV')}
          startIcon={<NavigateBeforeIcon />}
          variant="outlined"
          size="small"
          sx={{ borderRadius: '8px' }}
        >
          Back
        </Button>
        <Button
          onClick={() => onNavigate('NEXT')}
          endIcon={<NavigateNextIcon />}
          variant="outlined"
          size="small"
          sx={{ borderRadius: '8px' }}
        >
          Next
        </Button>
      </Box>
      <Typography variant="h6" fontWeight="bold">
        {label}
      </Typography>
      <Box display="flex" gap={1}>
        {['month', 'week', 'day'].map((view) => (
          <Button
            key={view}
            onClick={() => onView(view)}
            variant="contained"
            size="small"
            sx={{ borderRadius: '8px', textTransform: 'capitalize' }}
          >
            {view}
          </Button>
        ))}
      </Box>
    </Box>
  )
};

export default Toolbar