import { Box, Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, InputAdornment, LinearProgress, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useFormik, validateYupSchema } from "formik";
import { enqueueSnackbar } from "notistack";
import React, { useState } from 'react';
import { useNavigate } from "react-router";
import useCreateAd from "../../../../hooks/Tutor/useCreateAd";
import Subject from './Subject';
import { initialSchema, validationSchemas } from './yup';

const initialProgress = 25;
const MAX_STEP = 4

function CreateYourAd() {
    const [progress, setProgress] = useState(initialProgress);
    const theme = useTheme()
    const step = Math.ceil(progress / initialProgress);

    const navigate = useNavigate()

    const handleNext = async () => {
        try {
            await validateYupSchema(formik.values, validationSchema, true);
        } catch (validationErrors) {
            const errors = {};
            const touched = {};

            validationErrors.inner.forEach((error) => {
                errors[error.path] = error.message;
                touched[error.path] = true;
            });

            formik.setErrors(errors);
            formik.setTouched(touched);
            enqueueSnackbar('Please fix all errors before proceeding', { variant: 'error' });
            return
        }

        setProgress((prevProgress) => Math.min(prevProgress + initialProgress, 100));

        if (Math.ceil(progress / initialProgress) === MAX_STEP) {
            formik.handleSubmit();
        }
    };

    const handleBack = () => {
        setProgress((prevProgress) => Math.max(prevProgress - initialProgress, initialProgress));
    };

    const handleSuccess = () => {
        enqueueSnackbar("Your ad was created successfully.", { variant: "success" })

        navigate("/t/my-ads", { replace: true })
    }

    const handleError = (data) => {
        enqueueSnackbar(data.message || "An error occurred while creating your ad.", { variant: "error" })
    }

    const { mutate: createAd, isLoading } = useCreateAd(handleSuccess, handleError);

    const validationSchema = validationSchemas[step - 1]

    const formik = useFormik({
        initialValues: initialSchema,
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (data) => {
            const payload = {
                ...data,
                subjectId: data.subject.id
            }

            delete payload.subject

            createAd(payload)
        }
    });

    return (
        <Box>
            <Typography variant="h4" fontWeight="bold" mb={5}>Create your ad</Typography>
            <Typography variant="body1" mb={2}>Help students find you on the TutorMe platform. Set up your ad, and we'll show it to students who are looking for help in your subject.</Typography>
            <LinearProgress variant="determinate" value={progress} sx={{ borderRadius: "20px", height: "6px" }} />
            <Typography variant="body1" mt={2} color="#4A709C">Step {step} of {MAX_STEP}</Typography>
            <Stack mt={4} spacing={3} pb={10}>
                {step === 1 && (
                    <>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Select a subject</Typography>
                            <Subject formik={formik} />
                        </Box>
                    </>
                )}
                {step === 2 && (
                    <>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>About Me</Typography>
                            <TextField
                                fullWidth
                                placeholder="Write something about yourself..."
                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.aboutMe}
                                name="aboutMe"
                                error={formik.touched.aboutMe && Boolean(formik.errors.aboutMe)}
                                helperText={formik.touched.aboutMe && formik.errors.aboutMe}
                                onBlur={formik.handleBlur}
                                minRows={2}
                                multiline
                            />
                        </Box>
                        <Typography variant="body1" mb={2}>
                            About me should state clear and brief information about yourself and your experience.
                        </Typography>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Ad Title</Typography>
                            <TextField
                                fullWidth
                                placeholder="Enter the title of your ad"
                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.title}
                                name="title"
                                error={formik.touched.title && Boolean(formik.errors.title)}
                                helperText={formik.touched.title && formik.errors.title}
                                onBlur={formik.handleBlur}
                                minRows={2}
                                multiline
                            />
                        </Box>
                        <Typography variant="body1" mb={2}>
                            Your title should be clear and concise, and should explain
                            what you are offering. For example, 'Experienced math tutor for all levels' or 'Native Spanish speaker offering
                            conversational practice'.
                        </Typography>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Ad Description</Typography>
                            <TextField
                                placeholder="Enter the description of your ad"
                                fullWidth
                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.description}
                                name="description"
                                error={formik.touched.description && Boolean(formik.errors.description)}
                                helperText={formik.touched.description && formik.errors.description}
                                onBlur={formik.handleBlur}
                                minRows={4}
                                multiline
                            />
                        </Box>
                        <Typography variant="body1">
                            Your description should describe your tutoring style,
                            experience, or qualifications. For example, 'I have 5 years of experience teaching high
                            school math and am familiar with the Common Core curriculum.' or 'I am a native Spanish
                            speaker from Mexico and can help you improve your conversational skills.'
                        </Typography>
                    </>
                )}
                {step === 3 && (
                    <>
                        <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Price per hour</Typography>
                            <TextField
                                autoComplete="off"
                                fullWidth
                                placeholder="Enter your rate per hour"
                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.rate}
                                name="rate"
                                error={formik.touched.rate && Boolean(formik.errors.rate)}
                                helperText={formik.touched.rate && formik.errors.rate}
                                onBlur={formik.handleBlur}
                                type="number"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography variant="body2" fontWeight="bold">$/h</Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </>
                )}
                {step === 4 && (
                    <>
                        {/* <Box>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Profile Photo</Typography>
                            <Box display="flex" gap={3} mt={3} alignItems="center" sx={{ maxWidth: 450, width: "100%" }}>
                                <Box>
                                    <Avatar alt="Wael" src="./wael.png" sx={{ width: 56, height: 56 }} />
                                </Box>
                                <Box display="flex" justifyContent="space-between" flexGrow={1}>
                                    <Box>
                                        <Typography variant="body2" fontWeight="bold" mb={0.5}>Upload your profile photo</Typography>
                                        <Typography variant="body2" fontSize={13}>This will help students recognize you</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton component="label">
                                            <input
                                                type="file"
                                                hidden
                                            />
                                            <CameraIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box> */}
                        <Box mt={4}>
                            <Typography variant="body1" fontWeight="bold" mb={1}>Service Location</Typography>
                            <Box mt={2}>
                                <FormGroup>
                                    <Box border={"2px solid " + theme.palette.lightBlue.main} pl={3} pr={3} pt={1} pb={1} borderRadius={"12px"}>
                                        <FormControlLabel
                                            value="online"
                                            control={
                                                <Checkbox
                                                    size='small'
                                                    name="isLessonOnline"
                                                    onChange={(_, val) => formik.setFieldValue("isLessonOnline", val)}
                                                    onBlur={formik.handleBlur}
                                                    checked={formik.values.isLessonOnline}
                                                />
                                            }
                                            label={<Typography ml={1} fontWeight="500" variant="body2">Online</Typography>} />
                                    </Box>
                                    <Box border={"2px solid " + theme.palette.lightBlue.main} pl={3} pr={3} pt={1} pb={1} borderRadius={"12px"} mt={2}>
                                        <FormControlLabel
                                            value="in-person"
                                            control={
                                                <Checkbox
                                                    size='small'
                                                    name="isLessonInPerson"
                                                    onChange={(_, val) => formik.setFieldValue("isLessonInPerson", val)}
                                                    onBlur={formik.handleBlur}
                                                    checked={formik.values.isLessonInPerson}
                                                />
                                            }
                                            label={<Typography ml={1} fontWeight="500" variant="body2">In-Person</Typography>} />
                                    </Box>
                                    {formik.errors.isLessonOnlineOrInHouse && (
                                        <Typography mt={1} ml={1} variant="caption" color="error">
                                            {formik.errors.isLessonOnlineOrInHouse}
                                        </Typography>
                                    )}
                                </FormGroup>
                            </Box>
                        </Box>
                    </>
                )}
            </Stack>
            <Box
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                display="flex"
                justifyContent="center"
                gap={2}
                p={2}
                bgcolor="white"
            >
                <Box display="flex" gap={2} width="fit-content">
                    <Button disabled={step === 1 || isLoading} variant="contained" onClick={handleBack} sx={{ fontSize: "16px", pl: "2rem", pr: "2rem" }}>
                        Back
                    </Button>
                    <Button disabled={isLoading} variant="contained" onClick={handleNext} sx={{ fontSize: "16px", pl: "2rem", pr: "2rem" }}>
                        {isLoading &&
                            <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
                        }
                        {step === MAX_STEP ? "Create Ad" : "Next"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default CreateYourAd;
