import { useQuery } from 'react-query';
import { fetchFeaturedListings } from '../services/Listing';

const useFeaturedListings = (isAuthenticated, onSuccess, onError) => {
    return useQuery(['featuredListings'], () => fetchFeaturedListings(isAuthenticated), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
    });
};

export default useFeaturedListings;
