import { Box, Stack, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Listing from "../../../../components/Listing";
import ListingSkeleton from '../../../../components/ListingSkeleton';
import { useUserContext } from '../../../../context/UserContext';
import useFeaturedListings from '../../../../hooks/useFeaturedListings';

const Wrapper = forwardRef(({ children, inView }, ref) => {
    return (
        <Box>
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
                transition={{ duration: 0.75 }}
            >
                <Typography variant="h5" fontWeight="bold" mb={2}>
                    Featured Ads
                </Typography>
                <Stack gap={2} mb={2}>
                    {children}
                </Stack>
            </motion.div>
        </Box>
    );
});

function FeaturedAds() {
    const { isAuthenticated } = useUserContext();
    const { data: featuredListings, isLoading, isFetched } = useFeaturedListings(isAuthenticated);
    const { ref, inView } = useInView({ triggerOnce: true });

    if (isLoading) {
        return (
            <Wrapper ref={ref} inView={inView}>
                {new Array(4).fill(0).map((_, index) => (
                    <ListingSkeleton key={index} />
                ))}
            </Wrapper>
        );
    }

    if (!featuredListings.length && isFetched) {
        return null;
    }

    return (
        <Wrapper ref={ref} inView={inView}>
            {featuredListings.map(listing => (
                <Listing key={listing.id} listing={listing} />
            ))}
        </Wrapper>
    );
}

export default FeaturedAds;
