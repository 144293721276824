import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Upcoming from '../../shared/student_tutor/Lessons/Upcoming';
import Canceled from '../../shared/student_tutor/Lessons/Canceled';
import AwaitingConfirmation from '../../shared/student_tutor/Lessons/AwaitingConfirmation';
import Ongoing from '../../shared/student_tutor/Lessons/Ongoing';
import Completed from '../../shared/student_tutor/Lessons/Completed';
import Rejected from '../../shared/student_tutor/Lessons/Rejected';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{pt: 3}}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Lessons() {
  const navigate = useNavigate();
  const location = useLocation();

  const tabValues = ['pending', 'upcoming', 'ongoing', 'completed', 'canceled', 'rejected'];

  const handleNavigate = (_, newValue) => {
    navigate(`/t/lessons/${tabValues[newValue]}`);
  };

  const currentTab = location.pathname.split('/').pop();
  const tabIndex = tabValues.indexOf(currentTab);
  const value = tabIndex !== -1 ? tabIndex : 0

  return (
    <Box width="100%">
      <Typography variant="h4" fontWeight="bold" mb={4}>Manage Lessons</Typography>
      <Box>
        <Tabs value={value} onChange={handleNavigate} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
          <Tab disableRipple label={<Typography fontWeight="600" color="secondary" variant="body2" textTransform="capitalize">Awaiting Confirmation</Typography>} {...a11yProps(0)} />
          <Tab disableRipple label={<Typography fontWeight="600" color="secondary" variant="body2" textTransform="capitalize">Upcoming</Typography>} {...a11yProps(1)} />
          <Tab disableRipple label={<Typography fontWeight="600" color="secondary" variant="body2" textTransform="capitalize">Ongoing</Typography>} {...a11yProps(2)} />
          <Tab disableRipple label={<Typography fontWeight="600" color="secondary" variant="body2" textTransform="capitalize">Completed</Typography>} {...a11yProps(3)} />
          <Tab disableRipple label={<Typography fontWeight="600" color="secondary" variant="body2" textTransform="capitalize">Canceled</Typography>} {...a11yProps(4)} />
          <Tab disableRipple label={<Typography fontWeight="600" color="secondary" variant="body2" textTransform="capitalize">Rejected</Typography>} {...a11yProps(5)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AwaitingConfirmation />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Upcoming />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Ongoing />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Completed />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <Canceled />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <Rejected />
      </CustomTabPanel>
    </Box>
  );
}
