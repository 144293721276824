import RequestEgine from "../core/RequestEngine"

const formDataRequestEngine = new RequestEgine("multipart/form-data")
const requestEngine = new RequestEgine()

export const createTutor = async (data) => {
    const response = await formDataRequestEngine.post("/api/tutor", data);
    return response.data.data;
}

export const createStudent = async (data) => {
    const response = await requestEngine.post("/api/student", data);
    return response.data.data;
}

export const login = async (data) => {
    const response = await requestEngine.post("/api/auth/login", data);
    return response.data.data;
}

export const logout = async () => {
    const response = await requestEngine.post("/api/auth/logout");
    return response.data.data;
}

export const verifyUser = async (data) => {
    const response = await requestEngine.get("/api/auth/" + data.userId + "/verify/" + data.token);
    return response.data.data
}

export const requestResetPasswordLink = async (data) => {
    const response = await requestEngine.post("/api/auth/request/reset/password", data);
    return response.data.data;
}

export const resetPassword = async (data) => {
    const response = await requestEngine.post("/api/auth/" + data.userId + "/reset/password/" + data.token, { new_password: data.new_password });
    return response.data.data;
}