import { Box, Skeleton, Typography } from '@mui/material';
import React from 'react';

const LessonSkeleton = () => (
    <Box mb={1}>
        <Typography variant="body1">
            <Skeleton width={200} />
        </Typography>
        <Box mt={1} borderRadius={4} border={"1px solid #d6d6d6"} p="1rem">
            <Box display="flex" gap={3} alignItems="center" mt={1}>
                <Skeleton variant="circular" width={56} height={56} />
                <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
                    <Box>
                        <Typography variant="body2" fontSize={15} fontWeight="600" mb={0.5}>
                            <Skeleton width={100} />
                        </Typography>
                        <Typography variant="body2" fontSize={14} color="secondary">
                            <Skeleton width={150} />
                        </Typography>
                    </Box>
                    <Skeleton variant="rectangular" width={24} height={24} />
                </Box>
            </Box>
        </Box>
    </Box>
);

export default LessonSkeleton;
