import { useInfiniteQuery } from 'react-query';
import { fetchListings } from '../services/Listing';

const useListings = (isAuthenticated, params, onSuccess, onError) => {
    return useInfiniteQuery(
        ['listings', params],
        async ({ pageParam = 1 }) => {
            const data = await fetchListings(isAuthenticated, { ...params, page: pageParam });
            return data;
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.pagination.currentPage < lastPage.pagination.totalPages ? lastPage.pagination.currentPage + 1 : undefined;
            },
            onSuccess: (data) => {
                if (onSuccess) onSuccess(data);
            },
            onError: (error) => {
                if (onError) onError(error.response?.data || error.message);
            },
            keepPreviousData: true, 
            cacheTime: 0
        }
    );
};

export default useListings;
