import { Navigate } from 'react-router';
import Layout from '../../layouts';
import ListingDetails from '../../pages/shared/for_all/ListingDetails';
import MyCalendar from '../../pages/shared/student_tutor/Calendar';
import Messages from '../../pages/shared/student_tutor/Messages';
import MyAds from '../../pages/Tutor/Ads';
import CreateYourAd from '../../pages/Tutor/Ads/Create';
import Availability from '../../pages/Tutor/Availability';
import TutorLessons from '../../pages/Tutor/Lessons';
import Settings from "../../pages/Tutor/Settings";
import commonRoutes from './common';

/**
 * Tutor Routes Configuration
 *
 * This file contains the routing configuration for tutors pages in the application.
 * Each route defines the path, associated component, and layout to be used.
 */

const tutorRoutes = [
    {
        path: "/t",
        element: <Layout maxWidth="lg" />,
        children: [
            { path: "create-your-ad", element: <CreateYourAd /> },
            { path: "my-ads", element: <MyAds /> },
            { path: "lessons/upcoming", element: <TutorLessons /> },
            { path: "lessons/canceled", element: <TutorLessons /> },
            { path: "lessons/pending", element: <TutorLessons /> },
            { path: "lessons/ongoing", element: <TutorLessons /> },
            { path: "lessons/completed", element: <TutorLessons /> },
            { path: "lessons/rejected", element: <TutorLessons /> },
            { path: "my-hours", element: <Availability /> }
        ]
    },
    {
        path: "/t",
        element: <Layout maxWidth="xl" />,
        children: [
            { path: "settings", element: <Settings /> },
            { path: "settings/profile", element: <Settings /> },
            { path: "calendar", element: <MyCalendar /> },
        ]
    },
    {
        path: "/t",
        element: <Layout maxWidth="100%" isMessages />,
        children: [
            { path: "messages", element: <Messages /> }
        ]
    },
    {
        path: "/",
        element: <Navigate to="/t/my-ads" replace />
    },
    {
        path: "/",
        element: <Layout maxWidth="lg" />,
        children: [
            { path: "listings/:listingId", element: <ListingDetails /> }
        ]
    },
    ...commonRoutes,
    {
        path: "*",
        element: <Navigate to="/t/my-ads" replace />
    }
]

export default tutorRoutes