import * as yup from "yup";

const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}$/;

export const validationSchema = yup.object({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    aboutMe: yup.string().required('About Me is required'),
    rate: yup
        .number()
        .required('Rate is required')
        .min(0.01, 'The minimum rate allowed is 1$')
        .max(100, 'The maximum rate allowed is 100$'),
    isLessonOnline: yup.boolean(),
    isLessonInPerson: yup.boolean(),
    youtubeLink: yup.string().url("Please enter a valid URL").matches(youtubeRegex, 'Please enter a valid YouTube URL'),
}).test('at-least-one', 'Must specify at least one service location', function (values, context) {
    if (!values.isLessonOnline && !values.isLessonInPerson) {
        return context.createError({ path: 'isLessonOnlineOrInHouse' })
    }

    return true;
});

export const initialSchema = {
    id: null,
    title: "",
    description: "",
    rate: "",
    isLessonOnline: false,
    isLessonInPerson: false,
    aboutMe: "",
    youtubeLink: ""
};
