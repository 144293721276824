import { Avatar, Badge, useTheme } from '@mui/material'
import React from 'react'

const AvatarWithStatus = (props) => {
    const { online, ...other } = props

    const theme = useTheme()
    return (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            sx={{
                '& .MuiBadge-dot': {
                    backgroundColor: online ? theme.palette.success.main : theme.palette.error.main,
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    border: `2px solid ${theme.palette.background.paper}`,
                },
            }}
        >
            <Avatar {...other} sx={{ width: 45, height: 45 }} />
        </Badge>
    )
}

export default AvatarWithStatus