export const MuiPalette = {
    primary: {
        // main: "#0D7DF2",
        main: "rgb(51,98,204)"
    },
    secondary: {
        main: "#4A709C",
        dark: "#3B5A7D"
    },
    error: {
        main: "#d32f2f",
    },
    orange: {
        light: '#FFCC80',
        main: '#FF9800',
        dark: '#F57C00',
        contrastText: '#FFFFFF',
    },
    blue: {
        light: '#E8EDF5',
        main: '#E8EDF5',
        dark: '#A6B4D0',
        contrastText: '#000000',
    },
    lightBlue: {
        light: '#CFDBE8',
        main: '#CFDBE8',
        dark: '#CFDBE8',
        contrastText: '#000000',
    },
    important: {
        light: '#000000',
        main: '#000000',
        dark: '#000000',
        contrastText: '#FFFFFF',
    },
    // background: {
    //     default: '#FAFAFA'
    // }
}