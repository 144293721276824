import CircleIcon from '@mui/icons-material/Circle';
import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import dayjs from '../../../../../../utils/dayjsConfig';
import AvatarWithStatus from '../../AvatarWithStatus';

const Room = ({ room, onRoomSelect, user, active }) => {
    const navigate = useNavigate()
    const location = useLocation();
    const theme = useTheme()

    const formatLastMessageDate = (lastMessageDate) => {
        const now = dayjs();
        const messageDate = dayjs(lastMessageDate).local();

        if (now.isSame(messageDate, 'day')) {
            return messageDate.format('hh:mm A');
        }

        const diffInMinutes = now.diff(messageDate, 'minute');
        const diffInHours = now.diff(messageDate, 'hour');
        const diffInDays = now.diff(messageDate, 'day');
        const diffInMonths = now.diff(messageDate, 'month');
        const diffInYears = now.diff(messageDate, 'year');

        if (diffInMinutes < 60) return `${diffInMinutes}m`;
        if (diffInHours < 24) return `${diffInHours}h`;
        if (diffInDays < 2) return `Yesterday`;
        if (diffInDays < 30) return `${diffInDays}d`;
        if (diffInMonths < 12) return `${diffInMonths}mo`;
        return `${diffInYears}y`;
    }

    const handleRoomSelect = (room) => {
        navigate({
            pathname: location.pathname,
            search: `?r=${room.id}`
        }, { replace: true });
        onRoomSelect(room)
    };

    const oppositeRole = user.role === 'tutor' ? 'student' : 'tutor'
    
    return (
        <ButtonBase
            onClick={() => handleRoomSelect(room)}
            sx={{
                display: 'block',
                p: 1,
                pl: 2,
                pr: 2,
                textAlign: 'left',
                width: '100%',
                backgroundColor: active ? theme.palette.primary.main : 'transparent',
                color: active ? theme.palette.common.white : 'inherit',
                '&:hover': !active && {
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    transition: 'background-color 0.2s ease',
                },
            }}
            key={room.id}
        >
            <Box display="flex" gap={3} alignItems="center">
                <Box>
                    <AvatarWithStatus online={room[oppositeRole].online} src={room[oppositeRole].profileUrl}>
                        {room[oppositeRole].credentials.fullName[0]}
                    </AvatarWithStatus>
                </Box>
                <Box overflow="hidden" width="100%">
                    <Box display="flex" gap={1} justifyContent="space-between" alignItems="center" mb={0.5}>
                        <Typography variant="body1" fontWeight="bold" sx={{ color: active ? theme.palette.common.white : 'inherit' }}>
                            {room[oppositeRole].credentials.fullName}
                        </Typography>
                        <Typography variant="body2" fontSize={14} whiteSpace="nowrap" color={active ? theme.palette.common.white : 'secondary'}>
                            {formatLastMessageDate(room.lastMessage.createdAt)}
                        </Typography>
                    </Box>
                    <Box display="flex" gap={1} justifyContent="space-between" alignItems="center">
                        <Typography
                            variant="body2"
                            fontSize={15}
                            color={active ? theme.palette.common.white : 'secondary'}
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                width: '100%',
                                wordBreak: 'break-word',
                                overflow: 'hidden'
                            }}
                        >
                            {room.lastMessage.senderId === user.userId ? "You: " : ""} {room.lastMessage.text}
                        </Typography>
                        {room.existsUnreadMessages && !active && (
                            <CircleIcon sx={{ fontSize: 11 }} color="primary" />
                        )}
                    </Box>
                </Box>
            </Box>
        </ButtonBase>
    )
}

export default Room