import { useMutation, useQueryClient } from 'react-query';
import { updateAd } from '../../services/Ad';

const useUpdateAd = (onSuccess, onError) => {
    const queryClient = useQueryClient()

    return useMutation(updateAd, {
        onSuccess: (data) => {
            // queryClient.invalidateQueries('ads')
            if (onSuccess) onSuccess(data)
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useUpdateAd;
