import CloseIcon from '@mui/icons-material/Close';
import { IconButton, styled, useTheme } from '@mui/material';
import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from 'notistack';

const NotificationCenter = ({ children }) => {
    const theme = useTheme()

    const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
        // '&.notistack-MuiContent-success': {
        //     backgroundColor: theme.palette.primary.main,
        // },
        // '&.notistack-MuiContent-error': {
        //     backgroundColor: '#970C0C',
        // },
    }));
    return (
        <SnackbarProvider
            hideIconVariant
            action={(snackbarId) => (
                <IconButton
                    style={{ color: 'white' }}
                    onClick={() => closeSnackbar(snackbarId)}>
                    <CloseIcon />
                </IconButton>
            )}
            Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={5}
            autoHideDuration={3000}>
            {children}
        </SnackbarProvider >
    );
}

export default NotificationCenter;
