import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useUserContext } from "../../../../context/UserContext";

const AdsList = ({ ads, selectedAd, onAdClick }) => {
    const theme = useTheme();
    const { user } = useUserContext();

    return (
        <Box>
            <Typography variant="h6" fontWeight="bold" mt={4} mb={2}>Current Ads</Typography>
            <Stack mt={2} spacing={2}>
                {ads.map((ad) => {
                    const isSelected = selectedAd?.id === ad.id
                    return (
                        <Box
                            key={ad.id}
                            onClick={() => onAdClick(ad.id)}
                            display="flex"
                            gap={2}
                            alignItems="center"
                            sx={{
                                width: "100%",
                                border: isSelected ? "unset" : "2px solid " + theme.palette.lightBlue.main + " !important",
                                p: 2,
                                borderRadius: '15px',
                                cursor: "pointer",
                                backgroundColor: isSelected ? theme.palette.primary.main : 'transparent',
                                color: isSelected ? theme.palette.common.white : 'inherit',
                            }}
                        >
                            <Box
                                sx={{
                                    width: "56px",
                                    height: "56px",
                                    backgroundImage: `url(${user.profileUrl})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    borderRadius: "20px",
                                    position: "relative",
                                }}
                            />
                            <Box>
                                <Typography
                                    variant="body1"
                                    mb={0.5}
                                    fontWeight="600"
                                    sx={{ color: isSelected ? theme.palette.common.white : 'inherit' }}
                                >
                                    {ad.subject.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ color: isSelected ? theme.palette.common.white : 'inherit' }}
                                >
                                    {ad.online ? "Online" : "Offline"}
                                </Typography>
                            </Box>
                        </Box>
                    )
                })}
            </Stack>
        </Box >
    );
};

export default AdsList;
