import StarIcon from '@mui/icons-material/Star';
import { Avatar, Box, Button, Chip, Grid, Typography, styled } from '@mui/material';
import React from 'react';
import StudentAuth from "../../components/StudentAuth";
import { useUserContext } from "../../context/UserContext";
import useModal from "../../hooks/core/useModal";
import BookALesson from '../../pages/shared/for_all/ListingDetails/BookALesson';
import SendMessage from '../../pages/shared/for_all/ListingDetails/SendMessage';

const StyledGridContainer = styled(Grid)(({ theme }) => ({
    border: '1px solid #d6d6d6',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    transition: 'border-color 0.3s',
    '&:hover': {
        borderColor: 'black',
    },
}));

const StyledBox = styled(Box)(({ theme }) => ({
    width: 160,
    height: 160,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    cursor: 'pointer',
    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'transform 0.5s',
    },
    '&:hover img': {
        transform: 'scale(1.2)',
    },
}));

const BOOK_LESSON_ID = Math.floor(Date.now() * Math.random());
const SEND_MESSAGE_ID = Math.floor(Date.now() * Math.random());
const AUTH_ID = Math.floor(Date.now() * Math.random());

const Listing = ({ listing }) => {
    const { isAuthenticated } = useUserContext();
    const { openModal, closeModal, ModalComponent } = useModal();

    const navigateToListingDetails = () => {
        const setupTab = window.open("/listings/" + listing.id, "_blank");
        if (setupTab) setupTab.focus();
    };

    const handleBookingAction = () => {
        if (!isAuthenticated) {
            openModal(AUTH_ID);
            return;
        }
        openModal(BOOK_LESSON_ID);
    };

    const handleMessageAction = () => {
        if (!isAuthenticated) {
            openModal(AUTH_ID);
            return;
        }
        openModal(SEND_MESSAGE_ID);
    };

    const onLessonBooked = () => closeModal(BOOK_LESSON_ID);
    const onMessageSent = () => closeModal(SEND_MESSAGE_ID);

    const navigateToMessages = () => {
        const setupTab = window.open("/s/messages?r=" + listing.roomId, "_blank");
        if (setupTab) setupTab.focus();
    };

    const displayAverageRating = () =>
        listing.reviews.length
            ? (listing.reviews.reduce((sum, review) => sum + review.rating, 0) / listing.reviews.length).toFixed(1)
            : "N/A";

    return (
        <StyledGridContainer
            container
            columnGap={2}
            rowGap={{ xs: 2, md: 0 }}
            direction={{ xs: 'column', md: 'row' }}
        >
            <Grid item xs="auto" alignSelf={{ xs: 'center', md: 'start' }}>
                <StyledBox onClick={navigateToListingDetails}>
                    <Avatar
                        src={listing.tutor.profileUrl}
                        sx={{ width: "100%", height: "100%" }}
                        variant="square"
                    />
                </StyledBox>
            </Grid>

            <Grid item xs>
                <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    textAlign={{ xs: 'center', md: 'left' }}
                >
                    {listing.tutor.credentials.fullName}
                </Typography>
                <Box display="flex" flexWrap="wrap" gap={1} justifyContent={{ xs: 'center', md: 'flex-start' }}>
                    <Chip label={listing.subject.name} variant="filled" />
                    {listing.featured && <Chip label="Featured" variant="outlined" color="primary" />}
                    {listing.youtubeLink && <Chip label="Video Introduction" variant="outlined" color="error" />}
                </Box>
                <Typography variant="body2" fontWeight="600" mt={1} textAlign={{ xs: 'center', md: 'left' }}>
                    {listing.title}
                </Typography>
            </Grid>

            <Grid
                item
                container
                xs="auto"
                flexDirection="column"
                gap={2}
                alignItems={{ xs: 'center', md: 'flex-start' }}
            >
                <Box display="flex" gap={3} justifyContent={{ xs: 'center', md: 'flex-start' }} alignItems="center">
                    <Box textAlign="center">
                        <Box display="flex" gap={0.5} alignItems="center" justifyContent="center">
                            <Typography variant="h6" fontWeight="600">
                                {displayAverageRating()}
                            </Typography>
                            <StarIcon sx={{ color: "rgb(250, 175, 0)" }} />
                        </Box>
                        <Typography variant="body2" color="secondary">
                            {listing.reviews.length || "N/A"} review(s)
                        </Typography>
                    </Box>
                    <Box textAlign="center">
                        <Typography variant="h6" fontWeight="600">
                            ${listing.rate}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            per lesson
                        </Typography>
                    </Box>
                </Box>

                <Box display="flex" gap={2} flexDirection="column" width="100%" maxWidth={{ xs: '250px', md: 'none' }}>
                    <Button variant="contained" onClick={handleBookingAction}>
                        Book a lesson
                    </Button>
                    {listing.roomExists ? (
                        <Button variant="outlined" onClick={navigateToMessages}>
                            View Messages
                        </Button>
                    ) : (
                        <Button variant="outlined" onClick={handleMessageAction}>
                            Send a message
                        </Button>
                    )}
                    <ModalComponent id={BOOK_LESSON_ID} maxWidth={600}>
                        <BookALesson onLessonBooked={onLessonBooked} ad={listing} />
                    </ModalComponent>
                    <ModalComponent id={SEND_MESSAGE_ID} maxWidth={500}>
                        <SendMessage
                            onMessageSent={onMessageSent}
                            ad={listing}
                            tutorId={listing.tutor.id}
                            firstName={listing.tutor.credentials.firstName}
                        />
                    </ModalComponent>
                    <ModalComponent id={AUTH_ID} maxWidth={600} hideHeader>
                        <Box p={1}>
                            <StudentAuth redirectURL={"/listings"} />
                        </Box>
                    </ModalComponent>
                </Box>
            </Grid>

        </StyledGridContainer>
    );
};

export default Listing;
