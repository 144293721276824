import { useQuery } from 'react-query';
import { searchSubjects } from '../services/Subject';

const useSubjects = (query, onSuccess, onError) => {
    return useQuery(['subjects', query], () => searchSubjects(query), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
        enabled: !!query,
        keepPreviousData: true,
        staleTime: 5 * 60 * 1000,
        cacheTime: 30 * 60 * 1000,
    });
};

export default useSubjects;
