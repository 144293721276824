import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import dayjs from '../../../../../../utils/dayjsConfig';

const Message = ({ message, role }) => {
  const theme = useTheme();

  const getFormattedDate = () => {
    const messageDate = dayjs(message.createdAt).local();
    const now = dayjs();

    if (messageDate.isSame(now, 'day')) {
      return `Today at ${messageDate.format('h:mm A')}`;  // Today at 9:15 AM
    }

    if (messageDate.isSame(now.subtract(1, 'day'), 'day')) {
      return `Yesterday at ${messageDate.format('h:mm A')}`;  // Yesterday at 9:15 AM
    }

    if (messageDate.isAfter(now.startOf('week')) && messageDate.isBefore(now)) {
      return `${messageDate.format('dddd [at] h:mm A')}`;  // e.g., Wednesday at 9:15 AM
    }

    // If it's outside this week, show full date including the month
    return messageDate.format('dddd, MMMM D [at] h:mm A');  // e.g., Wednesday, August 2 at 9:15 AM
  };

  if (message.system) {
    return (
      <Box key={message.id} textAlign="center">
        <Typography variant="caption" color="secondary">{message.text}</Typography>
      </Box>
    )
  }

  return (
    <Box
      key={message.id}
      sx={{
        maxWidth: "60%",
        width: "fit-content",
        marginLeft: message.senderType === role ? "auto" : "unset",
      }}
    >
      <Box width="100%" textAlign="right">
        <Typography gutterBottom variant="caption" color="textSecondary" whiteSpace="nowrap">
          {getFormattedDate()}
        </Typography>
      </Box>
      <Box
        sx={{
          p: "8px 14px",
          borderRadius: "10px",
          backgroundColor: message.senderType === role ? theme.palette.lightBlue.main : theme.palette.blue.main,
          wordBreak: "break-word",
        }}>
        <Typography variant="body2" fontWeight="500">
          {message.text}
        </Typography>
      </Box>
    </Box>
  )
}

export default Message