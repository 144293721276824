import { Box, Button, CircularProgress, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import React from 'react'
import { MuiFormHelperText } from '../../../../../assets/styles/mui/theme/MuiFormHelperText'

const Address = ({ formik, loading }) => {
    return (
        <Box mt={4}>
            <Typography variant="h6" fontWeight="bold" color="secondary" mb={2}>Address Information</Typography>
            <Grid container spacing={{ xs: 3, md: 4 }}>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography variant="body1" fontWeight="bold" mb={1}>Country</Typography>
                        <FormControl fullWidth>
                            <Select
                                variant='outlined'
                                displayEmpty
                                fullWidth
                                onChange={formik.handleChange}
                                value={formik.values.country}
                                name="country"
                                error={formik.touched.country && Boolean(formik.errors.country)}
                                onBlur={formik.handleBlur}
                            >
                                <MenuItem value="" disabled>
                                    <Typography fontSize={15} fontWeight="400">Select your country</Typography>
                                </MenuItem>
                                <MenuItem value={"Lebanon"}>
                                    <Typography fontSize={15} variant="body2" fontWeight="400">Lebanon</Typography>
                                </MenuItem>
                            </Select>
                            {formik.touched.country && formik.errors.country && (
                                <Typography variant="caption" color="error" sx={{ ...MuiFormHelperText.styleOverrides.root }}>
                                    {formik.errors.country}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography variant="body1" fontWeight="bold" mb={1}>City</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter your city"
                            variant="outlined"
                            onChange={formik.handleChange}
                            value={formik.values.city}
                            name="city"
                            error={formik.touched.city && Boolean(formik.errors.city)}
                            helperText={formik.touched.city && formik.errors.city}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>
                        <Typography variant="body1" fontWeight="bold" mb={1}>Street Address</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter your street address"
                            variant="outlined"
                            onChange={formik.handleChange}
                            value={formik.values.streetAddress}
                            name="streetAddress"
                            error={formik.touched.streetAddress && Boolean(formik.errors.streetAddress)}
                            helperText={formik.touched.streetAddress && formik.errors.streetAddress}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box mt={3}>
                <Button disabled={loading} variant="contained" color="secondary" onClick={formik.handleSubmit}>
                    {loading && (
                        <CircularProgress size={24} color="inherit" className='absolute-circular-progress' />
                    )}
                    Apply
                </Button>
            </Box>
        </Box>
    )
}

export default Address