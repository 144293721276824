import * as yup from "yup";

export const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),

    password: yup
        .string()
        .min(8, 'Password must be at least 8 characters')
        .matches(/[A-Z]/, 'Must include an uppercase letter')
        .matches(/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/, 'Must include a special character')
        .required('Password is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
})


export const initialSchema = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: ""
}