import { MuiPalette } from "./MuiPalette";

export const MuiTextField = {
    styleOverrides: {
        root: {
            backgroundColor: MuiPalette.blue.main,
            borderRadius: 5,
            '& .MuiOutlinedInput-root': {
                'fieldset': {
                    borderColor: 'transparent',
                    // transition: 'border-color 0.2s ease-in-out',
                },
                '&:hover fieldset': {
                    // borderColor: 'transparent', // Remove border on hover
                },
                '&.Mui-focused fieldset': {
                    borderColor: MuiPalette.secondary.main,
                    // borderWidth: '2px',
                },
            },
            '& .MuiInputBase-input': {
                fontSize: '14px',
                height: "15px"
            },
            '& .MuiInputBase-input::placeholder': {
                fontSize: '15px',
                color: MuiPalette.secondary.dark,
            },
            '&:has(.MuiFormHelperText-root)': {
                '& .MuiOutlinedInput-root': {
                    borderRadius: 5,
                    'fieldset': {
                        borderColor: MuiPalette.error.main,
                    },
                    '&:hover fieldset': {
                        borderColor: MuiPalette.error.main,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: MuiPalette.error.main,
                    },
                },
                '& .MuiInputBase-input': {
                    borderRadius: 5,
                },
            },
        },
    },
};

