import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router';
    import SchoolIcon from '@mui/icons-material/School';

const Logo = () => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/');
    };

    return (
        <Box onClick={handleLogoClick} style={{ cursor: 'pointer', color: "black" }}>
            <Typography variant="h6" component="div" fontWeight="bolder" display="flex" gap={1} alignItems="center">
                <SchoolIcon /> Tutor Me
            </Typography>
        </Box>
    )
}

export default Logo