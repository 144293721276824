import * as yup from "yup";
import { matchIsValidTel } from "mui-tel-input";

export const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    phoneNumber: yup.string().required('Phone Number is required').test('is-valid-phone', 'Phone Number is invalid', (value) => {
        return matchIsValidTel(value);
    }),
    gender: yup.string().required('Gender is required'),
    dob: yup.string().required('Date of Birth is required'),
    country: yup.string().required('Country is required'),
    city: yup.string().required('City is required'),
    streetAddress: yup.string().required('Street Address is required'),
    // resume: yup.mixed().required('Resume is required').test("fileType", "Unsupported File Format", (value) => {
    //     return value && ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].includes(value.type);
    // }),
    // profile: yup.mixed().required('Profile is required').test("fileType", "Unsupported File Format", (value) => {
    //     return value && ["image/jpeg", "image/png", "image/gif"].includes(value.type);
    // }),
})


export const initialSchema = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    gender: "",
    dob: "",
    country: "",
    city: "",
    streetAddress: "",
    resume: "",
    profile: "",
    _resume: null,
    _profile: null
}