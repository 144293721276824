import { useInfiniteQuery } from 'react-query';
import { fetchRoomMessages } from '../../../services/Message';
import { useRef, useEffect } from 'react';

const useRoomMessages = (roomId, onSuccess, onError) => {
    const lastMessageTimeRef = useRef(null);

    useEffect(() => {
        lastMessageTimeRef.current = null;
    }, [roomId]);

    const query = useInfiniteQuery(
        ['roomMessages', roomId],
        async ({ pageParam = 1 }) => {
            const data = await fetchRoomMessages(roomId, lastMessageTimeRef.current, pageParam);
            if (data.messages.length > 0) {
                lastMessageTimeRef.current = data.pagination.lastFetchedTime;
            }
            return data;
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage && lastPage.pagination.currentPage < lastPage.pagination.totalPages
                    ? lastPage.pagination.currentPage + 1
                    : undefined;
            },
            onSuccess: (data) => {
                if (onSuccess) onSuccess(data);
            },
            onError: (error) => {
                if (onError) onError(error.response?.data || error.message);
            },
            keepPreviousData: true,
            enabled: Boolean(roomId),
            initialData: { pages: [], pageParams: [] },
            cacheTime: 0,
        }
    );

    return query;
};

export default useRoomMessages;
