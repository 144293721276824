export const MuiButton = {
    styleOverrides: {
        root: {
            borderRadius: '10px',
            // padding: '8px 16px',
            textTransform: 'none',
            fontWeight: '600',
        },
        contained: {
            color: "white",
        },
        outlinedPrimary: {
            color: "black",
            background: "#F0F2F5",
            border: "unset",
            '&:hover': {
                border: "unset",
                background: "#F0F2F5",
            }
        }
    },
}