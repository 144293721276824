import * as yup from "yup";
import dayjs from "../../../utils/dayjsConfig";

export const validationSchema = yup.object().shape({
    date: yup.date().required('Date is required'),
    startTime: yup.string().required('Start time is required').test(
        'valid-minute',
        'Start time must be at :00 or :30',
        function (value) {
            const minutes = dayjs(value).minute();
            return minutes === 0 || minutes === 30;
        }
    ),
    endTime: yup.string().required('End time is required').test(
        'is-greater',
        'End time must be later than start time',
        function (value) {
            const { startTime } = this.parent;
            return dayjs(value).isAfter(dayjs(startTime));
        }
    ).test(
        'valid-minute',
        'End time must be at :00 or :30',
        function (value) {
            const minutes = dayjs(value).minute();
            return minutes === 0 || minutes === 30;
        }
    ),
    exceptions: yup.array().of(
        yup.object().shape({
            startTime: yup.string().required('Exception start time is required').test(
                'valid-minute',
                'Exception start time must be at :00 or :30',
                function (value) {
                    const minutes = dayjs(value).minute();
                    return minutes === 0 || minutes === 30;
                }
            ),
            endTime: yup.string().required('Exception end time is required').test(
                'is-greater',
                'Exception end time must be later than exception start time',
                function (value) {
                    const { startTime } = this.parent;
                    return dayjs(value).isAfter(dayjs(startTime));
                }
            ).test(
                'valid-minute',
                'Exception end time must be at :00 or :30',
                function (value) {
                    const minutes = dayjs(value).minute();
                    return minutes === 0 || minutes === 30;
                }
            )
        })
    )
});


export const initialSchema = {
    date: dayjs(),
    startTime: null,
    endTime: null,
    exceptions: []
};
