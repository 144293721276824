import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const createBooking = async (data) => {
    const response = await requestEngine.post("/api/booking", data);
    return response.data.data;
}

export const cancelBooking = async (bookingId) => {
    const response = await requestEngine.post("/api/booking/" + bookingId + "/cancel");
    return response.data.data;
}

export const rejectBooking = async (bookingId) => {
    const response = await requestEngine.post("/api/booking/" + bookingId + "/reject");
    return response.data.data;
}

export const confirmBooking = async (bookingId) => {
    const response = await requestEngine.post("/api/booking/" + bookingId + "/confirm");
    return response.data.data;
}

export const deleteBooking = async (bookingId) => {
    const response = await requestEngine.post("/api/booking/" + bookingId + "/delete");
    return response.data.data;
}

export const fetchUpcomingBookings = async (page) => {
    const response = await requestEngine.get("/api/bookings/upcoming?page=" + page);
    return response.data.data;
}

export const fetchCanceledBookings = async (page) => {
    const response = await requestEngine.get("/api/bookings/canceled?page=" + page);
    return response.data.data;
}

export const fetchPendingBookings = async (page) => {
    const response = await requestEngine.get("/api/bookings/pending?page=" + page);
    return response.data.data;
}

export const fetchOngoingBookings = async (page) => {
    const response = await requestEngine.get("/api/bookings/ongoing?page=" + page);
    return response.data.data;
}

export const fetchCompletedBookings = async (page) => {
    const response = await requestEngine.get("/api/bookings/completed?page=" + page);
    return response.data.data;
}

export const fetchRejectedBookings = async (page) => {
    const response = await requestEngine.get("/api/bookings/rejected?page=" + page);
    return response.data.data;
}

export const updateMeetingLink = async (data) => {
    const response = await requestEngine.post("/api/booking/" + data.bookingId + "/meeting-link", data);
    return response.data.data;
}