import { Box, ListItemText, MenuItem, MenuList, Typography } from '@mui/material';
import React from 'react';
import FileCard from '../../../../../components/FileCard';
import useModal from '../../../../../hooks/core/useModal';
import usePopper from '../../../../../hooks/core/usePopper';
import UploadFile from '../UploadFile';
import useDeleteFile from '../../../../../hooks/Tutor/useDeleteFile';
import { enqueueSnackbar } from 'notistack';

const id = Math.floor(Date.now() * Math.random())

const Resume = ({ formik, loading, profile }) => {
    const { openModal, closeModal, ModalComponent } = useModal();
    const { openPopper, closePopper, PopperComponent } = usePopper();

    const handleSuccess = () => {
        enqueueSnackbar("Resume was deleted.")
    }

    const { mutate: deleteFile } = useDeleteFile(handleSuccess)

    const handleUpdateProfile = () => {
        openModal(id);
        closePopper(id);
    };

    const handleRemovePhoto = async () => {
        const confirmRemoval = window.confirm('Are you sure you want to delete your resume?');
        if (confirmRemoval) {
            closePopper(id);
            deleteFile({ type: "resume" })
        }
    };
    return (
        <Box mt={4}>
            <Typography variant="h6" fontWeight="bold" color="secondary" mb={2}>Resume</Typography>
            <FileCard loading={loading} fileUrl={profile.resumeUrl} fileName={profile.resume} showDownload showEdit showRemove={false} onEdit={(e) => openPopper(id, e.currentTarget)} />
            <PopperComponent id={id}>
                <Box mt={1}>
                    <MenuList>
                        <MenuItem>
                            <ListItemText onClick={handleUpdateProfile}>
                                <Typography variant='body2'>Upload a resume</Typography>
                            </ListItemText>
                        </MenuItem>
                        {formik.values.resume && (
                            <MenuItem>
                                <ListItemText onClick={handleRemovePhoto}>
                                    <Typography variant='body2'>Remove resume</Typography>
                                </ListItemText>
                            </MenuItem>
                        )}
                    </MenuList>
                </Box>
            </PopperComponent>
            <ModalComponent id={id} maxWidth={600}>
                <UploadFile
                    file={formik.values._resume}
                    onChange={(e) => formik.setFieldValue("_resume", e.target.files[0])}
                    onRemove={() => formik.setFieldValue("_resume", "")}
                    onUpload={() => { formik.handleSubmit(); closeModal(id) }}
                    type="file"
                    label={`${formik.values.resume ? 'Change' : 'Upload'} your resume`} />
            </ModalComponent>
        </Box>
    )
}

export default Resume