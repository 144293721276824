import { Autocomplete, MenuItem, TextField } from '@mui/material';
import React from 'react';
import useSubjectSearch from '../../../../../hooks/shared/for_all/useSubjectSearch';
import useAds from "../../../../../hooks/Tutor/useAds"

const Subject = ({ formik }) => {
    const {
        handleInputChange,
        getOptions,
    } = useSubjectSearch(formik.values?.subject?.name);

    const { data: ads, isLoading } = useAds()

    const existingSubjectIds = ads?.map(ad => ad.subjectId) || [];

    const filteredOptions = getOptions().map(option => ({
        ...option,
        disabled: existingSubjectIds.includes(option.id),
    }));

    return (
        <Autocomplete
            onInputChange={handleInputChange}
            options={filteredOptions}
            onBlur={formik.handleBlur}
            value={formik.values.subject}
            filterSelectedOptions
            includeInputInList
            filterOptions={(x) => x}
            isOptionEqualToValue={(option) => option.id === formik.values.subject.id}
            onChange={(_, v) => formik.setFieldValue("subject", v)}
            getOptionLabel={(option) => option.name}
            selectOnFocus={false}
            renderOption={(props, option) => (
                <MenuItem {...props} sx={{ opacity: option.disabled ? 0.5 : 1, fontSize: "14px" }} disabled={option.disabled}>
                    {option.name}
                </MenuItem>
            )}
            renderInput={(params) =>
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Search subjects"
                    name="subject"
                    error={formik.touched.subject && Boolean(formik.errors.subject)}
                    helperText={formik.touched.subject && formik.errors.subject}
                />
            }
        />
    )
}

export default Subject