import { useFormik } from 'formik';
import React from 'react';
import * as yup from "yup";
import useCreateUpdateReview from "../../../hooks/Student/useCreateUpdateReview";
import useHideNotification from "../../../hooks/shared/student_tutor/useHideNotification";
import { Box, Button, CircularProgress, TextField, Typography, Rating } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

const Review = ({ notification, type, onReviewSuccess }) => {

  const handleSuccess = () => {
    enqueueSnackbar("Review was sent.", { variant: "success" });
    hideNotification(notification.id)
  }

  const handleError = (data) => {
    enqueueSnackbar(data.message || "", { variant: "error" });
  }

  const { mutate: createUpdateReview, isLoading: isLoadingCUReview } = useCreateUpdateReview(handleSuccess, handleError);
  const { mutate: hideNotification, isLoading: isLoadingHideNoti } = useHideNotification(() => {
    onReviewSuccess(notification, type)
  });

  const formik = useFormik({
    initialValues: { content: notification.data.review?.content || "", rating: notification.data.review?.rating || 0 },
    validationSchema: yup.object({
      content: yup.string().required('Content is required'),
      rating: yup.number().min(1, 'Rating must be at least 1').max(5, 'Rating cannot be more than 5').required('Rating is required'),
    }),
    validateOnBlur: true,
    onSubmit: (data) => {
      createUpdateReview({ content: data.content, rating: data.rating, adId: notification.data.adId });
    }
  });

  return (
    <Box>
      <Box>
        <Typography variant="body1" fontWeight={"600"} mb={1}>
          How were your recent lessons with {notification.data.tutorFirstName}?
        </Typography>

        <TextField
          fullWidth
          autoComplete="off"
          placeholder="Write your review here..."
          name="content"
          value={formik.values.content}
          onChange={formik.handleChange}
          multiline
          rows={4}
          error={formik.touched.content && Boolean(formik.errors.content)}
          helperText={formik.touched.content && formik.errors.content}
          onBlur={formik.handleBlur}
        />

        <Box mt={3}>
          <Typography variant="body1" fontWeight={"600"} mb={.5}>
            {type === "create" ? "Add " : "Update "} Your Rating
          </Typography>
          <Rating
            name="rating"
            size='large'
            value={formik.values.rating}
            onChange={(_, newValue) => formik.setFieldValue("rating", newValue)}
            onBlur={formik.handleBlur}
          />
          {formik.touched.rating && formik.errors.rating && (
            <Box ml={1} mt={-1}>
              <Typography variant="caption" color="error">
                {formik.errors.rating}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      <Box mt={3} textAlign="right">
        <Button variant="contained" sx={{ maxWidth: 100, width: "100%" }} onClick={formik.handleSubmit} disabled={isLoadingCUReview || isLoadingHideNoti}>
          {(isLoadingCUReview || isLoadingHideNoti) && (
            <CircularProgress size={24} color="inherit" className='absolute-circular-progress' />
          )}
          {type === "create" ? "Send" : "Update"}
        </Button>
      </Box>
    </Box>
  );
}

export default Review;
