import * as yup from "yup";

export const validationSchema = yup.object({
    password: yup
        .string()
        .min(8, 'Password must be at least 8 characters')
        .matches(/[A-Z]/, 'Must include an uppercase letter')
        .matches(/[!@#$%^&*()_+{}[\]:;<>,.?~\\-]/, 'Must include a special character')
        .required('Password is required'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Confirm Password is required'),
})

export const initialSchema = {
    password: "",
    confirmPassword: "",
}