import * as yup from "yup";
import dayjs from "../../../../../utils/dayjsConfig"

export const validationSchema = yup.object().shape({
    // message: yup.string().required('Message is required'),
    date: yup.date().required('Date is required'),
    startTime: yup.string().required('Start time is required'),
    lessonLength: yup.number().required('Lesson Duration is required'),
});

export const initialSchema = {
    message: "",
    date: dayjs(),
    startTime: "",
    lessonLength: null
};
