import { Box, debounce, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { useUserContext } from "../../../../../context/UserContext";
import Constants from '../../../../../core/Constants';
import Room from './Room';

const Rooms = ({ selectedRoom, data: rooms, query, socket, onRoomSelect, onRoomStatusBroadcast }) => {
    const queryClient = useQueryClient()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const roomId = searchParams.get("r")

    const { user } = useUserContext();

    useEffect(() => {
        if (rooms && rooms.length) {
            const room = rooms.find(r => r.id === roomId)
            if (room && room.id !== selectedRoom?.id) {
                onRoomSelect(room);
            }
        }
    }, [rooms]);

    useEffect(() => {
        if (!socket) return;

        socket.on(Constants.EVENTS.MESSAGE_RECEIVED, onMessageReceived)
        socket.on(Constants.EVENTS.BROADCAST_ONLINE, onStatusBroadcast)
        socket.on(Constants.EVENTS.MESSAGES_MARKED_AS_READ, onMessagesMarkedAsRead)

        return () => {
            socket.off(Constants.EVENTS.MESSAGE_RECEIVED, onMessageReceived)
            socket.off(Constants.EVENTS.BROADCAST_ONLINE, onStatusBroadcast)
            socket.off(Constants.EVENTS.MESSAGES_MARKED_AS_READ, onMessagesMarkedAsRead)
        }
    }, [socket, selectedRoom?.id])

    const updateRoomData = debounce((rooms) => {
        queryClient.setQueryData(["rooms", query], () => rooms);
    }, 300);

    function onMessageReceived(message) {
        const roomIsActive = message.roomId === selectedRoom?.id;

        const updatedRooms = queryClient.getQueryData(["rooms", query])?.map((room) => {
            if (room.id === message.roomId) {
                return {
                    ...room,
                    lastMessage: message,
                    existsUnreadMessages: !roomIsActive,
                };
            }
            return room;
        }) || [];

        const sortedRooms = updatedRooms.sort((a, b) =>
            new Date(b.lastMessage?.createdAt) - new Date(a.lastMessage?.createdAt)
        );

        updateRoomData(sortedRooms);
    }

    function onMessagesMarkedAsRead({ roomId }) {
        queryClient.setQueryData(["rooms", query], (oldData) => {
            if (!oldData) return [];

            return oldData.map((room) => {
                if (room.id === roomId) {
                    return {
                        ...room,
                        existsUnreadMessages: false
                    };
                }
                return room;
            });
        });
    }

    const onStatusBroadcast = ({ model, modelName, roomId }) => {
        queryClient.setQueryData(["rooms", query], (oldData) => {
            if (!oldData) return []

            return oldData.map(room => {
                if (room.id === roomId) {
                    return { ...room, [modelName]: { ...room[modelName], online: true } }
                }

                return room
            })
        })

        if (selectedRoom?.id === roomId) {
            onRoomStatusBroadcast(model, modelName)
        }
    }

    // if (isLoading) {
    //     return (
    //         <Stack spacing={0.3}>
    //             {new Array(6).fill().map((_, index) => {
    //                 return <RoomSkeletonLoader key={index} />
    //             })}
    //         </Stack>
    //     )
    // }

    return (
        <>
            <Box mt={1} flex={1} overflow="auto">
                <Stack spacing={0.3}>
                    {rooms.map((room) => {
                        const activeRoom = selectedRoom?.id === room.id
                        return (
                            <Room key={room.id} room={room} onRoomSelect={onRoomSelect} user={user} active={activeRoom} />
                        )
                    })}
                </Stack>
            </Box>
        </>
    );
}

export default Rooms;
