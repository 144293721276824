import axios from "axios";
import Constants from "./Constants";

const instanceMap = new Map();

class RequestEngine {
    constructor(ContentType = "application/json") {
        if (instanceMap.has(ContentType)) {
            return instanceMap.get(ContentType);
        }

        this._apiEngine = axios.create({
            baseURL: Constants.serverlink,
            timeout: Constants.timeout,
            headers: {
                "Content-Type": ContentType,
            },
            withCredentials: true,
        });

        this.initializeResponseInterceptor();
        // this.initializeRequestInterceptor();

        instanceMap.set(ContentType, this);
    }

    initializeResponseInterceptor() {
        this._apiEngine.interceptors.response.use(
            response => {
                return response
            },
            async (error) => {
                const status = error.response?.status
                const errorName = error.response?.data.error

                if (status === 401) {
                    if (errorName === "TokenExpired") {
                        const newToken = error.response.headers['x-new-access-token'];

                        if (newToken) {
                            localStorage.setItem('token', newToken);
                            error.config.headers['Authorization'] = `Bearer ${newToken}`;
                            return this._apiEngine(error.config)
                        }
                    } else {
                        localStorage.removeItem('token')
                        window.location.href = "/login"
                        return
                    }
                }
                return Promise.reject(error);
            }
        );
    }

    initializeRequestInterceptor() {
        this._apiEngine.interceptors.request.use(
            (config) => {
                if (!navigator.onLine) {
                    window.location.href = "/offline";
                    return Promise.reject(new Error("Network error. Redirecting to offline page."));
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    setAuthHeaders() {
        const token = localStorage.getItem("token");
        if (token) {
            this._apiEngine.defaults.headers.Authorization = `Bearer ${token}`;
        }
    }

    async get(path) {
        this.setAuthHeaders();
        return await this._apiEngine.get(path);
    }

    async post(path, data = {}) {
        this.setAuthHeaders();
        return await this._apiEngine.post(path, data);
    }
}

export default RequestEngine;
