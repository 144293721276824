import { useQuery } from 'react-query';
import { fetchAvailableSlots } from '../services/TutorAvailablity';

const useAvailableSlots = ({ tutorId, date }, onSuccess, onError) => {
    return useQuery(['availableSlots'], () => fetchAvailableSlots(tutorId, date), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
    });
};

export default useAvailableSlots;
