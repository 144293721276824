import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, Box, CircularProgress, IconButton, ListItemText, MenuItem, Skeleton, Tooltip, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQueryClient } from 'react-query';
import { useSocket } from "../../../context/SocketContext";
import Constants from '../../../core/Constants';
import useMenu from '../../../hooks/core/useMenu';
import useModal from '../../../hooks/core/useModal';
import useMarkNotificationAsRead from '../../../hooks/shared/student_tutor/useMarkNotificationAsRead';
import useNotifications from "../../../hooks/shared/student_tutor/useNotifications";
import useNotificationsUnreadCount from "../../../hooks/shared/student_tutor/useNotificationsUnreadCount";
import dayjs from "../../../utils/dayjsConfig";
import Review from '../Review';

const NOTIFICATIONS_MENU = "notifications-menu";
const REVIEW_MODAL = "review"
const UPDATE_REVIEW_MODAL = "update_review"

const Notifications = () => {
  const socket = useSocket()
  const { openMenu, MenuComponent } = useMenu();
  const { openModal, ModalComponent, getModalData, closeModal } = useModal();
  const queryClient = useQueryClient()

  const { data: notifications, refetch, isLoading, fetchNextPage, hasNextPage } = useNotifications();
  const { data: unreadNotificationsCount } = useNotificationsUnreadCount();

  const handleSuccess = (notification) => {
    updateNotificationByFieldName(notification.id, "read", true)

    queryClient.setQueryData("unreadNotificationsCount", (oldC = 0) => oldC - 1)
  }

  const { mutate: markAsRead, isLoading: isLoadingMarkAsRead } = useMarkNotificationAsRead(handleSuccess);

  useEffect(() => {
    if (!socket) return

    socket.on(Constants.EVENTS.NOTIFICATIONS, refetchNotifications)

    return () => {
      socket.off(Constants.EVENTS.NOTIFICATIONS, refetchNotifications)
    }
  }, [socket])

  const refetchNotifications = () => {
    enqueueSnackbar("You have received a new notification")
    refetch()
  }

  const handleNotificationsClick = (event) => {
    openMenu(NOTIFICATIONS_MENU, event.currentTarget);
  };

  const getFormattedDate = (notification) => {
    const date = dayjs(notification.createdAt).local();
    const now = dayjs();

    if (date.isSame(now, 'day')) {
      return date.format('h:mm A');
    }

    if (date.isSame(now.subtract(1, 'day'), 'day')) {
      return `Yesterday ${date.format('h:mm A')}`;
    }

    return date.format('YYYY-MM-DD h:mm A');
  };

  const handleMenuItemClick = (notification) => {
    if (notification.lineThrough || isLoadingMarkAsRead) return

    if (!notification.read) markAsRead(notification.id)

    switch (notification.type) {
      case REVIEW_MODAL:
        openModal(REVIEW_MODAL, notification);
        break;
      case UPDATE_REVIEW_MODAL:
        openModal(UPDATE_REVIEW_MODAL, notification);
        break;
      default:
        break
    }
  }

  const onReviewSuccess = (notification, type) => {
    closeModal(type === "create" ? REVIEW_MODAL : UPDATE_REVIEW_MODAL);
    updateNotificationByFieldName(notification.id, "lineThrough", true)
  }

  const updateNotificationByFieldName = (notificationId, key, value) => {
    queryClient.setQueryData("notifications", (oldData) => {
      return {
        ...oldData,
        pages: oldData.pages.map(page => {
          return {
            ...page,
            notifications: page.notifications.map(n => {
              if (n.id === notificationId) {
                return { ...n, [key]: value }
              }
              return { ...n }
            })
          }
        })
      }
    })
  }

  if (isLoading) {
    return (
      <Skeleton variant="circular" width={40} height={40} />
    )
  }

  const data = notifications?.pages.flatMap(page => page.notifications) || []

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton onClick={handleNotificationsClick} sx={{ border: '1px solid', borderColor: 'divider', borderRadius: '50%' }}>
          <Badge badgeContent={unreadNotificationsCount} color="error">
            <NotificationsIcon sx={{ color: "black" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <MenuComponent
        id={NOTIFICATIONS_MENU}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              overflow: "hidden",
              mt: 1.5,
              width: 500,
            },
          }
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!isLoading && !data.length ? (
          <MenuItem disabled sx={{ justifyContent: "center" }}>
            <Typography variant="body2">No notifications found.</Typography>
          </MenuItem>
        ) : (
          <Box
            id="scrollable"
            sx={{ height: "100%", maxHeight: "600px", overflowY: 'auto' }}
          >
            <InfiniteScroll
              dataLength={data.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={<CircularProgress size={24} color="inherit" />}
              scrollableTarget="scrollable"
            >
              {data.map(notification => (
                <React.Fragment key={notification.id}>
                  <MenuItem sx={{ alignItems: 'flex-start', py: 2, position: 'relative' }} onClick={() => handleMenuItemClick(notification)}>
                    {!notification.read && (
                      <Box
                        sx={(theme) => ({
                          position: 'absolute',
                          right: "3%",
                          top: '50%',
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: theme.palette.primary.main,
                          transform: 'translateY(-50%)',
                        })}
                      />
                    )}
                    <ListItemText
                      primary={
                        <Typography fontWeight="bold" sx={{ textDecoration: notification.lineThrough ? "line-through" : "unset", whiteSpace: "pre-line" }} gutterBottom>
                          {notification.title}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography sx={{ textDecoration: notification.lineThrough ? "line-through" : "unset" }} variant="body2" component="span" width="95%" display="block" color="text.secondary" whiteSpace="pre-line">
                            {notification.content}
                          </Typography>
                          <Typography sx={{ textDecoration: notification.lineThrough ? "line-through" : "unset" }} variant="caption" component="span" color="secondary">
                            {getFormattedDate(notification)}
                          </Typography>
                        </>
                      }
                    />
                  </MenuItem>
                </React.Fragment>
              ))}
            </InfiniteScroll>
          </Box>
        )}
      </MenuComponent >
      <ModalComponent id={REVIEW_MODAL} maxWidth={600}>
        <Review type="create" notification={getModalData(REVIEW_MODAL)} onReviewSuccess={onReviewSuccess} />
      </ModalComponent>
      <ModalComponent id={UPDATE_REVIEW_MODAL} maxWidth={600}>
        <Review type="update" notification={getModalData(UPDATE_REVIEW_MODAL)} onReviewSuccess={onReviewSuccess} />
      </ModalComponent>
    </>
  );
};

export default Notifications;
