import { Paper, Popper } from '@mui/material';
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';

const usePopper = () => {
    const [poppers, setPoppers] = useState([]);
    const popperRefs = useRef({});

    const openPopper = (id, anchorEl) => {
        setPoppers([...poppers, { id, anchorEl }]);
    };

    const closePopper = (id) => {
        setPoppers(prevPoppers => prevPoppers.filter(popper => popper.id !== id));
    };

    const isPopperOpen = (id) => {
        return Boolean(poppers.find(popper => popper.id === id));
    };

    const findAnchorEl = (id) => {
        return poppers.find(popper => popper.id === id)?.anchorEl || null;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            poppers.forEach(popper => {
                const anchorEl = findAnchorEl(popper.id);
                const popperEl = popperRefs.current[popper.id];
                if (anchorEl && !anchorEl.contains(event.target) && popperEl && !popperEl.contains(event.target)) {
                    closePopper(popper.id);
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [poppers]);

    const PopperComponent = useMemo(() => {
        return React.memo(({ id, children }) => (
            <Popper
                id={id}
                open={isPopperOpen(id)}
                anchorEl={findAnchorEl(id)}
                placement='bottom-end'
                sx={{ zIndex: 9999 }}
                ref={(el) => {
                    if (el) {
                        popperRefs.current[id] = el;
                    }
                }}
            >
                <Paper sx={{ borderRadius: '10px' }}>
                    {children}
                </Paper>
            </Popper>
        ));
    }, [poppers]);

    return { openPopper, closePopper, PopperComponent };
};

export default usePopper;
