import EditIcon from '@mui/icons-material/Edit';
import { Avatar, Box, Button, ListItemText, MenuItem, MenuList, Skeleton, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import useDeleteFile from '../../../../../hooks/Tutor/useDeleteFile';
import useModal from '../../../../../hooks/core/useModal';
import usePopper from '../../../../../hooks/core/usePopper';
import UploadFile from '../UploadFile';

const id = Math.floor(Date.now() * Math.random())

const ProfilePicture = ({ formik, loading, profile }) => {
    const { openModal, closeModal, ModalComponent } = useModal();
    const { openPopper, closePopper, PopperComponent } = usePopper();

    const handleSuccess = () => {
        enqueueSnackbar("Profile was deleted.")
    }

    const { mutate: deleteFile } = useDeleteFile(handleSuccess)

    const handleUpdateProfile = () => {
        openModal(id);
        closePopper(id);
    };

    const handleRemovePhoto = async () => {
        const confirmRemoval = window.confirm('Are you sure you want to delete the photo?');
        if (confirmRemoval) {
            closePopper(id);
            deleteFile({ type: "profile" })
        }
    };

    return (
        <Box display="flex" justifyContent="center" >
            <Box
                width={160}
                height={160}
                position="relative"
            >
                {loading ? (
                    <Skeleton variant="circular" width={"100%"} height={"100%"} />
                ) : (
                    <>
                        <Avatar sx={{ width: "100%", height: "100%" }} src={profile.profileUrl} />
                        <Box sx={{ position: "absolute", bottom: "4px", left: "-15px", opacity: 1 }}>
                            <Button
                                variant='outlined'
                                startIcon={<EditIcon />}
                                onClick={(e) => openPopper(id, e.currentTarget)}
                            >
                                Edit
                            </Button>
                            <PopperComponent id={id}>
                                <Box mt={1}>
                                    <MenuList>
                                        <MenuItem>
                                            <ListItemText onClick={handleUpdateProfile}>
                                                <Typography variant='body2'>Upload a photo</Typography>
                                            </ListItemText>
                                        </MenuItem>
                                        {formik.values.profile && (
                                            <MenuItem>
                                                <ListItemText onClick={handleRemovePhoto}>
                                                    <Typography variant='body2'>Remove photo</Typography>
                                                </ListItemText>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </Box>
                            </PopperComponent>
                            <ModalComponent id={id} maxWidth={600}>
                                <UploadFile
                                    file={formik.values._profile}
                                    onChange={(e) => formik.setFieldValue("_profile", e.target.files[0])}
                                    onRemove={() => formik.setFieldValue("_profile", null)}
                                    onUpload={() => { formik.handleSubmit(); closeModal(id) }}
                                    type="image"
                                    label={`${formik.values.profile ? 'Change' : 'Upload'} your profile`} />
                            </ModalComponent>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default ProfilePicture;
