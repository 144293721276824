import { Box, Skeleton, styled, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import usePopularSubjects from '../../../../hooks/usePopularSubjects';

const StyledBox = styled(Box)(({ theme }) => ({
  border: '1px solid #d6d6d6',
  padding: theme.spacing(2),
  transition: 'border-color 0.3s',
  '&:hover': {
    borderColor: 'black',
  },
  width: 250,
  textAlign: 'center',
  borderRadius: '5px',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const SkeletonBox = styled(Skeleton)(({ theme }) => ({
  width: 250,
  height: 70,
  borderRadius: '5px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const Wrapper = forwardRef(({ children, inView }, ref) => (
  <Box ref={ref}>
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
      transition={{ duration: 0.75 }}
    >
      <Typography variant="h5" gutterBottom fontWeight="bold" mb={2}>
        Popular Subjects
      </Typography>
      <Box
        display="flex"
        gap={3}
        flexWrap="wrap"
        justifyContent={{ xs: 'center', md: 'flex-start' }}
      >
        {children}
      </Box>
    </motion.div>
  </Box>
));

const PopularSubjects = () => {
  const { data: popularSubjects, isLoading } = usePopularSubjects();
  const navigate = useNavigate();
  const { ref, inView } = useInView({ triggerOnce: true });

  const navigateToSubjectListing = (subject) => {
    navigate(`/listings?s=${subject.name},${subject.id}`);
  };

  if (isLoading) {
    return (
      <Wrapper ref={ref} inView={inView}>
        {Array(4)
          .fill()
          .map((_, index) => (
            <SkeletonBox variant="rectangular" key={index} />
          ))}
      </Wrapper>
    );
  }

  if (!popularSubjects.length) {
    return null;
  }

  return (
    <Wrapper ref={ref} inView={inView}>
      {popularSubjects.map((subject) => (
        <StyledBox
          onClick={() => navigateToSubjectListing(subject)}
          key={subject.id}
        >
          <Typography variant="body1" fontWeight="600">
            {subject.name}
          </Typography>
        </StyledBox>
      ))}
    </Wrapper>
  );
};

export default PopularSubjects;
