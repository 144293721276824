import { useInfiniteQuery } from 'react-query';
import { fetchNotifications } from '../../../services/Notification';

const useNotifications = (onSuccess, onError) => {
    return useInfiniteQuery(
        'notifications',
        async ({ pageParam = 1 }) => {
            const data = await fetchNotifications(pageParam);
            return data;
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.pagination.currentPage < lastPage.pagination.totalPages
                    ? lastPage.pagination.currentPage + 1
                    : undefined;
            },
            onSuccess: (data) => {
                if (onSuccess) onSuccess(data);
            },
            onError: (error) => {
                if (onError) onError(error.response?.data || error.message);
            },
            keepPreviousData: true,
        }
    );
};

export default useNotifications;
