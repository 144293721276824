import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import useResetPassword from '../../../hooks/Public/useResetPassword';
import { initialSchema, validationSchema } from './yup';
import { useLocation, useNavigate } from 'react-router';

function ResetPassword() {
    const navigate = useNavigate()
    const location = useLocation();

    const params = new URLSearchParams(location.search);
    const userId = params.get('ui');
    const token = params.get('vt'); // valid token 

    const handleSuccess = () => {
        enqueueSnackbar("Password was changed successfully.", { variant: "success" });
        navigate("/login")
    }

    const handleError = (data) => {
        enqueueSnackbar(data.message || "An error occurred.", { variant: "error" })
    }

    const { mutate: resetPassword, isLoading } = useResetPassword(handleSuccess, handleError)

    const formik = useFormik({
        initialValues: initialSchema,
        validationSchema: validationSchema,
        validateOnBlur: true,
        onSubmit: (data) => {
            resetPassword({ new_password: data.password, token, userId })
        }
    });

    return (
        <Box>
            <Typography variant="h4" fontWeight="bold" mb={5}>Reset your password!</Typography>
            <Stack mt={4} spacing={3}>
                <Box>
                    <Typography variant="body1" fontWeight="bold" mb={1}>Password</Typography>
                    <TextField
                        fullWidth
                        placeholder="Enter your password"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                        name="password"
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onBlur={formik.handleBlur}
                        type="password"
                    />
                </Box>
                <Box>
                    <Typography variant="body1" fontWeight="bold" mb={1}>Confirm Password</Typography>
                    <TextField
                        fullWidth
                        placeholder="Confirm your password"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.confirmPassword}
                        name="confirmPassword"
                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                        helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        onBlur={formik.handleBlur}
                        type="password"
                    />
                </Box>
            </Stack>
            <Box mt={4}>
                <Button onClick={formik.handleSubmit} fullWidth disabled={isLoading} variant="contained" sx={{ fontSize: "16px", pt: "0.6rem", pb: "0.6rem" }}>
                    {isLoading &&
                        <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
                    }
                    Change Password
                </Button>
            </Box>
        </Box>
    );
}

export default ResetPassword;
