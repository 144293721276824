import { Box, Button, Chip, Typography, useTheme } from '@mui/material';
import React from 'react';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { motion } from 'framer-motion';

const UpgradeToFeaturedBanner = ({ ad }) => {
    const theme = useTheme();

    return (
        <Box>
            {ad.featured ? (
                <Typography
                    variant="body2"
                    color="text.secondary"
                    fontWeight="500"
                >
                    This ad is Featured! 🌟 You will see the <Chip component="span" label="Featured" variant="outlined" color="primary"></Chip> chip next to your listing.
                </Typography>
            ) : (
                <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6, ease: 'easeOut' }}
                >
                    <Box
                        sx={{
                            backgroundColor: theme.palette.important.main,
                            color: 'white',
                            padding: '16px',
                            borderRadius: '12px',
                            textAlign: 'center',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '22px',
                                marginBottom: '8px',
                            }}
                        >
                            Sell your ad faster!
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                fontSize: '16px',
                                marginBottom: '16px',
                            }}
                        >
                            Upgrade to Featured and get more attention from students.
                        </Typography>
                        <Button
                            color="blue"
                            size="large"
                            variant="outlined"
                            endIcon={<AutoAwesomeIcon />}
                        >
                            Upgrade to Featured
                        </Button>
                    </Box>
                </motion.div>
            )}
        </Box>
    );
};

export default UpgradeToFeaturedBanner;
