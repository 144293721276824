import { useQuery } from 'react-query';
import { fetchListing } from '../services/Listing';

const useListing = (id, isAuthenticated, onSuccess, onError) => {
    return useQuery(['listing'], () => fetchListing(id, isAuthenticated), {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
    });
};

export default useListing;
