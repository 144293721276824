import { useMutation } from 'react-query';
import { createRoomAndSendMessage } from '../../../services/Room';

const useCreateRoomAndSendMessage = (onSuccess, onError) => {
    return useMutation(createRoomAndSendMessage, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data)
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useCreateRoomAndSendMessage;
