import { useQuery } from 'react-query';
import { fetchAds } from '../../services/Tutor';

const useAds = (onSuccess, onError) => {
    return useQuery(['ads'], fetchAds, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
    });
};

export default useAds;
