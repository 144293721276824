import { Box, CircularProgress, Divider, Grid, Skeleton, Stack } from '@mui/material';
import { useFormik } from "formik";
import { enqueueSnackbar } from "notistack";
import React, { useEffect } from 'react';
import useProfile from '../../../../hooks/Tutor/useProfile';
import useUpdateProfile from '../../../../hooks/Tutor/useUpdateProfile';
import Address from './Address';
import General from './General';
import ProfilePicture from './ProfilePicture';
import Resume from './Resume';
import { initialSchema, validationSchema } from "./yup";

const Profile = () => {
  const handleSuccess = (data) => {
    enqueueSnackbar("Profile updated.", { variant: "success" });
  };

  const handleError = (data) => {
    enqueueSnackbar(data.message, { variant: "error" });
  };

  const { mutateAsync: updateProfile, isLoading: isLoadingUpdate } = useUpdateProfile(handleSuccess, handleError);
  const { data: profile, isLoading: isLoadingGet, isRefetching } = useProfile();

  const formik = useFormik({
    initialValues: initialSchema,
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: async (data) => {
      const formData = new FormData();
      const payload = convertToPayload(data);

      Object.keys(payload).forEach(key => {
        if (payload[key] instanceof File) {
          formData.append(key, payload[key]);
        } else if (typeof payload[key] === 'object' && payload[key] !== null) {
          formData.append(key, JSON.stringify(payload[key]));
        } else {
          formData.append(key, payload[key]);
        }
      });

      updateProfile(formData)
    }
  });

  const convertToSchema = (data) => {
    return {
      firstName: data.credentials.firstName,
      lastName: data.credentials.lastName,
      email: data.credentials.email,
      phoneNumber: data.phoneNumber,
      gender: data.gender,
      dob: data.dob,
      country: data.credentials.address.country,
      city: data.credentials.address.city,
      streetAddress: data.credentials.address.streetAddress,
      resume: data.resume,
      profile: data.profile,
    }
  };

  const convertToPayload = (data) => {
    return {
      credentials: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        address: {
          country: data.country,
          city: data.city,
          streetAddress: data.streetAddress,
        },
      },
      phoneNumber: data.phoneNumber,
      gender: data.gender,
      dob: data.dob,
      _resume: data._resume,
      _profile: data._profile,
    }
  }

  useEffect(() => {
    if (profile) {
      formik.setValues(convertToSchema(profile));
    } else {
      formik.setValues({ ...initialSchema });
      formik.setErrors({});
      formik.setTouched({});
    }
  }, [profile]);

  if (isLoadingGet) {
    return (
      <Box>
        <Box display="flex" justifyContent="center">
          <Box width={160} height={160}>
            <Skeleton variant="circular" width={"100%"} height={"100%"} />
          </Box>
        </Box>
        <Stack spacing={4} mt={2}>
          {new Array(2).fill(0).map((_, index) => {
            return (
              <Box key={index}>
                <Skeleton variant="text" sx={{ fontSize: '2rem', mb: 1 }} />
                <Grid container spacing={4}>
                  {new Array(4).fill(0).map((_, i) => {
                    return (
                      <Grid xs={12} md={6} key={i} item>
                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        <Skeleton variant="rectangular" width="100%" height={54} sx={{ mt: 1 }} />
                      </Grid>
                    )
                  })}
                </Grid>
                <Skeleton variant="rectangular" width={120} height={40} sx={{ mt: 2 }} />
              </Box>
            )
          })}
        </Stack>
      </Box>
    );
  }

  return (
    <Box>
      <ProfilePicture formik={formik} loading={isLoadingUpdate || isRefetching} profile={profile} />
      <General formik={formik} loading={isLoadingUpdate || isRefetching} />
      <Divider sx={{ mt: 4 }} />
      <Address formik={formik} loading={isLoadingUpdate || isRefetching} />
      <Divider sx={{ mt: 4 }} />
      <Resume formik={formik} loading={isLoadingUpdate || isRefetching} profile={profile} />
    </Box>
  );
};

export default Profile;
