import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from "notistack";
import React from 'react';
import useCreateStudent from '../../../hooks/Public/useCreateStudent';
import { initialSchema, validationSchema } from './yup';
import { useNavigate } from 'react-router';

function SignUp({ smallTitle }) {
  const navigate = useNavigate()

  const handleSuccess = () => {
    enqueueSnackbar("Verification Email Sent.", { variant: "success" })
    navigate("/activate-account")
  }

  const handleError = (data) => {
    enqueueSnackbar(data.message || "An error occurred while creating your profile.", { variant: "error" })
  }

  const { mutate: createStudent, isLoading } = useCreateStudent(handleSuccess, handleError)

  const formik = useFormik({
    initialValues: initialSchema,
    validationSchema: validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (data) => {
      createStudent({ user: data })
    }
  });


  return (
    <Box>
      <Typography variant={smallTitle ? "h5" : "h4"} fontWeight="bold" mb={3}>Create a student account</Typography>
      <Stack mt={4} spacing={3}>
        <Box display={{ xs: "block", md: "flex" }} alignItems="center" gap={2}>
          <Box flexGrow={1}>
            <Typography variant="body1" fontWeight="bold" mb={1}>First Name</Typography>
            <TextField
              fullWidth
              placeholder="Enter your first name"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              name="firstName"
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              onBlur={formik.handleBlur}
            />
          </Box>
          <Box flexGrow={1} mt={{ xs: 3, md: 0 }}>
            <Typography variant="body1" fontWeight="bold" mb={1} >Last Name</Typography>
            <TextField
              fullWidth
              placeholder="Enter your last name"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              name="lastName"
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Box>
        <Box>
          <Typography variant="body1" fontWeight="bold" mb={1}>Email</Typography>
          <TextField
            fullWidth
            placeholder="Enter your email"
            variant="outlined"
            onChange={formik.handleChange}
            value={formik.values.email}
            name="email"
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
          />
        </Box>

        <Box display={{ xs: "block", md: "flex" }} alignItems="center" gap={2}>
          <Box flexGrow={1}>
            <Typography variant="body1" fontWeight="bold" mb={1}>Password</Typography>
            <TextField
              fullWidth
              placeholder="Enter your password"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.password}
              name="password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
              type="password"
            />
          </Box>
          <Box flexGrow={1} mt={{ xs: 3, md: 0 }}>
            <Typography variant="body1" fontWeight="bold" mb={1} >Confirm Password</Typography>
            <TextField
              fullWidth
              placeholder="Confirm your password"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              name="confirmPassword"
              error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              onBlur={formik.handleBlur}
              type="password"
            />
          </Box>
        </Box>

      </Stack>
      <Box mt={4}>
        <Button disabled={isLoading} onClick={() => formik.handleSubmit()} size="small" fullWidth variant="contained" sx={{ fontSize: "16px", pt: "0.6rem", pb: "0.6rem" }}>
          {isLoading &&
            <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
          }
          Sign Up
        </Button>
      </Box>
    </Box>
  );
}

export default SignUp;
