const Constants = {
  serverlink: process.env.REACT_APP_API_URL,
  timeout: 40000,
  EVENTS: {
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    ERROR: 'connect_error',
    MARK_MESSAGES_AS_READ: 'mark-messages-as-read',
    MESSAGES_MARKED_AS_READ: 'messages-marked-as-read',
    SEND_MESSAGE: 'send-message',
    NOTIFICATIONS: 'notifications',
    MESSAGE_RECEIVED: 'message-received',
    BROADCAST_ONLINE: 'broadcast-online',
  }
};

export default Constants;
