import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Button, CardMedia, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router';
import teacherStudentMeetingImage from '../../../../assets/images/teacher-student-meeting.png';

const CreateYourFirstAd = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { ref, inView } = useInView({ threshold: 0.5, triggerOnce: true });
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            style={{
                position: 'relative',
                width: '100%',
                overflow: 'hidden',
                height: '550px',
                border: "1px solid #ebe2e2",
                borderRadius: "20px"
            }}
        >
            <CardMedia
                component="img"
                image={teacherStudentMeetingImage}
                alt="Teacher in a lesson with a student."
                sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
            />
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.25, duration: 0.5, ease: 'easeInOut' }}
                style={{ position: 'absolute', bottom: "5%", right: "5%", width: "90%", maxWidth: "440px" }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                background: theme.palette.primary.main,
                                color: "white",
                                p: { xs: "15px", md: "25px" }, 
                                borderRadius: "20px",
                                cursor: "pointer",
                                textAlign: { xs: 'center', md: 'left' },
                            }}
                            onClick={() => navigate("/become-a-tutor")}
                        >
                            <Typography variant={isSmallScreen ? "h5" : "h4"} fontWeight="bold" gutterBottom>
                                You can become a great tutor too!
                            </Typography>
                            <Typography variant={isSmallScreen ? "body2" : "body1"} fontWeight="500" gutterBottom>
                                Empower others with your expertise, fuel your passion, and take control of your career.
                            </Typography>
                            <Button
                                fullWidth
                                sx={{ mt: 1 }}
                                size="large"
                                variant="outlined"
                                color="primary"
                                endIcon={<StarBorderIcon />}
                            >
                                Create my tutor ad
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </motion.div>
        </motion.div>
    );
};

export default CreateYourFirstAd;
