import { Box, Chip, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Calendar, dayjsLocalizer, Views } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { v4 as uuidv4 } from 'uuid';
import useModal from "../../../../hooks/core/useModal";
import useCalendarEvents from "../../../../hooks/shared/student_tutor/useCalendarEvents";
import dayjs from '../../../../utils/dayjsConfig';
import EventComponent from "./Event";
import EventDetails from "./EventDetails";
import Toolbar from './Toolbar';

const DnDCalendar = withDragAndDrop(Calendar);

const id = uuidv4();

const MyCalendar = () => {
    const theme = useTheme();
    const [dateRange, setDateRange] = useState({
        start: dayjs().startOf('week').format("YYYY-MM-DD"),
        end: dayjs().endOf('week').format("YYYY-MM-DD"),
    });

    const { data: events, refetch: refetchEvents } = useCalendarEvents(dateRange.start, dateRange.end);

    const { openModal, ModalComponent } = useModal();

    useEffect(() => {
        refetchEvents();
    }, [dateRange]);

    const handleRangeChange = (range) => {
        let start, end;
        if (Array.isArray(range)) {
            start = dayjs(range[0]).format("YYYY-MM-DD");
            end = dayjs(range[range.length - 1]).format("YYYY-MM-DD");
        } else {
            start = dayjs(range.start).format("YYYY-MM-DD");
            end = dayjs(range.end).format("YYYY-MM-DD");
        }
        setDateRange({ start, end });
    };

    const eventPropGetter = (e) => {
        const event = events.find(ev => e.id === ev.id);
        const backgroundColor = statusColors[event.status] || theme.palette.grey[400];
        return {
            style: {
                backgroundColor,
                color: 'white',
                border: '0px',
            },
        };
    };

    const onSelectEvent = (event) => {
        openModal(id, event);
    };

    const statusColors = {
        pending: theme.palette.warning.main,
        upcoming: theme.palette.secondary.light,
        ongoing: theme.palette.primary.main,
        completed: theme.palette.success.main,
    };

    return (
        <Box mb={2} pl={2} pr={2}>
            <Box mb={3} display="flex" flexWrap="wrap" justifyContent="center" gap={2}>
                {Object.keys(statusColors).map((status) => (
                    <Chip
                        key={status}
                        label={status.charAt(0).toUpperCase() + status.slice(1)}
                        sx={{
                            backgroundColor: statusColors[status],
                            color: 'white',
                            fontWeight: 'bold',
                            borderRadius: '8px',
                        }}
                    />
                ))}
            </Box>
            <ModalComponent id={id}>
                {(event) => <EventDetails event={event} />}
            </ModalComponent>
            <DnDCalendar
                localizer={dayjsLocalizer(dayjs)}
                events={events}
                defaultView={Views.WEEK}
                startAccessor="start"
                endAccessor="end"
                resizable={false}
                onSelectEvent={onSelectEvent}
                eventPropGetter={eventPropGetter}
                components={{ toolbar: Toolbar, event: EventComponent }}
                onRangeChange={handleRangeChange}
                style={{ whiteSpace: 'nowrap', display: 'block' }}
            />

        </Box>
    );
};

export default MyCalendar;
