import { Autocomplete, TextField, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import useSubjectSearch from '../../../../../hooks/shared/for_all/useSubjectSearch';

const Search = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const { handleInputChange, getOptions } = useSubjectSearch();

    const navigateToListing = (_, subject) => {
        if (subject) {
            navigate("/listings?s=" + subject.name + "," + subject.id);
        }
    };

    return (
        <Autocomplete
            onInputChange={handleInputChange}
            options={getOptions()}
            filterSelectedOptions
            includeInputInList
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.name}
            selectOnFocus={false}
            onChange={navigateToListing}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    placeholder="What do you want to learn?"
                    sx={{
                        backgroundColor: 'white',
                        width: isSmallScreen ? '100%' : '480px', // Full width on small screens
                        '& .MuiOutlinedInput-root': {
                            height: isSmallScreen ? '45px' : '60px', // Adjust height for smaller screens
                        },
                    }}
                />
            )}
        />
    );
};

export default Search;
