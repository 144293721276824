import { Box, Grid, Typography, useTheme, Link, Container, Stack, IconButton } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const theme = useTheme();
  return (
    <Box mt={3} sx={{ background: theme.palette.important.main }}>
      <Container maxWidth="md">
        <Grid container spacing={3} justifyContent="space-between" pt={7} pb={7}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" sx={{ color: "white" }} fontWeight="bold" mb={2}>About</Typography>
            <Stack>
              <Link variant="body2" underline="none" sx={{ color: "white" }} component={RouterLink} gutterBottom to="/about-us">Who are we?</Link>
              <Link variant="body2" underline="none" sx={{ color: "white" }} component={RouterLink} gutterBottom to="/">Terms and conditions</Link>
              <Link variant="body2" underline="none" sx={{ color: "white" }} component={RouterLink} gutterBottom to="/">Privacy Policy</Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" sx={{ color: "white" }} fontWeight="bold" mb={2}>Help</Typography>
            <Stack>
              <Link variant="body2" underline="none" sx={{ color: "white" }} component={RouterLink} gutterBottom to="/">Help Center?</Link>
              <Link variant="body2" underline="none" sx={{ color: "white" }} component={RouterLink} gutterBottom to="/">Contact</Link>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h5" sx={{ color: "white" }} fontWeight="bold" mb={2}>Follow us</Typography>
            <Box display="flex" justifyContent={{ xs: 'center', sm: 'flex-start' }}>
              <IconButton component={RouterLink} to="/" aria-label="Instagram">
                <InstagramIcon sx={{ color: "white" }} />
              </IconButton>
              <IconButton component={RouterLink} to="/" aria-label="LinkedIn">
                <LinkedInIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
