import { useQuery } from 'react-query';
import { getUser } from '../../../services/User';
import { jwtDecode } from 'jwt-decode';

const useUser = (onSuccess, onError) => {
    const token = localStorage.getItem("token");

    const isTokenValid = token ? (() => {
        try {
            const decoded = jwtDecode(token);
            return Boolean(decoded)
        } catch (error) {
            return false;
        }
    })() : false;

    return useQuery(['user'], getUser, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
        enabled: isTokenValid,
    });
};

export default useUser;
