import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // Disable automatic refetching on window focus
            retry: false, // Disable retrying on error
        },
    },
});

export default queryClient;
