import { useMutation, useQueryClient } from 'react-query';
import { deleteFile } from '../../services/Tutor';

const useDeleteFile = (onSuccess, onError) => {
    const queryClient = useQueryClient();

    return useMutation(deleteFile, {
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data)
            }
            queryClient.invalidateQueries('profile')
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useDeleteFile;
