import SendIcon from '@mui/icons-material/Send';
import WestIcon from '@mui/icons-material/West';
import { Box, CircularProgress, IconButton, InputAdornment, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useUserContext } from '../../../../../context/UserContext';
import useRoomMessages from '../../../../../hooks/shared/student_tutor/useRoomMessages';
import AvatarWithStatus from '../AvatarWithStatus';
import Message from "./Message";
import Constants from '../../../../../core/Constants';

const Wrapper = ({ children, isSmallScreen }) => {
    if (isSmallScreen) {
        return (
            <motion.div
                initial={{ x: '100%' }}
                animate={{ x: 0 }}
                exit={{ x: '100%' }}
                transition={{ type: 'spring', stiffness: 300, damping: 30 }}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    zIndex: 1300,
                    backgroundColor: 'white',
                }}
            >
                {children}
            </motion.div>
        )
    }

    return children
}

const boxStyles = (theme) => ({
    backgroundColor: "white",
    position: "sticky",
    top: 0,
    display: "flex",
    alignItems: "center",
    gap: 2,
    p: 1,
    pl: 2,
    borderBottom: "1px solid " + theme.palette.lightBlue.main,
    zIndex: 1,
})

const ChatBox = ({ room, socket, unselectRoom }) => {
    const theme = useTheme();
    const { user } = useUserContext();
    const queryClient = useQueryClient();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const { data: messages, isLoading, fetchNextPage, hasNextPage } = useRoomMessages(room.id);

    const [isFetchingOlder, setIsFetchingOlder] = useState(false)

    const messagesEndRef = useRef(null);
    const messageRef = useRef(null);
    const scrollableStackRef = useRef(null);
    const isFetchingRef = useRef(false);
    const firstMessageRef = useRef(null)

    useEffect(() => {
        if (!isFetchingOlder) {
            scrollToBottom();
        }
        setIsFetchingOlder(false);
    }, [messages]);

    useEffect(() => {
        if (!socket) return;

        socket.on(Constants.EVENTS.MESSAGE_RECEIVED, onMessageReceived);
        socket.on(Constants.EVENTS.MESSAGES_MARKED_AS_READ, onMessagesMarkedAsRead);

        return () => {
            socket.off(Constants.EVENTS.MESSAGE_RECEIVED, onMessageReceived);
            socket.off(Constants.EVENTS.MESSAGES_MARKED_AS_READ, onMessagesMarkedAsRead);
        };
    }, [socket, room]);

    useEffect(() => {
        const scrollable = scrollableStackRef.current;
        if (!scrollable) return;

        const observer = new MutationObserver(() => {
            markMessagesAsReadIfAtBottom();

            if (scrollable.scrollTop < 50) handleScroll({ target: scrollable });
        });

        observer.observe(scrollable, { childList: true, subtree: true });

        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const target = firstMessageRef.current;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && hasNextPage && !isFetchingRef.current) {
                    isFetchingRef.current = true;
                    setIsFetchingOlder(true);

                    fetchNextPage().finally(() => {
                        isFetchingRef.current = false;
                    });
                }
            },
            { threshold: 1.0 }
        );

        if (target) observer.observe(target);

        return () => {
            if (target) observer.unobserve(target);
        };
    }, [hasNextPage, isFetchingRef]);

    const handleScroll = (e) => {
        if (isFetchingRef.current || !hasNextPage || isLoading) return;

        if (e.target.scrollTop < 50) {
            isFetchingRef.current = true;
            setIsFetchingOlder(true)

            fetchNextPage().finally(() => {
                isFetchingRef.current = false;
            });
        }
    };

    const markMessagesAsReadIfAtBottom = () => {
        const scrollable = scrollableStackRef.current;
        if (scrollable) {
            // const isAtBottom = Math.abs(scrollable.scrollHeight - scrollable.scrollTop - scrollable.clientHeight) < 10;
            if (true) {
                socket.emit(Constants.EVENTS.MARK_MESSAGES_AS_READ, room.id);
            }
        }
    };

    const sendMessage = () => {
        const message = messageRef.current.firstChild.firstChild.value;
        if (!message) return;

        const messageTransfer = {
            roomId: room.id,
            text: message,
        };
        socket.emit(Constants.EVENTS.SEND_MESSAGE, messageTransfer);

        messageRef.current.firstChild.firstChild.value = "";
    };

    const onMessageReceived = async (newMessage) => {
        if (newMessage.roomId !== room.id) return;

        queryClient.setQueryData(['roomMessages', newMessage.roomId], (oldData) => {
            const newPages = [...oldData.pages];

            newPages[0] = {
                ...newPages[0],
                messages: [...newPages[0].messages, newMessage],
            };

            return {
                ...oldData,
                pages: newPages,
            };
        });
    };

    const onMessagesMarkedAsRead = ({ roomId }) => {
        if (roomId !== room.id) return;

        queryClient.setQueryData(['rooms'], (oldRooms) => {
            if (!oldRooms) return []
            return oldRooms.map((room) => {
                if (room.id === roomId) {
                    return {
                        ...room,
                        existsUnreadMessages: false,
                    };
                }
                return room;
            });
        });
    };

    const scrollToBottom = () => {
        if (!messagesEndRef.current) return;

        messagesEndRef.current.scrollIntoView();
    };

    if (!room || isLoading) return null;

    const oppositeRole = user.role === 'tutor' ? 'student' : 'tutor';
    const data = messages?.pages.slice().reverse().flatMap(page => page.messages) || [];

    return (
        <Wrapper isSmallScreen={isSmallScreen}>
            <Box sx={boxStyles(theme)}>
                {isSmallScreen && (
                    <IconButton onClick={unselectRoom}>
                        <WestIcon />
                    </IconButton>
                )}
                <AvatarWithStatus online={room[oppositeRole].online} src={room[oppositeRole].profileUrl}>
                    {room[oppositeRole].credentials.fullName[0]}
                </AvatarWithStatus>
                <Typography variant="body1" fontWeight="bold">
                    {room[oppositeRole].credentials.fullName}
                </Typography>
            </Box>
            <Stack
                sx={{
                    gap: 1, p: 2, pt: 1,
                    flexGrow: 1, height: "calc(100% - 135px)",
                    overflow: "auto"
                }}
                onScroll={handleScroll}
                ref={scrollableStackRef}
            >
                {isFetchingRef.current && (
                    <Box display="flex" justifyContent="center" mt={1} mb={1}>
                        <CircularProgress size={14} />
                    </Box>
                )}
                {data.map((message, index) => (
                    <Box mt={1} key={message.id} ref={index === 0 ? firstMessageRef : null} >
                        <Message message={message} role={user.role} />
                    </Box>
                ))}
                <Box ref={messagesEndRef} />
            </Stack>
            <Box sx={{ backgroundColor: "white", p: 2, position: "sticky", bottom: 0 }}>
                <Box display="flex" gap={2} alignItems="center">
                    <TextField
                        placeholder="Type a message"
                        variant="outlined"
                        fullWidth
                        autoComplete="off"
                        size="large"
                        ref={messageRef}
                        multiline
                        maxRows={4}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                sendMessage();
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={sendMessage}>
                                        <SendIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
        </Wrapper>
    );
};

export default ChatBox;
