import { useMutation, useQueryClient } from 'react-query';
import { updateProfile } from '../../services/Tutor';

const useUpdateProfile = (onSuccess, onError) => {
    const queryClient = useQueryClient();

    return useMutation(updateProfile, {
        onSuccess: (data) => {
            if (onSuccess) {
                onSuccess(data)
            }
            queryClient.invalidateQueries('profile')
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useUpdateProfile;
