import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const YoutubeVideo = ({ videoUrl, maxWidth = 400 }) => {
    const [loading, setLoading] = useState(true);
    const [iframeHeight, setIframeHeight] = useState(0);

    // Function to extract the YouTube video ID from the URL
    const extractVideoId = (url) => {
        const regExp = /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|)|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(regExp);
        return match ? match[1] : null;
    };

    // Dynamically set the iframe height for a 16:9 aspect ratio
    const updateIframeHeight = () => {
        const width = Math.min(document.body.offsetWidth, 400); // limit width to 400px
        setIframeHeight(width * 0.5625); // 16:9 aspect ratio
    };

    useEffect(() => {
        updateIframeHeight();
        window.addEventListener('resize', updateIframeHeight);

        return () => {
            window.removeEventListener('resize', updateIframeHeight);
        };
    }, []);

    // Extract video ID and check if it's valid
    const videoId = extractVideoId(videoUrl);
    if (!videoId) {
        return (
            <Box textAlign="center">
                <Typography variant="body2">Invalid YouTube link.</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ position: 'relative', width: '100%', maxWidth, height: 'auto' }}>
            {loading && (
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'rgba(255, 255, 255, 0.6)',
                        borderRadius: '10px',
                        zIndex: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <iframe
                width="100%"
                height={iframeHeight}
                src={`https://www.youtube.com/embed/${videoId}`}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ borderRadius: '10px', zIndex: 0 }}
                onLoad={() => setLoading(false)}
            />
        </Box>
    );
};

export default YoutubeVideo;
