import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { useUserContext } from '../../../../../context/UserContext';
import useOngoingBookings from "../../../../../hooks/shared/student_tutor/useOngoingBookings";
import Lesson from '../Lesson';
import groupBookingsByDate from '../utils/groupBookingsByDate';
import InfiniteScroll from 'react-infinite-scroll-component';
import LessonSkeleton from '../Lesson/Skeleton';

const Ongoing = () => {
    const { data: ongoingBookings, isLoading, fetchNextPage, hasNextPage } = useOngoingBookings();
    const { user } = useUserContext()

    if (isLoading) {
        return (
            <Box>
                {new Array(3).fill().map((_, index) => {
                    return (
                        <LessonSkeleton key={index} />
                    )
                })}
            </Box>
        )
    }

    const data = ongoingBookings?.pages.flatMap(page => page.bookings) || []

    if (!data.length) {
        return (
            <Typography variant="body2">No ongoing lessons were found.</Typography>
        )
    }

    const groupedBookings = groupBookingsByDate(data);

    return (
        <Box>
            <InfiniteScroll
                dataLength={data.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<Box textAlign="center"><CircularProgress size={24} color="inherit" /></Box>}
            >
                {Object.keys(groupedBookings).map(date => (
                    <Box key={date} mb={3}>
                        <Typography fontSize="1.1rem" fontWeight="bold" mb={2}>{date}</Typography>
                        <Stack spacing={2.5}>
                            {groupedBookings[date].map(booking => (
                                <Lesson key={booking.id} user={user} booking={booking} />
                            ))}
                        </Stack>
                    </Box>
                ))}
            </InfiniteScroll>
        </Box>
    )
}

export default Ongoing
