import RequestEgine from "../core/RequestEngine"

const requestEngine = new RequestEgine()

export const searchSubjects = async (query) => {
    const response = await requestEngine.get("/api/subjects/search?q=" + query);
    return response.data.data;
}

export const fetchPopularSubjects = async () => {
    const response = await requestEngine.get("/api/subjects/popular");
    return response.data.data;
}