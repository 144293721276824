import React, { createContext, useContext } from "react";
import useUser from "../hooks/shared/student_tutor/useUser";

const UserContext = createContext();

const UserProvider = ({ children }) => {
    const { data: user, isLoading, isError } = useUser()

    const isAuthenticated = !!user && !isError;

    return (
        <UserContext.Provider
            value={{
                isAuthenticated,
                user,
                isLoading,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

const useUserContext = () => {
    return useContext(UserContext);
};

export { UserProvider, useUserContext };

