import { useQuery } from 'react-query';
import { fetchPopularSubjects } from '../services/Subject';

const usePopularSubjects = (onSuccess, onError) => {
    return useQuery(['popularSubjects'], fetchPopularSubjects, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data);
        },
        onError: (error) => {
            if (onError) onError(error.response.data);
        },
    });
};

export default usePopularSubjects;
