
export const MuiAutocomplete = {
    styleOverrides: {
        paper: {
            fontSize: '14px',
        },
        root: {
            borderRadius: 5,
            '& .MuiOutlinedInput-root': {
                'fieldset': {
                    borderColor: 'transparent',
                },
                '&:hover fieldset': {
                    borderColor: 'transparent',
                },
                '&.Mui-focused fieldset': {
                    borderColor: 'transparent',
                },
            },
        },
    },
}