import { useMutation } from 'react-query';
import { updateAdVisibility } from '../../services/Ad';

const useUpdateAdVisibility = (onSuccess, onError) => {
    return useMutation(updateAdVisibility, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data)
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useUpdateAdVisibility;
