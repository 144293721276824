import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';

const Search = ({ query, onQueryChange }) => {
  return (
    <TextField
      placeholder="Search by name..."
      variant="outlined"
      fullWidth
      sx={{
        "& input": { height: "6px !important" },
        "& input::placeholder": {fontSize: "0.93rem !important", },
        borderRadius: "10px",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "inherit",
          borderRadius: "10px",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "transparent",
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon sx={{ fontSize: "19px" }} color='secondary' />
          </InputAdornment>
        ),
      }}
      onChange={onQueryChange}
      value={query}
    />
  );
};

export default Search;
