import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Chip, CircularProgress, Typography } from '@mui/material';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from "../../../../../utils/dayjsConfig"
import { useFormik } from 'formik';
import { enqueueSnackbar } from "notistack";
import React from 'react';
import { useQueryClient } from 'react-query';
import useAvailableSlots from '../../../../../hooks/useAvailableSlots';
import useCreateBooking from '../../../../../hooks/Student/useCreateBooking';
import useLessonLengths from '../../../../../hooks/useLessonLengths';
import { initialSchema, validationSchema } from './yup';

const BookALesson = ({ ad, onLessonBooked }) => {
  const queryClient = useQueryClient();

  const handleSuccess = () => {
    enqueueSnackbar("Lesson request was sent.", { variant: "success" });
    onLessonBooked()
  }

  const handleError = (data) => {
    enqueueSnackbar(data.message, { variant: "error" })
  }

  const { mutate: createBooking, isLoading: isLoadingCreate } = useCreateBooking(handleSuccess, handleError)

  const formik = useFormik({
    initialValues: initialSchema,
    validationSchema: validationSchema,
    validateOnBlur: true,
    onSubmit: (data) => {
      const payload = { ...data, adId: ad.id, tutorId: ad.tutor.id, date: data.date.format("YYYY-MM-DD") }
      createBooking(payload)
    }
  });

  const formattedDate = formik.values.date.format("YYYY-MM-DD")

  const { data: availableSlots, refetch: refetchAvailableSlots } = useAvailableSlots({
    tutorId: ad.tutor.id,
    date: formattedDate
  });

  const { data: lessonLengths, refetch: refetchLessonLengths } = useLessonLengths({
    tutorId: ad.tutor.id,
    date: formattedDate,
    startTime: formik.values.startTime
  });

  const onDateCalendarChange = async (date) => {
    if (date === formik.values.date) return
    await formik.setValues({ ...initialSchema, date });
    queryClient.setQueryData("lessonLengths", null)
    refetchAvailableSlots();
  }

  const handleStartTimeChange = async (time) => {
    if (time === formik.values.startTime) return
    await formik.setFieldValue("startTime", time);
    await formik.setFieldValue("lessonLength", null)
    refetchLessonLengths();
  }

  const getAvailableSlots = () => {
    if (!availableSlots) return <></>

    if (availableSlots?.length === 0) {
      return (
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" color="textSecondary">
            No available slots.
          </Typography>
        </Box>
      )
    }

    return (
      <Box mt={1}>
        <Typography variant="body1" fontWeight="600" mb={1.5}>Select a start time</Typography>
        <Box display="flex" gap={2} flexWrap="wrap">
          {availableSlots.map((time) => {
            const checked = formik.values.startTime === time;
            const formattedTime = dayjs(`${formattedDate} ${time}`).format("h:mm a")
            return (
              <Chip
                key={time}
                variant={"filled"}
                color={checked ? "primary" : "blue"}
                icon={checked ? <CheckIcon sx={{ zIndex: 1, pointerEvents: 'none', fontSize: "1rem" }} /> : null}
                label={formattedTime}
                onClick={() => handleStartTimeChange(time)}
              />
            );
          })}
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <Box>
        <Typography variant="body1" fontWeight="600" mb={1.5}>Choose your day</Typography>
        <DateCalendar minDate={dayjs()} value={formik.values.date} onChange={onDateCalendarChange} />
      </Box>
      {getAvailableSlots()}
      {lessonLengths && (
        <Box mt={3}>
          <Typography variant="body1" fontWeight="600" mb={1.5}>Lesson length</Typography>
          <Box display="flex" gap={2} flexWrap="wrap">
            {lessonLengths.map((lessonLength) => {
              const checked = formik.values.lessonLength === lessonLength;
              return (
                <Chip
                  key={lessonLength}
                  variant={"filled"}
                  color={checked ? "primary" : "blue"}
                  icon={checked ? <CheckIcon sx={{ zIndex: 1, pointerEvents: 'none', fontSize: "1rem" }} /> : null}
                  label={lessonLength + " minutes"}
                  onClick={() => formik.setFieldValue("lessonLength", lessonLength)}
                />
              );
            })}
          </Box>
        </Box>
      )}

      <Box mt={3} textAlign="right">
        <Button onClick={formik.handleSubmit} variant="contained" fullWidth disabled={!formik.values.startTime || !formik.values.date || !formik.values.lessonLength || isLoadingCreate} sx={{ maxWidth: 150 }}>
          {isLoadingCreate && (
            <CircularProgress className='absolute-circular-progress' size={24} color='inherit' />
          )}
          Confirm
        </Button>
      </Box>
    </Box>
  )
}

export default BookALesson