import { Box, CardMedia, Typography, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import nerdStudentImage from './../../../../assets/images/nerd-student.png';
import Search from './Search';

const HeroSection = () => {
  const theme = useTheme();
  const { ref, inView } = useInView({ triggerOnce: true });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
      transition={{ duration: 0.25, ease: 'easeInOut' }}
      style={{
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        height: isSmallScreen ? '450px' : isMediumScreen ? '550px' : '635px',
        border: '1px solid #ebe2e2',
        borderRadius: '20px',
      }}
    >
      <CardMedia
        component="img"
        image={nerdStudentImage}
        alt="Nerd student looking for information."
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          borderRadius: '20px',
          transform: isSmallScreen ? 'scale(1.1)' : 'scale(1.3)',
        }}
      />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.25, duration: 0.5, ease: 'easeInOut' }}
        style={{
          position: 'absolute',
          bottom: isSmallScreen ? '10%' : '5%',
          left: '5%',
          width: isSmallScreen ? '90%' : 'auto',
          borderRadius: '20px',
        }}
      >
        <Box
          sx={{
            background: theme.palette.primary.main,
            color: 'white',
            p: isSmallScreen ? '15px' : '25px',
            borderRadius: '20px',
          }}
        >
          <Typography
            variant={isSmallScreen ? 'h5' : 'h4'}
            mb={isSmallScreen ? 2 : 3}
            fontWeight="bold"
          >
            Find the perfect tutor for you
          </Typography>
          <Search />
        </Box>
      </motion.div>
    </motion.div>
  );
};

export default HeroSection;
