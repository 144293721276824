import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import queryClient from './queryClient';
import theme from './assets/styles/mui/theme';
import { SocketProvider } from './context/SocketContext';
import { UserProvider } from './context/UserContext';
import NotificationCenter from "./notifications/NotificationCenter";
import Routes from "./router";

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationCenter>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <SocketProvider>
                <Routes />
              </SocketProvider>
            </LocalizationProvider>
          </NotificationCenter>
        </ThemeProvider>
      </UserProvider>
    </QueryClientProvider>
  );
};

export default App;
