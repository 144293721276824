import { Grid } from '@mui/material';
import React from 'react';
import Filters from './Filters';
import List from './List';

const Listings = () => {
    return (
        <Grid container spacing={2} position="relative">
            <Grid item xs={12} lg="auto" position={{ lg: "sticky", xs: "static" }} top={{ lg: 60, xs: 0 }} height={{ lg: "calc(100vh - 130px)", xs: "unset" }}>
                <Filters />
            </Grid>
            <Grid item xs={12} lg>
                <List />
            </Grid>
        </Grid>
    )
}

export default Listings