import { Box, Button, CircularProgress, Link, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import * as yup from "yup";
import useRequestResetPasswordLink from '../../../hooks/Public/useRequestResetPasswordLink';

function RequestResetPassword() {

    const handleSuccess = () => {
        enqueueSnackbar("Reset password link was sent to your email.", { variant: "success" })
    }

    const handleError = (data) => {
        enqueueSnackbar(data.message || "An error occurred.", { variant: "error" })
    }

    const { mutate: requestResetPasswordLink, isLoading } = useRequestResetPasswordLink(handleSuccess, handleError)

    const formik = useFormik({
        initialValues: { email: "" },
        validationSchema: yup.object(({ email: yup.string().email('Email is invalid').required('Email is required') })),
        validateOnBlur: true,
        onSubmit: (data) => {
            requestResetPasswordLink(data)
        }
    });

    return (
        <Box>
            <Typography variant="h4" fontWeight="bold" mb={5}>Reset your password!</Typography>
            <Stack mt={4} spacing={3}>
                <Box>
                    <Typography variant="body1" fontWeight="bold" mb={1}>Enter your email:</Typography>
                    <TextField
                        fullWidth
                        placeholder="Ex: user@gmail.com"
                        variant="outlined"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        name="email"
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        onBlur={formik.handleBlur}
                    />
                </Box>
            </Stack>
            <Box mt={2} textAlign="center">
                <Link underline='hover' variant="body2" color="secondary" component={RouterLink} to="/login">
                    Back to login
                </Link>
            </Box>
            <Box mt={2}>
                <Button onClick={() => formik.handleSubmit()} fullWidth disabled={isLoading} variant="contained" sx={{ fontSize: "16px", pt: "0.6rem", pb: "0.6rem" }}>
                    {isLoading &&
                        <CircularProgress size={24} color="inherit" className="absolute-circular-progress" />
                    }
                    Submit
                </Button>
            </Box>
        </Box>
    );
}

export default RequestResetPassword;
