import { useQuery } from 'react-query';
import { fetchCalendarEvents } from '../../../services/Calendar';
import dayjs from "../../../utils/dayjsConfig"

const useCalendarEvents = (startDate, endDate, onSuccess, onError) => {
    return useQuery(
        ['events'],
        () => fetchCalendarEvents(startDate, endDate),
        {
            select: (data) => {
                const transformedData = data.map(event => {
                    return {
                        ...event,
                        start: dayjs(event.start).toDate(),
                        end: dayjs(event.end).toDate()
                    };
                });
                return transformedData;
            },
            onSuccess: (data) => {
                if (onSuccess) onSuccess(data);
            },
            onError: (error) => {
                if (onError) onError(error.response.data);
            },
        }
    );
};

export default useCalendarEvents;
