import { Box, Button, CircularProgress, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import React from 'react'

const General = ({ formik, loading }) => {
  return (
    <Box mt={4}>
      <Typography variant="h6" fontWeight="bold" color="secondary" mb={2}>General Information</Typography>
      <Grid container spacing={{ xs: 3, md: 4 }}>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>First Name</Typography>
            <TextField
              fullWidth
              placeholder="Enter your first name"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              name="firstName"
              error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              helperText={formik.touched.firstName && formik.errors.firstName}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Last Name</Typography>
            <TextField
              fullWidth
              placeholder="Enter your last name"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              name="lastName"
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Email</Typography>
            <TextField
              fullWidth
              placeholder="Enter your email"
              variant="outlined"
              onChange={formik.handleChange}
              value={formik.values.email}
              name="email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Phone Number</Typography>
            <MuiTelInput
              fullWidth
              placeholder="Enter your number"
              variant="outlined"
              onlyCountries={["LB"]}
              onChange={(value) => formik.setFieldValue("phoneNumber", value)}
              value={formik.values.phoneNumber}
              name="phoneNumber"
              error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
              helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Gender</Typography>
            <Select
              variant='outlined'
              displayEmpty
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.gender}
              name="gender"
              error={formik.touched.gender && Boolean(formik.errors.gender)}
              onBlur={formik.handleBlur}
            >
              <MenuItem value={"male"}>
                <Typography fontSize={15} variant="body2" fontWeight="400">Male</Typography>
              </MenuItem>
              <MenuItem value={"female"}>
                <Typography fontSize={15} variant="body2" fontWeight="400">Female</Typography>
              </MenuItem>
            </Select>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="body1" fontWeight="bold" mb={1}>Date of Birth</Typography>
            <TextField
              fullWidth
              variant="outlined"
              type="date"
              onChange={formik.handleChange}
              value={formik.values.dob}
              name="dob"
              error={formik.touched.dob && Boolean(formik.errors.dob)}
              helperText={formik.touched.dob && formik.errors.dob}
              onBlur={formik.handleBlur}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Button disabled={loading} variant="contained" color="secondary" onClick={formik.handleSubmit}>
          {loading && (
            <CircularProgress size={24} color="inherit" className='absolute-circular-progress' />
          )}
          Validate
        </Button>
      </Box>
    </Box>
  )
}

export default General