import { Fade, Menu } from '@mui/material';
import React, { useMemo, useState } from 'react';

const useMenu = () => {
    const [menus, setMenus] = useState({});

    const openMenu = (id, anchorEl, selectedItem = null) => {
        setMenus(prevMenus => ({
            ...prevMenus,
            [id]: { anchorEl, selectedItem },
        }));
    };

    const closeMenu = (id) => {
        setMenus(prevMenus => ({
            ...prevMenus,
            [id]: { anchorEl: null, selectedItem: null },
        }));
    };

    const isMenuOpen = (id) => Boolean(menus[id]?.anchorEl);
    const getSelectedItem = (id) => menus[id]?.selectedItem;
    const getSelectedAnchorEl = (id) => menus[id]?.anchorEl;

    const MenuComponent = useMemo(() => {
        return React.memo(({ id, children, TransitionComponent = Fade, ...menuProps }) => {
            const anchorEl = getSelectedAnchorEl(id);
            return (
                <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen(id)}
                    onClose={() => closeMenu(id)}
                    TransitionComponent={TransitionComponent}
                    {...menuProps}
                >
                    {children}
                </Menu>
            );
        });
    }, [menus]);

    return { openMenu, closeMenu, isMenuOpen, getSelectedItem, getSelectedAnchorEl, MenuComponent };
};

export default useMenu;
