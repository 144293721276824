import { Container } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router';
import Header from '../components/Header';

function TutorLayout({ isMessages, ...props }) {

  if (isMessages) {
    return (
      <>
        <Header />
        <Outlet />
      </>
    )
  }

  return (
    <>
      <Header />
      <Container sx={{ ...props, p: { xs: 2, md: 4 }, mx: 'auto' }} maxWidth={props.maxWidth}>
        <Outlet />
      </Container>
    </>
  );
}

export default TutorLayout;
