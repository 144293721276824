import { useMutation } from 'react-query';
import { confirmBooking } from '../../../services/Booking';

const useConfirmBooking = (onSuccess, onError) => {
    return useMutation(confirmBooking, {
        onSuccess: (data) => {
            if (onSuccess) onSuccess(data)
        },
        onError: (error) => {
            if (onError) onError(error.response.data)
        },
    });
};

export default useConfirmBooking;
