export const MuiToggleButton = {
    styleOverrides: {
        root: {
            paddingLeft: '1.2rem',
            paddingRight: '1.2rem',
            height: "35px",
            textTransform: "capitalize",
            // color:"black",
            border: "1px solid #CFDBE8",
            borderRadius: "10px"
        },
    },
}