import dayjs from "../../../../../utils/dayjsConfig"

const groupBookingsByDate = (bookings) => {
    return bookings.reduce((acc, booking) => {
        const date = dayjs(booking.date).format('dddd, MMMM D, YYYY');
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(booking);
        return acc;
    }, {});
};

export default groupBookingsByDate;
