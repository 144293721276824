import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useAds from "../../../hooks/Tutor/useAds";
import AdEdit from './Edit';
import AdsList from './List';
import { useQueryClient } from 'react-query';

function MyAds() {
    const queryClient = useQueryClient();

    const [selectedAd, setSelectedAd] = useState(null);

    const { data: ads, isLoading } = useAds((data) => {
        if (data.length && !selectedAd) {
            setSelectedAd(data[0]);
        }
    });

    useEffect(() => {
        if (ads?.length && !selectedAd) {
            setSelectedAd(ads[0]);
        }
    }, [ads, selectedAd]);

    const handleCreateAdClick = () => {
        window.open("/t/create-your-ad", "_blank");
    };

    const onAdClick = (id) => {
        setSelectedAd(ads.find(ad => ad.id === id));
    }

    const onAdUpdate = (id, newAd) => {
        queryClient.setQueryData('ads', (oldData) => {
            return oldData.map((ad) => {
                if (ad.id === id) {
                    return { ...newAd };
                }
                return { ...ad };
            });
        });

        setSelectedAd({ ...newAd });
    }

    if (isLoading || (!selectedAd && ads?.length)) {
        return (
            <Box mt={6} textAlign="center" overflow="hidden">
                <Typography variant="h6" fontSize={18} mb={1.5} fontWeight="500">Loading Your Ads</Typography>
                <CircularProgress disableShrink color="inherit" size={24} />
            </Box>
        );
    }

    if ((ads || []).length === 0) {
        return (
            <Box mt={6} textAlign="center">
                <Typography variant="h6" fontWeight="500">No Ads found.</Typography>
                <Button
                    variant="outlined"
                    disableRipple
                    onClick={handleCreateAdClick}
                    sx={{ fontSize: "16px", mt: 2 }}>
                    Create Your First Ad <AddIcon sx={{ ml: 1 }} />
                </Button>
            </Box>
        );
    }

    return (
        <Box>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={4} lg={3}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        fullWidth
                        onClick={handleCreateAdClick}
                        sx={{ fontSize: "16px", mb: 2 }}>
                        Create Ad <AddIcon sx={{ ml: 1 }} />
                    </Button>
                    <AdsList ads={ads} onAdClick={onAdClick} selectedAd={selectedAd} />
                </Grid>
                <Grid item xs={12} sm={8} lg={9}>
                    <AdEdit ad={selectedAd} onAdUpdate={onAdUpdate} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default MyAds;
