import debounce from 'lodash.debounce';
import { useState } from 'react';
import useSubjects from '../../useSubjects';

const useSubjectSearch = (initialValue) => {
    const [inputValue, setInputValue] = useState(initialValue || 'A');

    const { data: subjects, isLoading } = useSubjects(inputValue);

    const handleInputChange = debounce((_, newInputValue) => {
        setInputValue(newInputValue || 'A');
    }, 300);

    const getOptions = (selectedSubject) => {
        if (!subjects) return [];

        if (selectedSubject) {
            return [selectedSubject, ...subjects];
        }
        
        return subjects;
    };

    return {
        inputValue,
        setInputValue,
        subjects,
        isLoading,
        handleInputChange,
        getOptions,
    };
};

export default useSubjectSearch;
