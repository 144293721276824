import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import defaultBg from "../../../../assets/images/chat-default-bg.jpg";
import { useSocket } from '../../../../context/SocketContext';
import { useUserContext } from '../../../../context/UserContext';
import Constants from '../../../../core/Constants';
import useDebounce from '../../../../hooks/core/useDebounce';
import useRooms from '../../../../hooks/shared/student_tutor/useRooms';
import ChatBox from './ChatBox';
import Rooms from "./Rooms";
import Search from './Search';

function Messages() {
    const socket = useSocket();
    const theme = useTheme();
    const { user } = useUserContext()
    const navigate = useNavigate()

    const [query, setQuery] = useState("")
    const isClearingQueryRef = useRef(false);
    const roomQueryResult = useRooms(useDebounce(query, 300))
    const [selectedRoom, setSelectedRoom] = useState(null);

    useEffect(() => {
        if (!socket || !selectedRoom?.id) return;

        socket.emit(Constants.EVENTS.MARK_MESSAGES_AS_READ, selectedRoom.id);
    }, [selectedRoom?.id, socket]);

    const onRoomSelect = (room) => {
        if (selectedRoom?.id === room.id) return
        setSelectedRoom(room);
    }

    const onRoomStatusBroadcast = (model, modelName) => {
        setSelectedRoom((room) => {
            return { ...room, [modelName]: { ...room[modelName], online: true } }
        })
    }

    const unselectRoom = () => {
        navigate(`/${user.role === 'tutor' ? 't' : 's'}/messages`)
        setSelectedRoom(null);
    }

    const handleQueryChange = (e) => {
        setQuery(e.target.value)
        isClearingQueryRef.current = e.target.value === "";
    }

    const { data: rooms, isFetched, isLoading } = roomQueryResult

    if (isLoading) {
        return (
            <Box mt={6} textAlign="center">
                <Typography variant="h6" fontSize={18} mb={1.5} fontWeight="500">Loading Your Messages</Typography>
                <CircularProgress disableShrink color="inherit" size={24} />
            </Box>
        )
    }

    if (isFetched && !isClearingQueryRef.current && !rooms.length && !query) {
        return (
            <Box mt={6} textAlign="center">
                <Typography variant="h6" fontSize={18} gutterBottom>
                    No rooms found.
                </Typography>
                {
                    user.role === "tutor" ? (
                        <>
                            <Typography variant="body2" color="textSecondary">
                                No students have messaged you yet!
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Typography variant="body2" color="textSecondary">
                                To get started, send your first message to a tutor!
                            </Typography>
                        </>
                    )
                }
            </Box>
        )
    }

    return (
        <Box>
            <Grid container height={{ lg: "calc(100vh - 64.8px)", xs: "calc(100vh - 64.8px)" }}>
                <Grid item xs={12} lg={3.15}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: "1px solid " + theme.palette.lightBlue.main,
                        overflow: "auto",
                        height: "100%"
                    }}
                >
                    <Box pl={3} pr={3} pt={2} pb={2}>
                        <Typography mb={1.5} sx={{ fontSize: 18 }} fontWeight="500">Messages</Typography>
                        <Search onQueryChange={handleQueryChange} query={query} />
                    </Box>
                    <Rooms
                        query={query}
                        socket={socket}
                        onRoomSelect={onRoomSelect}
                        onRoomStatusBroadcast={onRoomStatusBroadcast}
                        selectedRoom={selectedRoom}
                        {...roomQueryResult}
                    />
                </Grid>
                <Grid item xs={0} lg={8.85} sx={{ height: "100%", overflowY: "hidden" }}>
                    {selectedRoom ? (
                        <ChatBox
                            socket={socket}
                            room={selectedRoom}
                            unselectRoom={unselectRoom}
                        />
                    ) : (
                        <Box
                            sx={{
                                height: "100%",
                                backgroundImage: `url(${defaultBg})`,
                                backgroundSize: 'contain',
                                opacity: '0.6',
                                backgroundPosition: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="h5">Select a chat to start messaging</Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}

export default Messages;
