import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, CircularProgress, Fade, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useUserContext } from '../../../../../context/UserContext';
import useConfirmBooking from "../../../../../hooks/shared/student_tutor/useConfirmBooking";
import useDeleteBooking from '../../../../../hooks/shared/student_tutor/useDeleteBooking';
import usePendingBookings from "../../../../../hooks/shared/student_tutor/usePendingBookings";
import useRejectBooking from "../../../../../hooks/shared/student_tutor/useRejectBooking";
import Lesson from "../Lesson";
import groupBookingsByDate from '../utils/groupBookingsByDate';
import InfiniteScroll from 'react-infinite-scroll-component';
import LessonSkeleton from '../Lesson/Skeleton';

const AwaitingConfirmation = () => {
    const [menuState, setMenuState] = React.useState({
        anchorEl: null,
        selectedBooking: null,
    });
    const open = Boolean(menuState.anchorEl);

    const { data: pendingBookings, isLoading, fetchNextPage, hasNextPage, refetch } = usePendingBookings();
    const { user } = useUserContext();

    const handleSuccess = (status) => {
        enqueueSnackbar("Booking was " + status + ".", { variant: "success" });
        setMenuState({ anchorEl: null, selectedBooking: null });
        refetch()
    }

    const handleError = (data) => {
        enqueueSnackbar(data.message, { variant: "error" });
        setMenuState({ anchorEl: null, selectedBooking: null });
    }

    const { mutate: deleteBooking } = useDeleteBooking(() => handleSuccess("deleted"), handleError)
    const { mutate: confirmBooking } = useConfirmBooking(() => handleSuccess("accepted"), handleError)
    const { mutate: rejectBooking } = useRejectBooking(() => handleSuccess("rejected"), handleError)

    const handleClick = (event, booking) => {
        setMenuState({ anchorEl: event.currentTarget, selectedBooking: booking })
    };

    const handleClose = () => {
        setMenuState({ anchorEl: null, selectedBooking: null });
    };

    const handleDeleteLesson = () => {
        const confirmDeletion = window.confirm('THIS ACTION IS IRREVERSIBLE! Are you sure you want to proceed?');
        if (confirmDeletion) {
            handleClose();
            deleteBooking(menuState.selectedBooking.id);
        }
    };

    const handleAcceptLesson = () => {
        const confirmAcceptance = window.confirm('Are you sure you want to confirm this lesson?');
        if (confirmAcceptance) {
            handleClose();
            confirmBooking(menuState.selectedBooking.id);
        }
    }

    const handleRejectLesson = () => {
        const confirmRejection = window.confirm('Are you sure you want to reject this lesson?');
        if (confirmRejection) {
            handleClose();
            rejectBooking(menuState.selectedBooking.id);
        }
    }

    if (isLoading) {
        return (
            <Box>
                {new Array(3).fill().map((_, index) => {
                    return (
                        <LessonSkeleton key={index} />
                    )
                })}
            </Box>
        )
    }

    const data = pendingBookings?.pages.flatMap(page => page.bookings) || []

    if (!data.length) {
        return (
            <Typography variant="body2">No pending lessons were found.</Typography>
        )
    }

    const groupedBookings = groupBookingsByDate(data)

    return (
        <Box>
            <InfiniteScroll
                dataLength={data.length}
                next={fetchNextPage}
                hasMore={hasNextPage}
                loader={<Box textAlign="center"><CircularProgress size={24} color="inherit" /></Box>}
            >
                {Object.keys(groupedBookings).map(date => {
                    return (
                        <Box key={date} mb={3}>
                            <Typography fontSize="1.1rem" fontWeight="bold" mb={2}>{date}</Typography>
                            <Stack spacing={2.5}>
                                {groupedBookings[date].map((booking) => {
                                    return (
                                        <Lesson key={booking.id} user={user} booking={booking}>
                                            <Box>
                                                <IconButton
                                                    id="fade-button"
                                                    aria-controls={open ? 'fade-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={(event) => handleClick(event, booking)}
                                                >
                                                    <MoreHorizIcon />
                                                </IconButton>
                                            </Box>
                                        </Lesson>
                                    )
                                })}
                            </Stack>
                        </Box>
                    )
                })}
            </InfiniteScroll>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={menuState.anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {user.role === 'student' && (
                    <MenuItem onClick={handleDeleteLesson}>
                        <DeleteIcon sx={{ mr: 1, fontSize: "1.2rem" }} />
                        <Typography variant="body2">Delete Lesson</Typography>
                    </MenuItem>
                )}

                {user.role === 'tutor' && (
                    <MenuItem onClick={handleAcceptLesson}>
                        <CheckIcon sx={{ mr: 1, fontSize: "1.2rem" }} />
                        <Typography variant="body2">Accept Lesson</Typography>
                    </MenuItem>
                )}

                {user.role === 'tutor' && (
                    <MenuItem onClick={handleRejectLesson}>
                        <CancelIcon sx={{ mr: 1, fontSize: "1.2rem" }} />
                        <Typography variant="body2">Reject Lesson</Typography>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    )
}

export default AwaitingConfirmation
